import {Box, Button, Content, Image, Level, Media} from 'react-bulma-components'

import {DateTime} from 'luxon'
import React from 'react'

/**
 * ProfileDetailsItem
 */
const ProfileDetailsItem = ({data, ...props}) => {
    if (!data) {
        return null
    }

    const creationDate = DateTime.fromISO(data.created_at, {zone: 'utc'})
    const formattedCreationDate = creationDate ? creationDate.toLocaleString(DateTime.DATETIME_FULL) : 'N/A'

    return (
        <Box className="is-shadowless is-marginless">
            <Media>
                <Media.Item renderAs="figure" position="left">
                    {data.image && <Image size={64} alt="64x64" src={data.image && data.image.url} />}
                    {!data.image && <Image size={64} alt="64x64" src="https://via.placeholder.com/64x64" />}
                </Media.Item>
                <Media.Item>
                    <Content>
                        <p>
                            <strong>
                                {data.fullName} ({data.profileType})
                            </strong>
                            <br />
                            {data.fullName} - {data.fullName}
                            <br />
                            Membership: {data.membershipType}
                        </p>
                    </Content>
                    <Level breakpoint="mobile">
                        <Level.Side align="left">
                            <Content>
                                <a href={`/dashboard/profiles/${data.id}`} title={`View details of ${data.fullName}`}>
                                    View details
                                </a>
                            </Content>
                        </Level.Side>
                    </Level>
                </Media.Item>
                <Media.Item>
                    <Content>
                        <p>
                            <strong>&nbsp;</strong>
                            <br />
                            {formattedCreationDate}
                        </p>
                    </Content>
                </Media.Item>
                <Media.Item>
                    <Level breakpoint="mobile" className="is-pulled-right">
                        <Level.Side align="right">
                            {!data.isDeleted && (
                                <div className="buttons">
                                    {data.isConfirmed === false && (
                                        <Button text={true} onClick={event => props.onAction('activation', data)}>
                                            Resend activation mail
                                        </Button>
                                    )}
                                    {data.isConfirmed === true &&
                                        data.membershipType === 'free' && (
                                            <Button color="primary" onClick={event => props.onAction('upgrade', data)}>
                                                Upgrade
                                            </Button>
                                        )}
                                    {data.isConfirmed === true &&
                                        data.membershipType === 'foodsaver' && (
                                            <Button
                                                color="primary"
                                                onClick={event => props.onAction('downgrade', data)}
                                            >
                                                Downgrade
                                            </Button>
                                        )}
                                    {data.isConfirmed === true &&
                                        !data.isApprovedProfile && (
                                            <Button color="primary" onClick={event => props.onAction('approve', data)}>
                                                Approve
                                            </Button>
                                        )}
                                    {data.isConfirmed === true &&
                                        data.isApprovedProfile && (
                                            <Button color="info" onClick={event => props.onAction('unapprove', data)}>
                                                Unapprove
                                            </Button>
                                        )}
                                    {data.isConfirmed === true &&
                                        data.isApprovedProfile === true && (
                                            <Button
                                                className=""
                                                text
                                                onClick={event => props.onAction('network', data)}
                                            >
                                                Network
                                            </Button>
                                        )}

                                    <Button color="danger" onClick={event => props.onAction('delete', data)}>
                                        Delete
                                    </Button>
                                </div>
                            )}
                        </Level.Side>
                    </Level>
                </Media.Item>
            </Media>
        </Box>
    )
}

export default ProfileDetailsItem
