// @flow
/*eslint react/default-props-match-prop-types: "off" */
import React, {Component} from 'react'

type AddressInfo = {
  address1: string,
  address2: string,
  address3: string,
  address4: string,
  city: string,
  postcode: string,
  country: string,
}

type ContactInfo = {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  mobileNumber: string,
}

type AddressBlockData = AddressInfo | ContactInfo

type StringArray = Array<string>
type StringOrArray = StringArray | string

type AddressBlockProps = {
  type: 'address' | 'contact',
  data: AddressBlockData,
  contactFields: StringOrArray,
  addressFields: StringOrArray,
  clickableFields: StringOrArray,
}

class AddressBlock extends Component<AddressBlockProps> {
  static defaultProps = {
    type: 'address',
    clickableFields: ['phoneNumber', 'mobileNumber', 'emailAddress'],
    contactFields: [],
    addressFields: [],
  }

  getItemValue(field: StringOrArray) {
    let itemValue
    if (Array.isArray(field)) {
      itemValue = field.map(fieldName => this.getItemValue(fieldName)).join(' ')
    } else {
      itemValue = this.props.data[field]
    }
    return itemValue
  }

  render() {
    const {
      type = 'address',
      data,
      contactFields: extraContactFields = [],
      addressFields: extraAddressFields = [],
      clickableFields: clickableFields = [],
      ...extraProps
    } = this.props
    const addressFields = [
      'address1',
      'address2',
      'address3',
      'address4',
      'city',
      'postcode',
      'country',
      ...extraAddressFields,
    ]
    const contactFields = [
      ['firstName', 'lastName'],
      'phoneNumber',
      'mobileNumber',
      ...extraContactFields,
    ]
    if (!data) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(
          'The data property of the <AddressBlock /> component can not be undefined. This will render an empty component',
        )
      }
      return null
    }
    return (
      <div {...extraProps}>
        {type === 'address' &&
          addressFields.map(field => {
            const itemValue = this.getItemValue(field)
            if (!itemValue) return null
            return <p key={field}>{itemValue}</p>
          })}
        {type === 'contact' &&
          contactFields.map((field: StringOrArray) => {
            const itemValue = this.getItemValue(field)
            const protocol = field === 'emailAddress' ? 'mailto' : 'tel'
            if (!itemValue) return null

            const fieldName: string = ((field: any): string)
            if (clickableFields.includes(fieldName)) {
              const trimmedItemValue = itemValue.replace(/\s/g, '')
              return (
                <p key={fieldName}>
                  <a
                    href={`${protocol}:${trimmedItemValue}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {itemValue}
                  </a>
                </p>
              )
            }

            return <p key={fieldName}>{itemValue}</p>
          })}
      </div>
    )
  }
}

export default AddressBlock
