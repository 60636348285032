import React, { Component } from 'react';
import {Container, Heading, Section, Content} from 'react-bulma-components'
import AdminCard from './DashboardAdminCard'
import {AlertDialog, AlertDialogLabel, AlertDialogDescription} from '@reach/alert-dialog'
import {connect} from 'react-redux'
import {fetch, getIsAuthenticated, getIsRestored} from 'redux-simple-auth'
import * as uiActions from './reducer/actions'
import * as uiSelectors from './reducer/selectors'
import * as actions from '../../common/reducers/users/adminActions'

class DashboardAdminPage extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.modalDeleteUsersRef = React.createRef()
        this.modalCorrectAddressesRef = React.createRef()
    }

    correctCorruptAddresses = () => {
        this.props.dispatch(uiActions.setModalState("modalCorrectAddresses", true))
    }

    correctCorruptAddressesResponse = (hasConfirmed) => {
        this.props.dispatch(uiActions.setModalState("modalCorrectAddresses", false))
        if (!hasConfirmed) {
            return
        }
        this.props.dispatch(actions.correctCorruptAddresses())
    }
    
    permanentlyDeleteUsers = () => {
        this.props.dispatch(uiActions.setModalState("modalDeleteUsers", true))
    }

    permanentlyDeleteUsersResponse = (hasConfirmed) => {
        this.props.dispatch(uiActions.setModalState("modalDeleteUsers", false))
        if (!hasConfirmed) {
            return
        }
        this.props.dispatch(actions.hardDeleteCleanup());
    }

    testClick = () => {
        console.log("The button was clicked")
    }

    render() {
        const {
            modalDeleteUsers = false,
            modalCorrectAddresses = false,


        } = this.props
        return (
            <React.Fragment>
                <Section renderAs="header">
                    <Container>
                        <Heading>Administration Actions</Heading>
                        <Content>
                            This page contains administrative clean up actions. 
                            Please use sparingly and consider the consequences of selected actions.
                        </Content>
                    </Container>
                </Section>
                <Section>
                    <AdminCard
                        title="Correct Corrupt Addresses"
                        info="Use this action to save a correct location to the database. 
                        This is designed to fix users logging in and seeing issues on their maps 
                        page after a Google Maps API Key expiry."
                        buttonText="Correct Addresses"
                        buttonAction={this.correctCorruptAddresses}
                    />
                    <AdminCard
                        title="Permanently Delete Users"
                        info="Use this action to delete all users who appear in the deleted list 
                        (after database transition to hard delete)."
                        buttonText="Delete Users"
                        buttonAction={this.permanentlyDeleteUsers}
                    />
                    {modalCorrectAddresses && (
                        <AlertDialog leastDestructiveRef={this.modalCorrectAddressesRef}>
                            <AlertDialogLabel>Correct Corrupt Addresses</AlertDialogLabel>
                            <AlertDialogDescription>
                                <div>
                                    <p>
                                        Are you sure you wish to perform a search for 
                                        and correction of corrupt addresses?
                                    </p>
                                </div>
                            </AlertDialogDescription>

                            <div className="buttons">
                                <button
                                    className="control button is-danger"
                                    onClick={event => this.correctCorruptAddressesResponse(true)}
                                >
                                    Confirm Action
                                </button>{' '}
                                <button
                                    className="control button"
                                    ref={this.cancelClaimDonationRef}
                                    onClick={event => this.correctCorruptAddressesResponse(false)}
                                >
                                    Nevermind
                                </button>
                            </div>
                        </AlertDialog>
                    )}
                    {modalDeleteUsers && (
                        <AlertDialog leastDestructiveRef={this.modalDeleteUsersRef}>
                            <AlertDialogLabel>Permanently Delete Users</AlertDialogLabel>
                            <AlertDialogDescription>
                                <div>
                                    <p>
                                        Are you sure you wish to perform a search for 
                                        and deletion of users marked as deleted?
                                    </p>
                                </div>
                            </AlertDialogDescription>

                            <div className="buttons">
                                <button
                                    className="control button is-danger"
                                    onClick={event => this.permanentlyDeleteUsersResponse(true)}
                                >
                                    Confirm Action
                                </button>{' '}
                                <button
                                    className="control button"
                                    ref={this.cancelClaimDonationRef}
                                    onClick={event => this.permanentlyDeleteUsersResponse(false)}
                                >
                                    Nevermind
                                </button>
                            </div>
                        </AlertDialog>
                    )}
                </Section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isSessionRestored: getIsRestored(state),
    isAuthenticated: getIsAuthenticated(state),
    modalDeleteUsers: uiSelectors.getModalState('modalDeleteUsers')(state),
    modalCorrectAddresses: uiSelectors.getModalState('modalCorrectAddresses')(state),
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdminPage);