// @flow
import {fetch} from 'redux-simple-auth'
import {actionTypes} from 'redux-resource'
import {
  getPaginationDataFromHeaders,
  getSearchQuerystring,
} from '../getSearchString'

import {API_SERVER_URL} from '../../app-constants'
import {handleErrors} from '../../utils/handleErrors'
import {processValidationErrors} from '../../utils/formValidations'

/**
 * Returns the profile data of the given identifier
 * @param {*} profileId the identifier of the profile
 * @returns Promise<Profile>
 */
export const getProfileById = (profileId: number) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `fetchAdminProfile-${profileId}`
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
      resources: [profileId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profiles/${profileId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [profileId],
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Approve the given profile
 * @param {*} profileId the identifier of the profile
 * @returns Promise<Profile>
 */
export const approveProfile = (profileId: number) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `unapproveProfile-${profileId}`
    dispatch({
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
      resources: [profileId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/profiles/${profileId}/approve`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [profileId],
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Unapprove the given profile
 * @param {*} profileId the identifier of the profie
 * @returns Promise<Profile>
 */
export const unapproveProfile = (profileId: number) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `unapproveProfile-${profileId}`
    dispatch({
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
      resources: [profileId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/profiles/${profileId}/unapprove`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [profileId],
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Delete the profile with the given identifier
 * @param {*} profileId the identifier of the profile
 */
export const deleteProfile = (profileId: number) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `deleteProfile-${profileId}`
    dispatch({
      type: actionTypes.DELETE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
      resources: [profileId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profiles/${profileId}/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.DELETE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.DELETE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [profileId],
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Adds an existing profile as a branch
 * @param {*} profileId the identifier of the profile
 */
export const addProfileAsBranch = (
  profileId: number,
  masterProfileId: number,
) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `addProfileAsBranch-${masterProfileId}`
    dispatch({
      type: actionTypes.CREATE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'network',
      resources: [masterProfileId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profile/${masterProfileId}/network`, {
        method: 'POST',
        body: JSON.stringify({branchProfileId: profileId}),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'network',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CREATE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'network',
          resources: [masterProfileId],
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Adds an existing profile as a branch
 * @param {*} profileId the identifier of the profile
 */
export const getProfileBranches = (profileId: number) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `getProfileBranches-${profileId}`

    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'network',
      list: `networkOf${profileId}`,
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profile/${profileId}/network`, {
        method: 'GEt',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => {
        return res.json()
      })
      .then(json => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'network',
          list: `networkOf${profileId}`,
          resources: json,
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'network',
          list: `networkOf${profileId}`,
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Remove an existing branch profile
 * @param {*} profileId the identifier of the profile
 */
export const removeProfileAsBranch = (
  profileId: number,
  masterProfileId: number,
) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `removeProfileAsBranch-${masterProfileId}`
    dispatch({
      type: actionTypes.DELETE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'network',
      resources: [masterProfileId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profile/${masterProfileId}/network`, {
        method: 'DELETE',
        body: JSON.stringify({branchProfileId: profileId}),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.DELETE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'network',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.DELETE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'network',
          resources: [masterProfileId],
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Update the profile with the given identifier
 * @param {*} profileId the identifier of the profile
 * @param {*} data the data to be update in the profile
 */
export const patchProfile = (profileId: number, data: object) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const generatedRequestKey = `patchProfile-${profileId}`
    dispatch({
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
      resources: [profileId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/profiles/${profileId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    )
      .then(processValidationErrors)
      .then(json => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          resources: [profileId],
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Returns all available profiles
 * @param {*} search the query to search within profiles
 * @param {*} cursor the next cursor
 */
export const getProfiles: ThunkAction = (
  search: string = '',
  cursor: string = '',
  options: object = {},
) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const {list, requestKey, ...otherOptions} = options
    const queryString = getSearchQuerystring({search, cursor, ...otherOptions})
    const generatedRequestKey = requestKey || `profiles`

    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
    })
    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profiles${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => {
        return Promise.all([
          res.json(),
          getPaginationDataFromHeaders(res.headers),
        ])
      })
      .then(([json, paginationData = null]) => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          mergeListIds: false,
          resourceType: 'profiles',
          list: list || 'all',
          resources: json,
          pagination: {all: paginationData},
          meta: {isAdminstrative: true},
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Returns all approved profiles
 * @param {*} search the query to search within profiles
 * @param {*} cursor the next cursor
 */
export const getApprovedProfiles: ThunkAction = (
  search: string,
  cursor: string,
  options: object = {},
) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const {list, requestKey, ...otherOptions} = options
    const queryString = getSearchQuerystring({search, cursor, ...otherOptions})
    const generatedRequestKey = requestKey || `approved-profiles`
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
    })
    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profiles/approved${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => {
        return Promise.all([
          res.json(),
          getPaginationDataFromHeaders(res.headers),
        ])
      })
      .then(([json, paginationData]) => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          mergeListIds: false,
          resourceType: 'profiles',
          list: list || 'approved',
          resources: json,
          pagination: {[list]: paginationData},
          meta: {isApproved: true},
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Returns all unapproved profiles
 * @param {*} search the query to search within profiles
 * @param {*} cursor the next cursor
 */
export const getUnapprovedProfiles: ThunkAction = (
  search: string,
  cursor: string,
  options: object = {},
) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const {list, requestKey, ...otherOptions} = options
    const queryString = getSearchQuerystring({search, cursor, ...otherOptions})
    const generatedRequestKey = requestKey || `unapproved-profiles`
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
    })
    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profiles/unapproved${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => {
        return Promise.all([
          res.json(),
          getPaginationDataFromHeaders(res.headers),
        ])
      })
      .then(([json, paginationData]) => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          mergeListIds: false,
          resourceType: 'profiles',
          list: list || 'unapproved',
          resources: json,
          pagination: {[list]: paginationData},
          meta: {isApproved: false},
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Returns all deleted profiles
 * @param {*} search the query to search within profiles
 * @param {*} cursor the next cursor
 */
export const getUnconfirmedProfiles: ThunkAction = (
  search: string,
  cursor: string,
  options: object = {},
) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const {list, requestKey, ...otherOptions} = options
    const queryString = getSearchQuerystring({search, cursor, ...otherOptions})
    const generatedRequestKey = requestKey || `unconfirmed-profiles`
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
    })
    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profiles/unconfirmed${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => {
        return Promise.all([
          res.json(),
          getPaginationDataFromHeaders(res.headers),
        ])
      })
      .then(([json, paginationData]) => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          mergeListIds: false,
          resourceType: 'profiles',
          list: list || 'unconfirmed',
          resources: json,
          pagination: {[list]: paginationData},
          meta: {isConfirmed: false},
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          meta: {error: error.message},
        })
      })
  }
}

/**
 * Returns all deleted profiles
 * @param {*} search the query to search within profiles
 * @param {*} cursor the next cursor
 */
export const getDeletedProfiles: ThunkAction = (
  search: string,
  cursor: string,
  options: object = {},
) => {
  return (dispatch: Dispatch, _getState: GetState) => {
    const {list, requestKey, ...otherOptions} = options
    const queryString = getSearchQuerystring({search, cursor, ...otherOptions})
    const generatedRequestKey = requestKey || `deleted-profiles`
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'profiles',
    })
    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/profiles/deleted${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => {
        return Promise.all([
          res.json(),
          getPaginationDataFromHeaders(res.headers),
        ])
      })
      .then(([json, paginationData]) => {
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          mergeListIds: false,
          resourceType: 'profiles',
          list: list || 'deleted',
          resources: json,
          pagination: {[list]: paginationData},
          meta: {isDeleted: true},
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'profiles',
          meta: {error: error.message},
        })
      })
  }
}

export default {
  getProfileById,
  getProfiles,
  getUnconfirmedProfiles,
  getApprovedProfiles,
  getUnapprovedProfiles,
  getDeletedProfiles,
  approveProfile,
  unapproveProfile,
  patchProfile,
  deleteProfile,
}
