//UI action types
export const SET_UI_STATE = '@profiles/SET_UI_STATE'
export const RESET_UI_STATE = '@profiles/RESET_UI_STATE'
export const SET_MODAL_STATE = '@profiles/SET_MODAL_STATE'

//API call action types
export const GET_PROFILES_LIST_SUCCESS = 'GET_PROFILES_LIST_SUCCESS';
export const GET_PROFILES_LIST_FAIL = 'GET_PROFILES_LIST_FAIL';
export const GET_PROFILES_LIST_START = 'GET_PROFILES_LIST_START';

export const PROFILE_ACTION_START = 'PROFILE_ACTION_START';
export const PROFILE_ACTION_SUCCESS = 'PROFILE_ACTION_SUCCESS';
export const PROFILE_ACTION_FAIL = 'PROFILE_ACTION_FAIL';

export const GET_PROFILE_BY_ID_START = 'GET_PROFILE_BY_ID_START';
export const GET_PROFILE_BY_ID_SUCCESS = 'GET_PROFILE_BY_ID_SUCCESS';
export const GET_PROFILE_BY_ID_FAIL = 'GET_PROFILE_BY_ID_FAIL';
