import React, {Component} from 'react'
import Downshift from 'downshift'
import classNames from 'classnames'
import FetchProfiles from './FetchProfiles' // eslint-disable-line import/no-named-as-default

/**
 * Profile picker
 * Allows selecting a specific profile from a list
 */
class ProfilePicker extends Component {
  input = React.createRef()
  state = {selectedProfiles: []}

  static defaultProps = {
    allowProfileTypes: 'charity',
  }

  render() {
    const {selectedProfiles} = this.state
    const {allowProfileTypes = 'charity'} = this.props
    return (
      <Downshift
        onChange={selection => this.props.onChange(selection)}
        itemToString={item => (item ? item.fullName : '')}
      >
        {({
          // getLabelProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          // toggleMenu,
          // clearSelection,
          highlightedIndex,
          // selectHighlightedItem,
          setHighlightedIndex,
          // reset,
          inputValue,
          clearItems,
          setItemCount,
        }) => (
          <div
            className="profilepicker__container"
            style={{marginBottom: '0.75rem'}}
          >
            <input
              {...getInputProps({
                className: 'input',
                ref: this.input,
                placeholder: 'Enter profile name',
              })}
            />
            {isOpen == false ? null : (
              <div {...getMenuProps({style: {padding: 0, margin: 0}})}>
                <FetchProfiles
                  searchValue={inputValue}
                  profileType={allowProfileTypes}
                  omitProfiles={selectedProfiles}
                  onLoaded={({profiles}) => {
                    clearItems()
                    if (profiles) {
                      setHighlightedIndex(profiles.length ? 0 : null)
                      setItemCount(profiles.length)
                    }
                  }}
                >
                  {({loading, profiles, error}) => (
                    <div>
                      {loading ? (
                        <div
                          style={{paddingTop: '0.75rem'}}
                          className="has-text-primary is-loading"
                        >
                          Loading...
                        </div>
                      ) : error ? (
                        <div
                          style={{paddingTop: '0.75rem'}}
                          className="has-text-danger"
                        >
                          Error...
                        </div>
                      ) : profiles.length ? (
                        <ProfileList
                          highlightedIndex={highlightedIndex}
                          getItemProps={getItemProps}
                          profiles={profiles}
                        />
                      ) : (
                        <div
                          style={{paddingTop: '0.75rem'}}
                          className="has-text-primary is-loading"
                        >
                          No results...
                        </div>
                      )}
                    </div>
                  )}
                </FetchProfiles>
              </div>
            )}
          </div>
        )}
      </Downshift>
    )
  }
}

function ProfileList({highlightedIndex, getItemProps, profiles}) {
  return (
    <div
      className="list"
      style={{
        maxHeight: '15rem',
        overflow: 'hidden',
        position: 'absolute',
        zIndex: 1000,
        overflowY: 'scroll',
      }}
    >
      {profiles &&
        profiles.map((item, index) => (
          <div
            key={item.id}
            {...getItemProps({
              item,
              index,
              style: {cursor: 'pointer'},
              className: classNames('list-item', {
                'is-active': index === highlightedIndex,
              }),
            })}
          >
            {item.fullName}
          </div>
        ))}
    </div>
  )
}

export default ProfilePicker
