import * as actionTypes from './actionTypes';

const initialState = {
    isLoaded: false,
    isLoading: false,
    items: [],
    pagination: {},
    errors: {},
    isError: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILES_LIST_START:
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            } 
        case actionTypes.GET_PROFILES_LIST_SUCCESS:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                items: action.items,
                pagination: action.pagination,
            }
        case actionTypes.GET_PROFILES_LIST_FAIL:
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                errors: action.error,
                isError: true
            }
        case actionTypes.GET_PROFILE_BY_ID_START:
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            }
        case actionTypes.GET_PROFILE_BY_ID_SUCCESS:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                profile: action.profile
            }
        case actionTypes.GET_PROFILE_BY_ID_FAIL:
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                error: action.error
            }
        default:
            return state;
    }
}

export default reducer;