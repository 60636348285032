/* eslint-disable react/no-unsafe, babel/camelcase */
import React from 'react'
import {connect} from 'formik'

type Props = {
  onChange: Function,
  formik: Object,
}

const Effect = class Effect extends React.Component<Props> {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {values, touched, errors, isSubmitting} = this.props.formik
    const {
      values: nextValues,
      touched: nextTouched,
      errors: nextErrors,
      isSubmitting: nextIsSubmitting,
    } = nextProps.formik
    if (nextProps.formik !== this.props.formik) {
      this.props.onChange(
        {
          values,
          touched,
          errors,
          isSubmitting,
        },
        {
          values: nextValues,
          touched: nextTouched,
          errors: nextErrors,
          isSubmitting: nextIsSubmitting,
        },
      )
    }
  }

  // eslint-disable-next-line
  render() {
    return null
  }
}

export default connect(Effect)
