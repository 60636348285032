import * as actionTypes from './actionTypes'

const initialState = {
    selectedFilterMode: 'all',
    selectedProfileId: null,
    profileSearchQuery: '',

    approveProfileId: null,
    upgradeProfileId: null,
    deleteProfileId: null,

    selectedBranchProfileId: null,

    modalState: {
        confirmApproveProfile: false,
        confirmUnapproveProfile: false,
        confirmUpgradeProfile: false,
        confirmDowngradeProfile: false,
        confirmDeleteProfile: false,
    },
}

export default function ui(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_UI_STATE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        case actionTypes.RESET_UI_STATE:
            return {
                ...state,
                ...initialState,
            }

        case actionTypes.SET_MODAL_STATE:
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    [action.payload.modalName]: action.payload.state,
                },
            }

        default:
            return state
    }
}
