import * as actionTypes from './actionTypes'

const initialState = {
    selectedReportProfileId: null,

    modalState: {
        modalDownloadImpactReport: false,
    },
}

export default function ui(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_UI_STATE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        case actionTypes.RESET_UI_STATE:
            return {
                ...state,
                ...initialState,
            }

        case actionTypes.SET_MODAL_STATE:
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    [action.payload.modalName]: action.payload.state,
                },
            }

        default:
            return state
    }
}
