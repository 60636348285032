import React, {Component} from 'react'

/**
 *
 */
export class NotFoundPage extends Component {
    render() {
        return <p>NotFoundPage</p>
    }
}

export default NotFoundPage
