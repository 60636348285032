import {Component} from 'react'
import {connect} from 'react-redux'
import debounce from 'lodash/debounce'
import {fetch} from 'redux-simple-auth'
import {API_SERVER_URL} from '../../app-constants'

/**
 *  Helper method to retrieve list of profiles which meet the search query
 */
function fetchProfiles(
  searchValue,
  {requestId, profileType = 'charity'} = {},
  dispatch,
) {
  return new Promise((resolve, reject) => {
    dispatch(
      fetch(
        `${API_SERVER_URL}/v1/profiles/search?query=${searchValue}&profileType=${profileType}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ),
    )
      .then(response => response.json())
      .then(json => {
        return resolve({
          response: {
            data: json,
            requestId,
          },
        })
      })
      .catch(error => {
        return reject({
          response: {
            error,
            requestId,
          },
        })
      })
  })
}

/**
 * FetchProfiles
 */
export class FetchProfiles extends Component {
  static initialState = {loading: false, error: null, profiles: []}
  requestId = 0
  state = FetchProfiles.initialState
  mounted = false

  reset(overrides) {
    this.setState({...FetchProfiles.initialState, ...overrides})
  }

  fetch = debounce(() => {
    if (!this.mounted) {
      return
    }

    const {omitProfiles, profileType = 'charity', limit} = this.props
    this.requestId++

    fetchProfiles(
      this.props.searchValue,
      {omitProfiles, profileType, limit, requestId: this.requestId},
      this.props.dispatch,
    )
      .then(({response: {data: profiles, requestId}}) => {
        if (this.mounted && requestId === this.requestId) {
          this.props.onLoaded({profiles})
          this.setState({loading: false, profiles})
        }
      })
      .catch(({response: {error = null, requestId}}) => {
        if (this.mounted && requestId === this.requestId) {
          this.props.onLoaded({error})
          this.setState({loading: false, error})
        }
      })
  }, 300)

  prepareFetch() {
    this.reset({loading: true})
  }

  componentDidMount() {
    this.mounted = true
    this.prepareFetch()
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.searchValue !== this.props.searchValue ||
      prevProps.omitProfiles !== this.props.omitProfiles
    ) {
      this.prepareFetch()
      this.fetch()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }
  render() {
    return this.props.children(this.state)
  }
}

const ConnectedFetchProfiles = connect()(FetchProfiles)
export default ConnectedFetchProfiles
