// @flow
import React, {Component} from 'react'
import {Form} from 'react-bulma-components'
import {connect, getIn} from 'formik'
import TextInput from './TextInput'

type OptionId = number | string
type OptionItem = {id: OptionId, description: string, category?: string}

type CheckboxGroupFieldProps = {
  options?: Array<OptionItem>,
}

/**
 * CheckboxGroupField
 */
const CheckboxGroupField = class CheckboxGroupField extends Component<CheckboxGroupFieldProps> {
  onChange = (optionId: any, checked: boolean) => {
    const {
      name,
      formik: {values, setFieldValue},
    } = this.props

    let fieldValues = getIn(values, name, [])
    fieldValues = Array.isArray(fieldValues) ? fieldValues : [fieldValues]

    if (checked) {
      fieldValues = fieldValues.filter(option => option !== optionId)
    } else {
      fieldValues = [...fieldValues, optionId]
    }

    setFieldValue(name, fieldValues)
    if (this.props.onChange) {
      this.props.onChange(fieldValues)
    }
  }

  render() {
    const {
      name,
      otherFieldName = 'metadata.other',
      formik: {values},
      options,
    } = this.props

    const value = getIn(values, name, [])

    return (
      <React.Fragment>
        {options &&
          options.map(option => {
            const checked = Array.isArray(value) && value.includes(option.id)
            return (
              <Form.Control key={option.id}>
                <label id={`option-${option.id}`} className="checkbox">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => this.onChange(option.id, checked)}
                  />
                  <span>{option.description}</span>
                  {option.category === 'other' && checked && (
                    <TextInput name={otherFieldName} />
                  )}
                </label>
              </Form.Control>
            )
          })}
      </React.Fragment>
    )
  }
}

export default connect(CheckboxGroupField)
