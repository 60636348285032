import * as Sentry from '@sentry/minimal'

/**
 * Report the error to the interested parties
 */
export const reportError = (error, info) => {
  console.log("Error:")
  console.log(error)
  console.log("Info:")
  console.log(info)
  Sentry.captureException(error, {extra: info})
}

export default {
  reportError,
}
