import React, {Component} from 'react'
import {connect, Field} from 'formik'
// import {Async} from 'react-select'
import RadioboxField from '../../form/RadioboxField'
import ProfilePicker from '../../profiles/ProfilePicker'

import TextInput from '../../form/TextInput'
// import {Form} from 'react-bulma-components'

/**
 * DonationAudienceField
 */
export class DonationAudienceField extends Component {
  radiusOptions = [
    {label: '5km', value: 5000},
    {label: '10km', value: 10000},
    {label: '20km', value: 20000},
    {label: '30km', value: 30000},
  ]

  onRecipientSelect = data => {
    this.props.formik.setFieldTouched('metadata.donationAudience')
    this.props.formik.setFieldValue(
      'metadata.donationAudience',
      'recipient',
      false,
    )

    this.props.formik.setFieldTouched('metadata.donationRecipient', true)
    this.props.formik.setFieldValue(
      'metadata.donationRecipient',
      data.value,
      false,
    )
  }

  onRadiusSelect = event => {
    this.props.formik.handleChange(event)

    // Afterwards attempt to update the donationAudience selected by the user
    this.props.formik.setFieldTouched('metadata.donationAudience')
    this.props.formik.setFieldValue(
      'metadata.donationAudience',
      'radius',
      false,
    )
  }

  render() {
    const {metadata = {}} = this.props.formik.values
    const {/*donationRadius, donationRecipient, */ donationAudience} = metadata

    return (
      <React.Fragment>
        <TextInput
          id="audience-radius"
          name="metadata.donationAudience"
          type="radio"
          component={RadioboxField}
          checked={donationAudience === 'radius'}
          value="radius"
          ignoreError={true}
          data-testid="radius-option"
        >
          All charities in{' '}
          <Field
            component="select"
            name="metadata.donationRadius"
            onChange={this.onRadiusSelect}
          >
            {this.radiusOptions.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Field>{' '}
          radius
        </TextInput>
        <TextInput
          id="audience-mycommunity"
          name="metadata.donationAudience"
          type="radio"
          checked={donationAudience === 'my_community'}
          component={RadioboxField}
          value="my_community"
          ignoreError={true}
        >
          Charities in My Community
        </TextInput>
        <TextInput
          id="audience-recipient"
          name="metadata.donationAudience"
          type="radio"
          component={RadioboxField}
          checked={donationAudience === 'recipient'}
          value="recipient"
          ignoreError={false}
        >
          A specific charity{' '}
          <div
            style={{
              display: 'inline-block',
              width: '15rem',
              position: 'relative',
              top: '-0.5rem',
            }}
          >
            <ProfilePicker
              placeholder="Search for recipient..."
              getProfiles={this.props.getProfiles}
              onChange={selection =>
                this.onRecipientSelect({value: selection.id})
              }
            />
          </div>
        </TextInput>
      </React.Fragment>
    )
  }
}

export default connect(DonationAudienceField)
