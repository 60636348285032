// @flow
//import * as selectors from '../../common/reducers/donations/selectors'
import * as actions from '../../common/reducers/donations/actions'

import {Container, Heading, Section} from 'react-bulma-components'
import React, {Component} from 'react'

import DonationDetails from '../../common/components/donations/DonationDetails'
import {connect} from 'react-redux'
import {donations} from '../../common/reducers/resources/selectors'
import {getStatus} from 'redux-resource'

/**
 * DashboardDonationDetailsPage
 */
export class DashboardDonationDetailsPage extends Component {
    componentDidMount() {
        this.props.dispatch(this.props.getDonationById(this.props.id))
    }

    render() {
        const {selectedItem, requestStatus} = this.props
        return (
            <React.Fragment>
                <Section renderAs="header">
                    <Container>
                        <Heading>Donation ID: {this.props.id}</Heading>
                    </Container>
                </Section>
                <Section>
                    {requestStatus.succeeded ? (
                        <DonationDetails simplified={false} isLoading={false} itemData={selectedItem} />
                    ) : requestStatus.pending ? (
                        <p>Loading...</p>
                    ) : (
                        <p>Something went wrong while loading the food donation.</p>
                    )}
                </Section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectedItem: donations.getDonationById(state, ownProps.id),
        requestStatus: getStatus(state, `resources.donations.meta[${ownProps.id}].readStatus`),
        // requestStatus: donations.getFetchStatusById(state, `fetchDonation:${ownProps.id}`),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDonationById: actions.getDonationById,
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDonationDetailsPage)
