import * as Yup from 'yup'
import isEmpty from 'lodash/isEmpty'

export const MySettingsSchema = Yup.object().shape({
  contactVisibility: Yup.string().oneOf(['friends', 'everybody', 'nobody']),
  locationVisibility: Yup.string().oneOf(['friends', 'everybody', 'nobody']),

  deliveryStatuses: Yup.boolean(),
  friendSuggestions: Yup.boolean(),
  messaging: Yup.boolean(),
  newsletter: Yup.boolean(),

  currentPassword: Yup.string(),
  password: Yup.string().when('currentPassword', (value, schema) => {
    if (isEmpty(value)) {
      return schema.notRequired()
    }

    return schema
      .min(8, 'Must be at least 8 characters')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, {
        message:
          'Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number and least 8 characters',
        excludeEmptyString: true,
      })
      .required('Required')
  }),
  confirmPassword: Yup.string().when('currentPassword', (value, schema) => {
    if (isEmpty(value)) {
      return schema.notRequired()
    }

    return schema
      .oneOf([Yup.ref('password')], 'Please confirm the password')
      .required('Required')
  }),
})

export default MySettingsSchema
