// @flow
/*eslint react/default-props-match-prop-types: "off" */
import React, {Component} from 'react'

import {Button} from 'react-bulma-components'

type ParticipantBlockProps = {
  type: 'name',
  data: any,
  onItemClicked: (item: any) => void,
}

export class ParticipantBlock extends Component<ParticipantBlockProps> {
  static defaultProps = {
    type: 'name',
  }

  getItemValue(field: string): string {
    let itemValue
    if (Array.isArray(field)) {
      itemValue = field.map(fieldName => this.getItemValue(fieldName)).join(' ')
    } else {
      itemValue = this.props.data[field]
    }
    return itemValue
  }

  onLinkClicked = (e: MouseEvent) => {
    e.preventDefault()

    const {onItemClicked} = this.props
    if (onItemClicked) {
      onItemClicked(this.props.data)
    }
  }

  render() {
    const {type, data, onItemClicked, ...extraProps} = this.props // eslint-disable-line
    if (!data) {
      return <p>Not addressed yet</p>
    }

    return (
      <div>
        {type === 'name' && (
          <Button text={true} onClick={this.onLinkClicked}>
            {data.fullName}
          </Button>
        )}
      </div>
    )
  }
}

export default ParticipantBlock
