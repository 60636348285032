import {Box, Button, Columns, Container, Form, Heading, Hero} from 'react-bulma-components'
import React, {Component} from 'react'
import {
    authenticate,
    getHasFailedAuth,
    getIsAuthenticated,
    getIsRestored,
    getLastError,
    getSessionData,
    invalidateSession,
} from 'redux-simple-auth'

import {Formik} from 'formik'
import {LoginSchema} from '../../common/schemas/LoginSchema'
import TextInput from '../../common/components/form/TextInput'
import RecaptchaInput from '../../common/components/form/RecaptchaInput'

import {connect} from 'react-redux'
import {REACT_APP_FRONTEND_URL} from '../../app-constants'

/**
 * LoginPage
 */
export class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            captchaValid: false,
        }
    }
    onFormSubmit = ({username, password, shouldRemember = false}, {setStatus}) => {
        this.setState({isLoading: true})
        this.props
            .login(username, password, shouldRemember)
            .then(({payload: sessionData}) => {
                this.setState({isLoading: false})

                this.props.navigate('/dashboard/overview')
            })
            .catch((err) => {
                this.setState({isLoading: false})
                setStatus('Something went wrong. Try again later.')
            })
    }

    handleVerify = () => {
        this.setState({
            captchaValid: true,
        })
    }

    render() {
        const {captchaValid} = this.state
        return (
            <Hero size="fullheight" color="light">
                <Hero.Body>
                    <Container size="fullheight">
                        <Columns multiline={false} centered={true}>
                            <Columns.Column size={5}>
                                <Box>
                                    <Heading subtitle size={4} className="has-text-centered">
                                        Welcome back!
                                    </Heading>

                                    <Formik
                                        initialValues={{email: '', password: ''}}
                                        validationSchema={LoginSchema}
                                        onSubmit={this.onFormSubmit}
                                    >
                                        {(props) => {
                                            const {handleSubmit, status} = props

                                            return (
                                                <form onSubmit={handleSubmit}>
                                                    {status && <Form.Help color="danger">{status}</Form.Help>}

                                                    <TextInput name="username" label="Email" />
                                                    <TextInput name="password" type="password" label="Password" />
                                                    <RecaptchaInput name="captcha" onVerify={this.handleVerify} />
                                                    <Form.Field>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            size="medium"
                                                            disabled={!captchaValid}
                                                            fullwidth={true}
                                                        >
                                                            Sign in
                                                        </Button>
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <Form.Control className="has-text-centered">
                                                            <a href={`${REACT_APP_FRONTEND_URL}/reset_password`}>
                                                                Forgot password?
                                                            </a>
                                                        </Form.Control>
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <Form.Control className="has-text-centered">
                                                            Don't have an account?{' '}
                                                            <a href={`${REACT_APP_FRONTEND_URL}/registration`}>
                                                                Get started now
                                                            </a>
                                                        </Form.Control>
                                                    </Form.Field>
                                                </form>
                                            )
                                        }}
                                    </Formik>
                                </Box>
                            </Columns.Column>
                        </Columns>
                    </Container>
                </Hero.Body>
            </Hero>
        )
    }
}

const mapStateToProps = (state) => ({
    isSessionRestored: getIsRestored(state),
    lastError: getLastError(state),
    isAuthenticated: getIsAuthenticated(state),
    hasFailedAuth: getHasFailedAuth(state),
    user: getSessionData(state),
})

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password, shouldRemember) =>
            dispatch(
                authenticate('credentials', {
                    username: username,
                    password: password,
                    remember_me: shouldRemember,
                }),
            ),
        logout: () => dispatch(invalidateSession()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
