// @flow
import React, {Component} from 'react'
import {FieldArray, getIn, connect} from 'formik'
import {Heading, Button} from 'react-bulma-components'
import {getTotalWeightOfDonation} from '../../../selectors/donations'
import DonationItemForm from './DonationItemForm'

//type OptionId = number | string
//type OptionItem = {id: OptionId, description: string}

type DonationItemsFieldProps = {
  name: string,
  // options?: Array<OptionItem>,
  formik: FormikProps<any>,
}

/**
 * DonationItemsField
 */
class DonationItemsField extends Component<DonationItemsFieldProps> {
  componentDidMount() {
    this.didMount = true
    this.checkRequirements()
  }

  componentWillUnmount() {
    this.didMount = false
  }

  checkRequirements = () => {
    const {name} = this.props

    const items = getIn(this.props.formik.values, name)
    if (Array.isArray(items) && items.length > 0) {
      return
    }

    // Add the default donation item to the field to ensure one is always being shown
    this.addItem()
  }

  addItem = () => {
    const {name} = this.props
    const defaultFoodItem = {
      productName: '',
      quantity: 1,
      itemWeight: 200,
      suggestedWeight: 200,
      unitOfMeasure: 'G',
      metadata: {
        measurementType: 'weight',
      },
    }

    if (!this.props.formik) {
      return
    }

    const items = getIn(this.props.formik.values, name, [])
    this.props.formik.setValues({
      ...this.props.formik.values,
      [name]: [...items, defaultFoodItem],
    })
  }

  render() {
    const {name} = this.props
    return (
      <FieldArray
        data-testid="donations-items"
        name={name}
        render={arrayHelpers => {
          const items = getIn(arrayHelpers.form.values, name, [])
          return (
            <React.Fragment>
              {Array.isArray(items) &&
                items.map((item, index) => {
                  return (
                    <DonationItemForm
                      key={index}
                      index={index}
                      name={name}
                      data={item}
                      onRemoveItem={_itemIndex => {
                        // TODO: check if we should use _itemIndex instead
                        arrayHelpers.remove(_itemIndex)
                      }}
                    />
                  )
                })}
              <div className="buttons">
                <Button color="info" type="button" onClick={this.addItem}>
                  Add item
                </Button>
              </div>
              <div className="donation__metadata">
                <Heading className="is-marginless" renderAs="h5" size={5}>
                  Total weight of donation
                </Heading>
                <p data-testid="weight">
                  {getTotalWeightOfDonation({items})}{' '}
                  <span className="unit">kg</span>
                </p>
              </div>
            </React.Fragment>
          )
        }}
      />
    )
  }
}

export default connect(DonationItemsField)
