import React, {Component} from 'react'
import {AlertDialog, AlertDialogLabel, AlertDialogDescription} from '@reach/alert-dialog'
import '@reach/dialog/styles.css'

type DialogProps = {
    title: string,
    description: string,
    buttons: Array,
    isOpen: boolean,
}

/**
 * GenericConfirmDialog
 */
export class GenericConfirmDialog extends Component<DialogProps> {
    static defaultProps = {isOpen: false}

    constructor(props: DialogProps) {
        super(props)
        this.dialogRef = React.createRef()
    }

    /**
     * @private
     */
    onDialogResponse = response => {
        this.props.onResponse && this.props.onResponse(response)
    }

    render() {
        const {title, description, confirmButtonLabel, cancelButtonLabel} = this.props
        return (
            <AlertDialog leastDestructiveRef={this.dialogRef}>
                <AlertDialogLabel>{title}</AlertDialogLabel>
                <AlertDialogDescription>{description}</AlertDialogDescription>

                <div className="buttons">
                    <button className="control button is-danger" onClick={event => this.onDialogResponse(true)}>
                        {confirmButtonLabel}
                    </button>{' '}
                    <button
                        className="control button"
                        ref={this.dialogRef}
                        onClick={event => this.onDialogResponse(false)}
                    >
                        {cancelButtonLabel}
                    </button>
                </div>
            </AlertDialog>
        )
    }
}

export default GenericConfirmDialog
