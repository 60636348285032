// @flow
import React, {Component} from 'react'
import {
  Button,
  Box,
  Container,
  Heading,
  Level,
  Form,
} from 'react-bulma-components'
import {Pagination} from './Pagination'
//import './EntitiesDataTable.scss'

type FilterModeItem = {
  id: number | string,
  description: string,
}
type FilterModeItemList = Array<FilterModeItem>

type EntitiesDataTableProps = {
  filterModes: FilterModeItemList,
  data: Array<object>,
  // itemsPerPage: number,
  // paginate: boolean,
  render: any,
  pagination: object,
}

type EntitiesDataTableState = {
  data: array,
  selectedFilterMode: string,
  searchQuery: string,
}

/**
 * EntitiesDataTable
 */
export class EntitiesDataTable extends Component<
  EntitiesDataTableProps,
  EntitiesDataTableState,
> {
  static defaultProps = {
    // data: [],
    // itemsPerPage: 20,
    // paginate: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      // data: [],
      selectedFilterMode: props.selectedFilterMode || 'all',
      searchQuery: '',
    }
  }

  search = value => {
    this.setState({searchQuery: value})
  }

  setFilterMode = id => {
    this.setState({selectedFilterMode: id}, () => {
      if (this.props.onFilterChange) {
        this.props.onFilterChange(id)
      }
    })
  }

  onSearchChanging = event => {
    const searchQuery = event.target.value || ''
    this.setState({searchQuery})
  }

  onSearchChanged = () => {
    if (this.props.onQueryChange) {
      this.props.onQueryChange(this.state.searchQuery)
    }
  }

  onClearSearchClick = (_event: MouseEvent) => {
    this.setState({searchQuery: ''}, () => {
      if (this.props.onQueryCleared) {
        this.props.onQueryCleared()
      }
    })
  }

  onFieldKeypress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.onSearchChanged()
      setTimeout(() => {
        this.onSearchClick()
      }, 500)
    }
  }

  onSearchClick = (_event: MouseEvent) => {
    if (this.props.onSearchClick) {
      this.props.onSearchClick()
    }
  }

  render() {
    const {
      render,
      pagination = {},
      data = [],
      filterModes = [],
      allowDownloads = false,
    } = this.props
    const {searchQuery = ''} = this.state
    const {totalItems = 0} = pagination

    const selectedFilterMode =
      this.state.selectedFilterMode || this.props.selectedFilterMode

    return (
      <Box className="is-shadowless is-marginless is-paddingless entities-datatable">
        <Container>
          <Box>
            <Level renderAs="nav">
              <Level.Side align="left">
                <Level.Item>
                  <Heading size={5} subtitle>
                    <strong>{totalItems}</strong> items
                  </Heading>
                </Level.Item>
                <Level.Item>
                  <Form.Field kind="addons">
                    <Form.Control>
                      <Form.Input
                        style={{paddingRight: '30px', overflow: 'hidden'}}
                        value={searchQuery}
                        onChange={this.onSearchChanging}
                        onKeyPress={this.onFieldKeypress}
                        onBlur={this.onSearchChanged}
                        placeholder="Find a item"
                      />
                      {searchQuery && searchQuery.length && (
                        <Button
                          style={{
                            right: '6px',
                            top: '8px',
                            zIndex: 10,
                            position: 'absolute',
                          }}
                          remove={true}
                          onClick={this.onClearSearchClick}
                          renderAs="button"
                        />
                      )}
                    </Form.Control>
                    <Form.Control>
                      <Button
                        color="primary"
                        onClick={this.onSearchClick}
                        renderAs="button"
                      >
                        Search
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </Level.Item>
              </Level.Side>
              <Level.Side align="right">
                {allowDownloads && (
                  <Level.Item>
                    <Button
                      className="is-secondary"
                      loading={this.props.isDownloading}
                      onClick={this.props.onDownloadClick}
                    >
                      Download as CSV
                    </Button>
                  </Level.Item>
                )}
                {filterModes.map(filter => (
                  <Level.Item key={filter.id}>
                    {selectedFilterMode === filter.id && (
                      <strong>{filter.description}</strong>
                    )}
                    {selectedFilterMode !== filter.id && (
                      <a
                        href={`#filter-${filter.id}`}
                        onClick={_event => {
                          this.setFilterMode(filter.id)
                        }}
                      >
                        {filter.description}
                      </a>
                    )}
                  </Level.Item>
                ))}
              </Level.Side>
            </Level>
          </Box>
        </Container>
        <Box className="is-shadowless main-content">
          {data.map((item, index) => render({key: index, data: item}))}
        </Box>
        <Box className="is-shadowless">
          <Pagination
            pagination={pagination}
            onChange={this.props.onPagination}
          />
        </Box>
      </Box>
    )
  }
}

export default EntitiesDataTable
