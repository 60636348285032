import React, {Component} from 'react'
import {Button, Columns, Form} from 'react-bulma-components'
import {connect, getIn} from 'formik'

import TextInput from '../../form/TextInput'
import WeightTextField from '../WeightTextField'
import DonationItemWeightCalculator from '../DonationItemWeightCalculator'

/**
 * DonationItemForm
 */
const DonationItemForm = class DonationItemForm extends Component {
  onWeightFieldChanged = value => {
    const {index: itemIndex = 0, name = 'items'} = this.props
    const fieldName = `${name}[${itemIndex}].`
    this.props.formik.setFieldValue(`${fieldName}itemWeight`, value)
    this.props.formik.setFieldValue(`${fieldName}suggestedWeight`, value)
    this.props.formik.setFieldValue(`${fieldName}quantity`, 1)
  }

  render() {
    const {index: itemIndex = 0, name = 'items'} = this.props
    const isFirstItem = itemIndex === 0
    const fieldName = `${name}[${itemIndex}]`
    const data = getIn(this.props.formik.values, `${fieldName}`, {})
    return (
      <Columns>
        <Columns.Column size={3}>
          <TextInput
            name={`${fieldName}.productName`}
            data-testid="item-productname"
            label={isFirstItem ? 'Food item' : null}
            placeholder="Enter product name"
            required
          />
        </Columns.Column>
        <Columns.Column size={3}>
          <TextInput
            name={`${fieldName}.metadata.measurementType`}
            data-testid="item-measurementtype"
            component={Form.Select}
            label={isFirstItem ? 'Measurement' : null}
          >
            <option value="">Please select one</option>
            <option value="quantity">Quantity</option>
            <option value="weight">Weight</option>
          </TextInput>
        </Columns.Column>
        <Columns.Column>
          {data &&
            data.metadata &&
            data.metadata.measurementType === 'quantity' && (
              <React.Fragment>
                <DonationItemWeightCalculator
                  itemIndex={itemIndex}
                  name={`${fieldName}`}
                />
              </React.Fragment>
            )}
          {data && data.metadata && data.metadata.measurementType === 'weight' && (
            <React.Fragment>
              <TextInput
                id={`${name}${itemIndex}_itemWeight`}
                name={`${name}[${itemIndex}].itemWeight`}
                data-testid="item-weight"
                component={WeightTextField}
                unitOfMeasure="kg"
                help={isFirstItem ? 'E.g. for 200g enter 0.2' : null}
                label={isFirstItem ? 'Weight' : null}
                onFieldChange={this.onWeightFieldChanged}
              />
            </React.Fragment>
          )}
          <div style={{position: 'relative'}}>
            {itemIndex > 0 && (
              <Button
                data-testid="item-additem"
                remove
                onClick={_event =>
                  this.props.onRemoveItem && this.props.onRemoveItem(itemIndex)
                }
                style={{
                  position: 'absolute',
                  right: '0.75rem',
                  bottom: isFirstItem ? '0.5rem' : '1rem',
                }}
              >
                Delete item
              </Button>
            )}
          </div>
          {/* {process.env.NODE_ENV !== 'production' && (
                        <pre>{JSON.stringify(this.props.formik.errors, null, 2)}</pre>
                    )} */}
        </Columns.Column>
      </Columns>
    )
  }
}

export default connect(DonationItemForm)
