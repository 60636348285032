import React from 'react'
import classNames from 'classnames'
import {
  IconTypeBusiness,
  IconTypeCharity,
  IconTypeTransporter,
  IconTypeVolunteer,
} from '../../icons'

import {CircularIcon} from '../Icon'

//import './ProfileTypeIndicator.scss'

const ProfileTypeIndicator = ({role: roleType = 'business', label}) => {
  let IconComponent = null
  switch (roleType) {
    case 'business':
      IconComponent = IconTypeBusiness
      break
    case 'charity':
      IconComponent = IconTypeCharity
      break
    case 'transporter':
      IconComponent = IconTypeTransporter
      break
    case 'volunteer':
      IconComponent = IconTypeVolunteer
      break
    default:
      break
  }

  return (
    <div className={classNames('profiletype_indicator', `type-${roleType}`)}>
      <CircularIcon
        icon={IconComponent}
        className="icon_profiletype"
        aria-label={roleType}
      />
      <p>{label || roleType}</p>
    </div>
  )
}

export default ProfileTypeIndicator
