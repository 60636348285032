import * as Yup from 'yup'

import {isEmailAddressUnique} from '../utils/isEmailAddressUnique'
import {AddressSchema} from './AddressSchema'

export const SignUpSchema = Yup.object().shape({
  profile: Yup.object().shape({
    profileType: Yup.string().required(),
    addresses: Yup.array()
      .min(1, 'At least one item needs to be defined')
      .of(AddressSchema),
    charityNumber: Yup.string(),
    foodRegistrationNumber: Yup.string(),
    foodTransportConfirmation: Yup.string(),

    // Profile
    averageDonation: Yup.string(), // averageDonation -> donationSize lookup
    collectionCapacity: Yup.string(), // collectionCapacity -> collectionCapacity
    donationSizes: Yup.array().notRequired(),
    transportOptions: Yup.array().notRequired(),
    volunteerRoles: Yup.array().notRequired(),
    foodTypes: Yup.array().notRequired(),

    // Legal matter
    hasSignAuthority: Yup.boolean().when('$currentStep', {
      is: 3,
      then: Yup.boolean()
        .required()
        .oneOf([true], 'Agreement needs to be accepted'),
      otherwise: Yup.boolean().notRequired(),
    }),
    hasAcceptedTerms: Yup.boolean().when('$currentStep', {
      is: 3,
      then: Yup.boolean()
        .required()
        .oneOf([true], 'Terms and conditions needs to be accepted'),
      otherwise: Yup.boolean().notRequired(),
    }),
  }),
  user: Yup.object().shape({
    username: Yup.string()
      .email('Invalid email address')
      // eslint-disable-next-line no-template-curly-in-string
      .test(
        'is-unique',
        '${value} is already used, please choose a different email address', // eslint-disable-line no-template-curly-in-string
        value => {
          return isEmailAddressUnique(value)
        },
      )
      .required('Required'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, {
        message:
          'Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number and least 8 characters',
        excludeEmptyString: true,
      })
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Please confirm the password')
      .required('Required'),
  }),
})

export default SignUpSchema
