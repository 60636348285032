// @flow
import {Button, Columns, Container, Form, Heading, Menu, Section, Table} from 'react-bulma-components'
import React, {Component} from 'react'
import {fetch, getIsAuthenticated, getIsRestored} from 'redux-simple-auth'

import {DateTime} from 'luxon'
import {connect} from 'react-redux'
import download from 'downloadjs'
import {AlertDialog, AlertDialogLabel, AlertDialogDescription} from '@reach/alert-dialog'
import '@reach/dialog/styles.css'
import ProfilePicker from '../../common/components/profiles/ProfilePicker'
import * as uiActions from './reducer/actions'
import * as uiSelectors from './reducer/selectors'
import {handleErrors} from '../../common/utils/handleErrors';

type DashboardReportsPageProps = {}

/**
 * DashboardReportsPage
 */
export class DashboardReportsPage extends Component<DashboardReportsPageProps> {
    state = {}

    constructor(props) {
        super(props)
        this.modalDownloadImpactReportRef = React.createRef()
    }

    downloadDonationsReport = (requestedMonth: string) => {
        const timePeriod = DateTime.fromISO(requestedMonth)
        const periodStart = timePeriod.startOf('month').toISODate()
        const periodEnd = timePeriod.endOf('month').toISODate()
        const formattedDate = timePeriod.toFormat('dd.LL.yyyy HH:mm', {locale: 'en-gb'})

        this.props
            .dispatch(
                fetch(`${window.env.REACT_APP_GATEWAY_SERVER_URL}/v1/admin/reports/donations?start=${periodStart}&end=${periodEnd}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
            )
            .then(res => res.blob())
            .then(contents => {
                download(contents, `Donations report ${formattedDate}.xlsx`, contents.type)
            })
            .catch(error => {
                console.log('Error: ', error)
            })
    }

    downloadLoginsReport = (requestedMonth: string) => {
        const timePeriod = DateTime.fromISO(requestedMonth)
        const periodStart = timePeriod.startOf('month').toISODate()
        const periodEnd = timePeriod.endOf('month').toISODate()
        const formattedDate = timePeriod.toFormat('dd.LL.yyyy HH:mm', {locale: 'en-gb'})

        this.props
            .dispatch(
                fetch(`${window.env.REACT_APP_GATEWAY_SERVER_URL}/v1/admin/reports/logins?start=${periodStart}&end=${periodEnd}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
            )
            .then(res => res.blob())
            .then(contents => {
                download(contents, `User login report ${formattedDate}.xlsx`, contents.type)
            })
            .catch(error => {
                console.log('Error: ', error)
            })
    }

    downloadImpactReport = (profileId: number) => {
        const periodStart = '2014-01-01'
        const periodEnd = DateTime.utc()
            .endOf('month')
            .toISODate()
        const formattedDate = '' //const formattedDate = periodEnd.toFormat('dd.LL.yyyy HH:mm', {locale: 'en-gb'})

        this.props
            .dispatch(
                fetch(
                    `${window.env.REACT_APP_GATEWAY_SERVER_URL}/v1/reports/donations/branch/${profileId}?start=${periodStart}&end=${periodEnd}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                ),
            )
            .then(handleErrors)
            .then(res => res.blob())
            .then(contents => {
                download(contents, `Impact report ${profileId} ${formattedDate}.xlsx`, contents.type)
            })
            .catch(error => {
                console.log('Error: ', error)
            })
    }

    getAvailableReportMonthForYear(selectedYear: string) {
        const today = DateTime.local()
        const currentYear = today.get('year')
        const firstMonth = today.startOf('year')
        const lastMonth = today.endOf('year')
        const minMonth = firstMonth.get('month')
        const maxMonth = selectedYear === currentYear ? today.get('month') : lastMonth.get('month')

        let availableReportMonths = []
        for (let currentMonth = minMonth; currentMonth <= maxMonth; currentMonth++) {
            const newMonth = firstMonth.set({month: currentMonth, year: selectedYear})
            availableReportMonths.push(newMonth.toISODate())
        }
        return availableReportMonths
    }

    getAvailableReportYears() {
        const minimumYear = 2015
        const maximumYear = DateTime.local()
            .startOf('year')
            .get('year')

        let availableReportYears = []
        for (let currentYear = minimumYear; currentYear <= maximumYear; currentYear++) {
            availableReportYears.push(currentYear)
        }

        return availableReportYears.reverse()
    }

    switchToYear = (year: number) => {
        this.props.navigate(`/dashboard/reports/${year}`)
    }

    onDownloadImpactReport = () => {
        this.props.dispatch(uiActions.setModalState('modalDownloadImpactReport', true))
    }

    setProfileForDownloadImpactReport = selectedProfile => {
        this.props.dispatch(uiActions.setUiState('selectedReportProfileId', selectedProfile.id))
    }

    onDownloadImpactReportResponse = result => {
        this.props.dispatch(uiActions.setModalState('modalDownloadImpactReport', false))
        if (!result) {
            return
        }

        console.log('onDownloadImpactReportResponse() selectedReportProfileId: ', this.props.selectedReportProfileId)
        this.downloadImpactReport(this.props.selectedReportProfileId)
    }

    getProfilesByQuery = (filter: string) => {
        return []
    }

    render() {
        const selectedYear = parseInt(this.props.selectedYear, 10) || DateTime.local().get('year')
        const availableReports = this.getAvailableReportMonthForYear(selectedYear)
        const availableYears = this.getAvailableReportYears()
        const {modalDownloadImpactReport = false} = this.props

        return (
            <React.Fragment>
                <Section renderAs="header">
                    <Container>
                        <Heading>Monthly reports</Heading>
                        <Button onClick={this.onDownloadImpactReport} color="primary">
                            Download Impact Report for Profile...
                        </Button>
                    </Container>
                </Section>
                <Section>
                    <Columns>
                        <Columns.Column size={3}>
                            <Menu>
                                <Menu.List title="Available Years">
                                    {availableYears.length > 0 &&
                                        availableYears.map((year, index) => {
                                            return (
                                                <Menu.List.Item key={index}>
                                                    <Button text={true} onClick={event => this.switchToYear(year)}>
                                                        {year}
                                                    </Button>
                                                </Menu.List.Item>
                                            )
                                        })}
                                </Menu.List>
                            </Menu>
                        </Columns.Column>
                        <Columns.Column>
                            <Table>
                                <tbody>
                                    {availableReports.length === 0 && (
                                        <tr colspan="3">
                                            <Form.Help color="danger">No reports are currently available.</Form.Help>
                                        </tr>
                                    )}
                                    {availableReports.length > 0 &&
                                        availableReports.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {DateTime.fromISO(item).toLocaleString({
                                                        month: 'long',
                                                        year: 'numeric',
                                                    })}
                                                </td>
                                                <td>
                                                    <Button
                                                        text={true}
                                                        onClick={event => this.downloadDonationsReport(item)}
                                                    >
                                                        Donations
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button
                                                        text={true}
                                                        onClick={event => this.downloadLoginsReport(item)}
                                                    >
                                                        Logins
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Columns.Column>
                    </Columns>
                    {modalDownloadImpactReport && (
                        <AlertDialog leastDestructiveRef={this.modalDownloadImpactReportRef}>
                            <AlertDialogLabel>Download Impact Report</AlertDialogLabel>
                            <AlertDialogDescription>
                                <div>
                                    <p>
                                        Select the profile you would like to download the Impact Report for this
                                        resembles the same report that a multi-branch profile can download for its
                                        branch profiles.
                                    </p>
                                    <ProfilePicker
                                        allowProfileTypes="charity,business"
                                        getProfiles={this.getProfilesByQuery}
                                        onChange={selection => this.setProfileForDownloadImpactReport(selection)}
                                    />
                                </div>
                            </AlertDialogDescription>

                            <div className="buttons">
                                <button
                                    className="control button is-danger"
                                    onClick={event => this.onDownloadImpactReportResponse(true)}
                                >
                                    Download report
                                </button>{' '}
                                <button
                                    className="control button"
                                    ref={this.cancelClaimDonationRef}
                                    onClick={event => this.onDownloadImpactReportResponse(false)}
                                >
                                    Nevermind
                                </button>
                            </div>
                        </AlertDialog>
                    )}
                </Section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isSessionRestored: getIsRestored(state),
    isAuthenticated: getIsAuthenticated(state),
    selectedReportProfileId: uiSelectors.getUiState('selectedReportProfileId')(state),
    modalDownloadImpactReport: uiSelectors.getModalState('modalDownloadImpactReport')(state),
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardReportsPage)
