// @flow
import '@reach/dialog/styles.css'
import './DashboardProfilesPage.scss'

import * as actions from './reducer/actions';
import * as selectors from '../../common/reducers/profiles/selectors'
import * as uiActions from './reducer/actions'
import * as uiSelectors from './reducer/selectors'
import adminUserActions from '../../common/reducers/users/adminActions'

import {AlertDialog, AlertDialogDescription, AlertDialogLabel} from '@reach/alert-dialog'
import {Container, Heading, Section} from 'react-bulma-components'
import React, {Component} from 'react'

import {DateTime} from 'luxon'
import {EntitiesDataTable} from '../../common/components'
import ProfileDetailsItem from './ProfileDetailsItem'
import {connect} from 'react-redux'
import download from 'downloadjs'
import get from 'lodash/get'
import {getStatus, getResources} from 'redux-resource'

/**
 * DashboardProfilesPage
 */
export class DashboardProfilesPage extends Component {
    state = {searchQuery: ''}

    constructor(props) {
        super(props)
        this.cancelApproveProfileRef = React.createRef()
        this.cancelUnapproveProfileRef = React.createRef()
        this.cancelUpgradeProfileRef = React.createRef()
        this.cancelDowngradeProfileRef = React.createRef()
        this.cancelDeleteProfileRef = React.createRef()
    }

    componentDidMount() {
        this.refreshItems()
    }

    onSearchQueryChanged = (query: string) => {
        this.props.dispatch(uiActions.setUiState('profileSearchQuery', query))
    }

    onSearchCleared = () => {
        this.props.dispatch(uiActions.setUiState('profileSearchQuery', ''))
        setTimeout(() => {
            this.refreshItems()
        }, 500)
    }

    onSearchItems = event => {
        this.refreshItems()
    }

    onApproveProfileResponse = (response: boolean) => {
        const approveProfileId = this.props.approveProfileId

        // Dismiss the alert dialog
        this.props.dispatch(uiActions.setUiState('approveProfileId', null))
        this.props.dispatch(uiActions.setModalState('confirmApproveProfile', false))
        if (!response) {
            return
        }

        // Claim the donation on behalf of the adminsitrative user
        this.props.dispatch(actions.approveProfile(approveProfileId))
    }

    onUnapproveProfileResponse = (response: boolean) => {
        const approveProfileId = this.props.approveProfileId

        // Dismiss the alert dialog
        this.props.dispatch(uiActions.setUiState('approveProfileId', null))
        this.props.dispatch(uiActions.setModalState('confirmUnapproveProfile', false))
        if (!response) {
            return
        }

        // Claim the donation on behalf of the adminsitrative user
        this.props.dispatch(actions.unapproveProfile(approveProfileId))
    }

    onUpgradeProfileResponse = (response: boolean) => {
        const upgradeProfileId = this.props.upgradeProfileId

        // Dismiss the alert dialog
        this.props.dispatch(uiActions.setUiState('upgradeProfileId', null))
        this.props.dispatch(uiActions.setModalState('confirmUpgradeProfile', false))
        if (!response) {
            return
        }

        this.props.dispatch(actions.patchProfile(upgradeProfileId, {membershipType: 'foodsaver'}))
    }

    onDowngradeProfileResponse = (response: boolean) => {
        const upgradeProfileId = this.props.upgradeProfileId

        // Dismiss the alert dialog
        this.props.dispatch(uiActions.setUiState('upgradeProfileId', null))
        this.props.dispatch(uiActions.setModalState('confirmDowngradeProfile', false))
        if (!response) {
            return
        }

        this.props.dispatch(actions.patchProfile(upgradeProfileId, {membershipType: 'free'}))
    }

    onDeleteProfileResponse = (response: boolean) => {
        const deleteProfileId = this.props.deleteProfileId

        // Dismiss the alert dialog
        this.props.dispatch(uiActions.setUiState('deleteProfileId', null))
        this.props.dispatch(uiActions.setModalState('confirmDeleteProfile', false))
        if (!response) {
            return
        }

        this.props.dispatch(actions.deleteProfile(deleteProfileId))
    }

    handleItemAction = (action: string, item: any) => {
        switch (action) {
            case 'details':
                this.props.dispatch(uiActions.setUiState('selectedProfileId', item.id))
                this.props.navigate(`/dashboard/profiles/${item.id}`)
                break
            case 'network':
                this.props.dispatch(uiActions.setUiState('selectedProfileId', item.id))
                this.props.navigate(`/dashboard/profiles/${item.id}/network`)
                break
            case 'activation':
                this.props.dispatch(uiActions.setUiState('selectedProfileId', item.id))

                const relevantUserId = item.userId
                if (relevantUserId) {
                    this.props.dispatch(adminUserActions.resendActivationMailForUserId(relevantUserId))
                }
                break
            case 'approve':
                this.props.dispatch(uiActions.setUiState('approveProfileId', item.id))
                this.props.dispatch(uiActions.setModalState('confirmApproveProfile', true))
                break
            case 'unapprove':
                this.props.dispatch(uiActions.setUiState('approveProfileId', item.id))
                this.props.dispatch(uiActions.setModalState('confirmUnapproveProfile', true))
                break
            case 'upgrade':
                this.props.dispatch(uiActions.setUiState('upgradeProfileId', item.id))
                this.props.dispatch(uiActions.setModalState('confirmUpgradeProfile', true))
                break
            case 'downgrade':
                this.props.dispatch(uiActions.setUiState('upgradeProfileId', item.id))
                this.props.dispatch(uiActions.setModalState('confirmDowngradeProfile', true))
                break
            case 'delete':
                this.props.dispatch(uiActions.setUiState('deleteProfileId', item.id))
                this.props.dispatch(uiActions.setModalState('confirmDeleteProfile', true))
                break
            default:
                break
        }
    }

    refreshItems = (page: string, options: object = {}) => {
        const {profileSearchQuery, selectedFilterMode} = this.props
        switch (selectedFilterMode) {
            case 'approved':
                this.props.dispatch(actions.getApprovedProfiles(profileSearchQuery, page, options))
                break
            case 'unapproved':
                this.props.dispatch(actions.getUnapprovedProfiles(profileSearchQuery, page, options))
                break
            case 'deleted':
                this.props.dispatch(actions.getDeletedProfiles(profileSearchQuery, page, options))
                break
            case 'unconfirmed':
                this.props.dispatch(actions.getUnconfirmedProfiles(profileSearchQuery, page, options))
                break
            default:
                this.props.dispatch(actions.getProfiles(profileSearchQuery, page, options))
                break
        }
    }

    onPaginationChanged = (page: string) => {
        const {pagination} = this.props
        if (page === 'next') {
            const nextCursor = get(pagination, 'links.next.cursor')
            if (!nextCursor) {
                return
            }

            this.refreshItems(nextCursor)
        } else {
            const previousCursor = get(pagination, 'links.previous.cursor')
            if (!previousCursor) {
                return
            }

            this.refreshItems(previousCursor)
        }
    }

    onDownload = event => {
        event.preventDefault()
        this.refreshItems(null, {list: 'download', requestKey: 'download-profiles', limit: -1})
    }

    deliverDownload() {
        const formattedDate = DateTime.utc().toFormat('dd.LL.yyyy HH:mm', {locale: 'en-gb'})

        const rows = this.props.downloadItems.map(item => {
            const row = [
                item.id,
                item.username,
                item.fullName,
                item.membershipType,
                item.profileType,
                item.isApprovedProfile,
                item.isConfirmed,
                item.created_at,
            ]
            return row.join(',')
        })

        const headers = [
            'ID',
            'Email',
            'Full Name',
            'Membership Type',
            'Profile  Type',
            'Is Approved?',
            'Is Confirmed?',
            'Created At',
        ].join(',')
        let contents = `${headers}\r\n` + rows.join('\r\n')
        download(contents, `${formattedDate}.csv`, 'data:text/csv;charset=utf-8')
    }

    onFilterChanged = (filter: string) => {
        this.props.dispatch(uiActions.setUiState('selectedFilterMode', filter))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedFilterMode !== this.props.selectedFilterMode) {
            this.refreshItems()
        } else if (this.props.downloadFetchStatus.succeeded) {
            this.deliverDownload()
        }
    }

    render() {
        const {
            selectedFilterMode = 'all',
            confirmApproveProfile = false,
            confirmUnapproveProfile = false,
            confirmUpgradeProfile = false,
            confirmDowngradeProfile = false,
            confirmResendConfirmation = false,
            confirmDeleteProfile = false,
            upgradeProfileId = null,
            deleteProfileId = null,
            approveProfileId = null,
            pagination = {},
            items = [],
        } = this.props

        console.log("pagination");
        console.log(pagination);

        return (
            <React.Fragment>
                <Section renderAs="header">
                    <Container>
                        <Heading>Profiles</Heading>
                    </Container>
                </Section>
                <Section style={{paddingTop: 0}}>
                    <EntitiesDataTable
                        pagination={pagination}
                        selectedFilterMode={selectedFilterMode}
                        onPagination={this.onPaginationChanged}
                        onQueryChange={this.onSearchQueryChanged}
                        onSearchClick={this.onSearchItems}
                        onQueryCleared={this.onSearchCleared}
                        onFilterChange={this.onFilterChanged}
                        onDownloadClick={this.onDownload}
                        isDownloading={this.props.downloadFetchStatus && this.props.downloadFetchStatus.pending}
                        allowDownloads={true}
                        filterModes={[
                            {id: 'all', description: 'All'},
                            {id: 'unconfirmed', description: 'Email not verified'},
                            {id: 'unapproved', description: 'List to approve'},
                            {id: 'approved', description: 'Approved'},
                            {id: 'deleted', description: 'Deleted'},
                        ]}
                        data={items}
                        render={props => {
                            return (
                                <ProfileDetailsItem
                                    key={props.key}
                                    data={props.data}
                                    onAction={this.handleItemAction}
                                />
                            )
                        }}
                    />
                </Section>
                {confirmResendConfirmation && (
                    <AlertDialog leastDestructiveRef={this.cancelApproveProfileRef}>
                        <AlertDialogLabel>
                            Are you sure you want to claim the profile with ID {approveProfileId}
                        </AlertDialogLabel>

                        <div className="buttons">
                            <button
                                className="control button is-danger"
                                onClick={event => this.onApproveProfileResponse(true)}
                            >
                                Yes, approve this profile
                            </button>{' '}
                            <button
                                className="control button"
                                ref={this.cancelApproveProfileRef}
                                onClick={event => this.onApproveProfileResponse(false)}
                            >
                                Nevermind, don't approve
                            </button>
                        </div>
                    </AlertDialog>
                )}
                {confirmApproveProfile && (
                    <AlertDialog leastDestructiveRef={this.cancelApproveProfileRef}>
                        <AlertDialogLabel>
                            Are you sure you want to approve the profile with ID {approveProfileId}
                        </AlertDialogLabel>
                        <AlertDialogDescription>
                            When you approve a profile the profile will show up on the Community Map and participate in
                            food donations
                        </AlertDialogDescription>

                        <div className="buttons">
                            <button
                                className="control button is-danger"
                                onClick={event => this.onApproveProfileResponse(true)}
                            >
                                Yes, approve this profile
                            </button>{' '}
                            <button
                                className="control button"
                                ref={this.cancelApproveProfileRef}
                                onClick={event => this.onApproveProfileResponse(false)}
                            >
                                Nevermind, don't approve
                            </button>
                        </div>
                    </AlertDialog>
                )}
                {confirmUnapproveProfile && (
                    <AlertDialog leastDestructiveRef={this.cancelUnapproveProfileRef}>
                        <AlertDialogLabel>
                            Are you sure you want to unapprove the profile with ID {approveProfileId}
                        </AlertDialogLabel>
                        <AlertDialogDescription>
                            When you unapprove a profile the profile won't be showning up on the Community Map and can't
                            participate in food donations
                        </AlertDialogDescription>

                        <div className="buttons">
                            <button
                                className="control button is-danger"
                                onClick={event => this.onUnapproveProfileResponse(true)}
                            >
                                Yes, approve this profile
                            </button>{' '}
                            <button
                                className="control button"
                                ref={this.cancelUnapproveProfileRef}
                                onClick={event => this.onUnapproveProfileResponse(false)}
                            >
                                Nevermind, don't approve
                            </button>
                        </div>
                    </AlertDialog>
                )}
                {confirmUpgradeProfile && (
                    <AlertDialog leastDestructiveRef={this.cancelUpgradeProfileRef}>
                        <AlertDialogLabel>
                            Are you sure you want to upgrade the profile with ID {upgradeProfileId}
                        </AlertDialogLabel>
                        <AlertDialogDescription>
                            Upgrade the profile to a paid membership, you can always downgrade the profile back to a
                            free membership
                        </AlertDialogDescription>

                        <div className="buttons">
                            <button
                                className="control button is-danger"
                                onClick={event => this.onUpgradeProfileResponse(true)}
                            >
                                Yes, upgrade this profile
                            </button>{' '}
                            <button
                                className="control button"
                                ref={this.cancelUpgradeProfileRef}
                                onClick={event => this.onUpgradeProfileResponse(false)}
                            >
                                Nevermind, don't upgrade
                            </button>
                        </div>
                    </AlertDialog>
                )}
                {confirmDowngradeProfile && (
                    <AlertDialog leastDestructiveRef={this.cancelDowngradeProfileRef}>
                        <AlertDialogLabel>
                            Are you sure you want to downgrade the profile with ID {upgradeProfileId}
                        </AlertDialogLabel>
                        <AlertDialogDescription>
                            Downgrade the profile to the free membership, you can always upgrade the profile back to a
                            paid membership
                        </AlertDialogDescription>

                        <div className="buttons">
                            <button
                                className="control button is-danger"
                                onClick={event => this.onDowngradeProfileResponse(true)}
                            >
                                Yes, downgrade this profile
                            </button>{' '}
                            <button
                                className="control button"
                                ref={this.cancelDowngradeProfileRef}
                                onClick={event => this.onDowngradeProfileResponse(false)}
                            >
                                Nevermind, don't downgrade
                            </button>
                        </div>
                    </AlertDialog>
                )}
                {confirmDeleteProfile && (
                    <AlertDialog leastDestructiveRef={this.cancelDeleteProfileRef}>
                        <AlertDialogLabel>
                            Are you sure you want to delete the profile with ID {deleteProfileId}
                        </AlertDialogLabel>
                        <AlertDialogDescription>
                            When the a profile gets deleted the personal information will be anonmised and for this
                            reason this action can not be reverted.
                        </AlertDialogDescription>

                        <div className="buttons">
                            <button
                                className="control button is-danger"
                                onClick={event => this.onDeleteProfileResponse(true)}
                            >
                                Yes, delete this profile
                            </button>{' '}
                            <button
                                className="control button"
                                ref={this.cancelDeleteProfileRef}
                                onClick={event => this.onDeleteProfileResponse(false)}
                            >
                                Nevermind, don't delete
                            </button>
                        </div>
                    </AlertDialog>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log(state);
    return ({
    isLoading: selectors.isLoading(state),
    isLoaded: selectors.isLoaded(state),
    pagination: state.profiles.pagination,
    items: state.profiles.items, //getEntities(state.resources.profiles, uiSelectors.getUiState('selectedFilterMode')(state)),

    //
    downloadItems: getResources(state.resources.profiles, 'download'),
    downloadFetchStatus: getStatus(state, `meta[download-profiles].readStatus`), //profiles.getFetchStatusById(`download-profiles`)(state),

    // UI STATE
    selectedFilterMode: uiSelectors.getUiState('selectedFilterMode')(state),
    selectedProfileId: uiSelectors.getUiState('selectedProfileId')(state),
    profileSearchQuery: uiSelectors.getUiState('profileSearchQuery')(state),

    approveProfileId: uiSelectors.getUiState('approveProfileId')(state),
    confirmApproveProfile: uiSelectors.getModalState('confirmApproveProfile')(state),
    confirmUnapproveProfile: uiSelectors.getModalState('confirmUnapproveProfile')(state),

    deleteProfileId: uiSelectors.getUiState('deleteProfileId')(state),
    confirmDeleteProfile: uiSelectors.getModalState('confirmDeleteProfile')(state),

    upgradeProfileId: uiSelectors.getUiState('upgradeProfileId')(state),
    confirmDowngradeProfile: uiSelectors.getModalState('confirmDowngradeProfile')(state),
    confirmUpgradeProfile: uiSelectors.getModalState('confirmUpgradeProfile')(state),
})
}
const mapDispatchToProps = dispatch => {
    return {
        ...uiActions,
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProfilesPage)
