// @flow
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Container, Section, Heading} from 'react-bulma-components'
import {fetch} from 'redux-simple-auth'
import {Redirect} from '@reach/router'

import EditDonationForm from '../../common/components/donations/EditDonationForm'
import * as actions from '../../common/reducers/donations/actions'
import {donations} from '../../common/reducers/resources/selectors'
import lookupSelectors from '../../common/reducers/lookups/selectors'

/**
 * DashboardDonationDetailsPage
 */
export class DashboardDonationDetailsPage extends Component {
    componentDidMount() {
        this.props.dispatch(this.props.getDonationById(this.props.id))
    }

    getProfilesByName = search => {
        return new Promise((resolve, reject) => {
            this.props
                .dispatch(
                    fetch(`${window.env.REACT_APP_GATEWAY_SERVER_URL}/v1/profiles/search?query=${search}&profileType=charity`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }),
                )
                .then(response => {
                    response.json().then(items => {
                        const mappedItems =
                            items &&
                            items.map(item => {
                                return {label: item.fullName, value: item.id}
                            })
                        return resolve(mappedItems)
                    })
                })
                .catch(error => {
                    return reject(error)
                })
        })
    }

    getRecipientAudienceProfiles = metadata => {
        return new Promise((resolve, reject) => {
            const {donationAudience, donationRadius, donationRecipient} = metadata

            const audienceRules = {
                donationAudience,
                donationRadius,
                donationRecipient,
            }

            if (donationAudience === 'recipient' && !donationRecipient) {
                return resolve([])
            }

            //
            this.props
                .dispatch(
                    fetch(`${window.env.REACT_APP_GATEWAY_SERVER_URL}/v1/profiles/audience?profileType=charity`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(audienceRules),
                    }),
                )
                .then(response => {
                    if (!response.ok) {
                        return reject(new Error('Failed to parse response'))
                    }

                    response.json().then(items => {
                        const mappedItems =
                            items &&
                            items.map(item => {
                                return {label: item.fullName, value: item.id}
                            })
                        return resolve(mappedItems)
                    })
                })
                .catch(error => {
                    return reject(error)
                })
        })
    }

    /**
     * @private
     */
    onDonationImageChanged = (selectedFile: File, values: DonationFormValues) => {
        return new Promise((resolve, reject) => {
            console.log('DashboardModifyDonationPage.onDonationImageChanged() selectedFile: ', selectedFile)
            this.props
                .dispatch(this.props.uploadDonationImage(selectedFile))
                .then(response => {
                    console.log('onDonationImageChanged response: ', response)
                    return resolve(response)
                })
                .catch(error => {
                    console.log('onDonationImageChanged error: ', error)
                    return reject(error)
                })
        })
    }

    componentWillUnmount() {
        // const recordId = this.props.id
        //this.props.dispatch(actions.resetRequestState(recordId, `updateDonation:${recordId}`, 'donations'))
    }

    onFormSubmitted = (formValues, formikActions) => {
        this.props
            .dispatch(this.props.updateDonation(this.props.id, formValues))
            .then(response => {
                formikActions.setSubmitting(false)
            })
            .catch(error => {
                formikActions.setSubmitting(false)
            })
    }

    render() {
        const {selectedItem, donationFoodTypesSet} = this.props
        if (this.props.updateStatus.succeeded) {
            return <Redirect noThrow to="/dashboard/donations" />
        }

        return (
            <React.Fragment>
                <Section renderAs="header">
                    <Container>
                        <Heading>Donation ID: {this.props.id}</Heading>
                    </Container>
                </Section>
                <Section>
                    {!this.props.fetchStatus.succeeded ? (
                        <p>Loading...</p>
                    ) : (
                        <EditDonationForm
                            mode="edit"
                            allowAdmin={true}
                            initialValues={selectedItem}
                            foodTypeOptions={donationFoodTypesSet}
                            onFormCompleted={this.onFormSubmitted}
                            getProfiles={this.getProfilesByName}
                            getSuggestions={this.getRecipientAudienceProfiles}
                            onDonationImageChanged={this.onDonationImageChanged}
                        />
                    )}
                </Section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    donationFoodTypesSet: lookupSelectors.donationFoodTypesSet(state),
    selectedItem: donations.getDonationById(state, ownProps.id),
    fetchStatus: donations.getFetchStatusById(state, `fetchDonation:${ownProps.id}`),
    updateStatus: donations.getFetchStatusById(state, `updateDonation:${ownProps.id}`),
})

const mapDispatchToProps = dispatch => {
    return {
        getDonationById: actions.getDonationById,
        updateDonation: actions.updateDonationById,
        uploadDonationImage: actions.uploadDonationImage,
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDonationDetailsPage)
