import {
  createAuthMiddleware,
  createAuthenticator,
  createSessionStorageStore,
} from 'redux-simple-auth'

import has from 'lodash/has'
import {API_SERVER_URL} from '../app-constants'

/**
 * BearerAuthorizer --
 */
const bearerAuthorizer = (data, block) => {
  if (data.token) {
    block('Authorization', `Bearer ${data.token}`)
  }
}

/**
 * CredentialsAuthenticator
 */
export const credentialsAuthenticator = createAuthenticator({
  name: 'credentials',
  authenticate({username, password}) {
    const credentials = {
      username,
      password,
    }

    return new Promise((resolve, reject) => {
      fetch(`${API_SERVER_URL || ''}/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.planzheroes+json; version=2.0',
        },
        body: JSON.stringify(credentials),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (has(data, 'statusCode')) {
            return reject(data.message)
          }
          return resolve(data)
        })
        .catch(err => {
          return reject(err)
        })
    })
  },
  invalidate: data => {
    const headers = {}
    bearerAuthorizer(data, (name, value) => {
      headers[name] = value
    })

    return fetch(`${API_SERVER_URL || ''}/v1/logout`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    })
      .then(response => {
        return response
      })
      .catch(_error => {
        return null
      })
  },
  restore(data) {
    if (data.token) {
      return Promise.resolve(data)
    }

    return Promise.reject()
  },
})

/**
 * Routine, which manages the storage of the session data
 */
const adaptiveStore = createSessionStorageStore({
  sessionStorageKey: 'my-custom-app-key',
  localStorageKey: 'my-custom-app-key',
})

export const authMiddleware = createAuthMiddleware({
  storage: adaptiveStore,
  authorize: bearerAuthorizer,
  authenticator: credentialsAuthenticator,
})

export default authMiddleware
