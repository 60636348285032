// @flow
import {DateTime, Interval} from 'luxon'
import {first as firstItem, omit} from 'lodash'

import geodist from 'geodist'
import get from 'lodash/get'
import round from 'lodash/round'
import IconDonationDefaultImage from '../icons/IconFruitVeg'
import {getSessionData} from '../reducers/session/selectors'

/**
 * Returns the list of particpants of the given donation
 * @param donation the food donation
 * @returns {ParticipantItemList}
 */
export const getDonationParticipants = (
  donation: Donation,
): ParticipantItemList => {
  return (donation && donation.participants) || []
}

/**
 * Returns the donor participant of the given donation
 * @param donation the food donation
 * @returns {ParticipantItem}
 */
export const getDonationDonor = (
  donation: Donation,
  isAccepted: boolean = true,
): ?ParticipantItem => {
  const participants = getDonationParticipants(donation)
  const selectedParticipant: ?ParticipantItem = participants.find(
    participant =>
      participant.participantType === 'donor' &&
      (isAccepted ? participant.isAccepted : true),
  )
  return selectedParticipant
}

/**
 * Returns the profile of the donor participant of the given donation
 * @param donation the food donation
 * @returns {Profile}
 */
export const getDonationDonorProfile = (donation: Donation): ?Profile => {
  const participant: ?ParticipantItem = getDonationDonor(donation)
  return participant ? participant.profile : null
}

/**
 * Returns the recipient participant of the given donation
 * @param donation the food donation
 * @returns {ParticipantItem}
 */
export const getDonationRecipient = (
  donation: Donation,
  isAccepted: boolean = true,
): ?ParticipantItem => {
  const participants = getDonationParticipants(donation)
  const selectedParticipant = participants.find(
    participant =>
      participant.participantType === 'recipient' &&
      (isAccepted ? participant.isAccepted : true),
  )
  return selectedParticipant
}

/**
 * Returns the profile of the recipient participant of the given donation
 * @param donation the food donation
 * @returns {Profile}
 */
export const getDonationRecipientProfile = (donation: Donation): ?Profile => {
  const participant = getDonationRecipient(donation)
  return participant ? participant.profile : null
}

/**
 * Returns the transporter participant of the given donation
 * @param donation the food donation
 * @returns {ParticipantItem}
 */
export const getDonationTransporter = (
  donation: Donation,
  isAccepted: boolean = true,
): ?ParticipantItem => {
  const participants = getDonationParticipants(donation)
  const selectedParticipant = participants.find(
    participant =>
      participant.participantType === 'transporter' &&
      (isAccepted ? participant.isAccepted : true),
  )
  return selectedParticipant
}

/**
 * Returns the profile of the transporter participant of the given donation
 * @param donation the food donation
 * @returns {Profile}
 */
export const getDonationTransporterProfile = (donation: Donation): ?Profile => {
  const participant = getDonationTransporter(donation)
  return participant ? participant.profile : null
}

/**
 * Returns the volunteer participant of the given donation
 * @param donation the food donation
 * @returns {ParticipantItem}
 */
export const getDonationVolunteer = (
  donation: Donation,
  isAccepted: boolean = true,
): ?ParticipantItem => {
  const participants = getDonationParticipants(donation)
  const selectedParticipant = participants.find(
    participant =>
      participant.participantType === 'volunteer' &&
      (isAccepted ? participant.isAccepted : true),
  )
  return selectedParticipant
}

/**
 * Returns the profile of the volunteer participant of the given donation
 * @param donation the food donation
 * @returns {Profile}
 */
export const getDonationVolunteerProfile = (donation: Donation): ?Profile => {
  const participant = getDonationVolunteer(donation)
  return participant ? participant.profile : null
}

/**
 * Returns whether the given donation is claimed
 * @param donation the food donation
 * @returns {Boolean}
 */
export const isDonationClaimed = (donation: Donation): boolean => {
  const recipientParticipant = getDonationRecipient(donation)
  if (!recipientParticipant) return false
  const participantAcceptedDonation = recipientParticipant.isAccepted || false
  const isDonationAccepted = donation && donation.donationStatus === 'accepted'
  return participantAcceptedDonation ? isDonationAccepted : false
}

/**
 * Returns the delivery date of the given donation
 * @param donation the food donation
 * @returns {Boolean}
 */
export const getDonationDeliveryDate = (donation: Donation) => {
  const deliveryDate = DateTime.fromISO(donation.deliveryDate)
  let deliveryTimeBegin = DateTime.fromISO(donation.deliveryTimeBegin)
  if (!deliveryDate.isValid) {
    return null
  }
  if (!deliveryTimeBegin.isValid) {
    deliveryTimeBegin = DateTime.local()
  }

  return DateTime.fromObject({
    year: deliveryDate.year,
    month: deliveryDate.month,
    day: deliveryDate.day,
    hour: deliveryTimeBegin.hour,
    minute: deliveryTimeBegin.minute,
    seconds: deliveryTimeBegin.second,
  })
}

/**
 * Returns whether the donation is expired base on the current date time
 * @param donation the food donation
 * @returns {Boolean}
 */
export const isDonationExpired = (donation: Donation): boolean => {
  const deliveryDate = getDonationDeliveryDate(donation)
  if (!deliveryDate) {
    return true
  }
  const currentDate = DateTime.local()
  return currentDate > deliveryDate
}

/**
 * Returns whether the given donation is available
 * @param donation the food donation
 * @returns {Boolean}
 */
export const isDonationAvailable = (donation: Donation): boolean => {
  const recipientParticipant = getDonationRecipient(donation)
  if (recipientParticipant) return false

  // If the donation is claimed then food donation shouldn't be considered available
  if (isDonationClaimed(donation)) {
    return false
  }

  // If no recipient has accepted the donation, we can check the status of the donation whether it is expired or not
  return !isDonationExpired(donation) && donation.donationStatus === 'created'
}

/**
 * Returns whether the given profile is able to modify the given donation
 * @returns {Boolean}
 */
export const canProfileModifyDonation = (
  donation: Donation,
  profileId: Id,
): boolean => {
  if (!donation) return false
  if (!profileId) return false

  const donorParticipantProfile = getDonationDonorProfile(donation)
  if (!donorParticipantProfile) return false

  return donorParticipantProfile.id === profileId
}

/**
 * @private
 * Returns whether the state of the donation such that it is still possible to be modified
 * @param donation the food donation
 * @returns {Boolean}
 */
export const isDonationEditable = (donation: Donation): boolean => {
  if (!donation) {
    return false
  }

  if (isDonationExpired(donation)) {
    return false
  }

  const recipientParticipant = getDonationRecipient(donation)
  return recipientParticipant ? false : donation.donationStatus === 'created'
}

/**
 * Returns the creation date of the given donation
 * @param donation the food donation
 * @returns {string}
 */
export const getDonationCreationDate = (donation: Donation): DateTime => {
  return DateTime.fromISO(donation.created_at)
}

/**
 * Returns the creation date of the given donation
 * @param donation the food donation
 * @returns {string}
 */
export const getDonationLastModifiedDate = (donation: Donation): DateTime => {
  return DateTime.fromISO(donation.updated_at)
}

/**
 * Returns whether the given donation has a filled in delivery date
 * @param {} donation
 * @returns {Date}
 */
export const hasDonationDeliveryDate = (donation: Donation): Date => {
  return donation.deliveryDate
}

/**
 * Returns the interval of the delivery date of the given food donation
 * @param donation the food donation
 * @returns {Interval}
 */
export const getDonationDeliveryDuration = (donation: Donation): Interval => {
  const deliveryDate = DateTime.fromISO(donation.deliveryDate)
  const deliveryTimeEnd = DateTime.fromISO(donation.deliveryTimeEnd)
  const actualDeliveryStartDate = getDonationDeliveryDate(donation)

  const actualDeliveryEndDate: DateTime = DateTime.fromObject({
    year: deliveryDate.year,
    month: deliveryDate.month,
    day: deliveryDate.day,
    hour: deliveryTimeEnd.hour,
    minute: deliveryTimeEnd.minute,
    seconds: deliveryTimeEnd.second,
  })

  return Interval.fromDateTimes(actualDeliveryStartDate, actualDeliveryEndDate)
}

/**
 * Returns the human readable version of the collection time of the given food donation
 * @param donation the food donation
 * @param format the format for the date
 * @returns {string}
 */
export const getDonationCollectionTime = (
  donation: Donation,
  format: DateTimeFormatOptions = DateTime.DATE_MED,
): string => {
  const deliveryDate = getDonationDeliveryDate(donation)
  if (!deliveryDate || !deliveryDate.isValid) {
    return null
  }
  return deliveryDate.setLocale('en-gb').toLocaleString(format)
}

/**
 * Returns the human readable version of the creation time of the given food donation
 * @param donation the food donation
 * @param format the format for the date
 * @returns {string}
 */
export const getDonationCreationTime = (
  donation: Donation,
  format: DateTimeFormatOptions = DateTime.DATE_MED,
): string => {
  const creationDate = getDonationCreationDate(donation)
  if (!creationDate || !creationDate.isValid) {
    return null
  }
  return creationDate.setLocale('en-gb').toLocaleString(format)
}

/**
 * Returns an existing donation into the form data structure for use in forms
 * @param donation the food donation
 * @returns {Object}
 */
export const getEditableDonationData = (donation: Donation): Donation => {
  if (!donation) return null
  const foodTypes: FoodTypeList = (donation.foodTypes || []).map(item => {
    return typeof item === 'object' ? item.id : item
  })

  if (!donation) return null
  const ignorableFields = [
    'id',
    'deliveredAt',
    'collectedAt',
    'claimedAt',
    'deliveryStatus',
    'donationStatus',
  ]
  const editableDonationData = omit(donation, ignorableFields)

  return {
    metadata: {
      donationRadius: 10000,
    },
    ...editableDonationData,
    foodTypes,
  }
}

/**
 * Returns the editable donation data by copying from an existing donation
 * @param donation the food donation
 * @returns {Donation}
 */
export const getEditableDonationDataFromCopy = (
  donation: Donation,
): ?Donation => {
  if (!donation) return null
  const ignorableFields = [
    'id',
    'deliveredAt',
    'collectedAt',
    'claimedAt',
    'deliveryAccepted',
    'created_at',
    'updated_at',
    'deliveryStatus',
    'donationStatus',
    'isPublicDonation',
    'image',
    // When copying a donation we should not populate the delivery date as this needs to be manually filled in by the user
    'deliveryDate',
    'deliveryTimeBegin',
    'deliveryTimeEnd',
    'donationAgreementAccepted',
    'participants',
  ]
  const editableDonationData = omit(
    getEditableDonationData(donation),
    ignorableFields,
  )
  const safeDonationData = {
    ...editableDonationData,
    donationAgreementAccepted: false, // user needs to set this
    items: editableDonationData.items.map(item => {
      const {id, notes, feedback, ...donationItem} = item
      return donationItem
    }),
  }

  // TODO: find out why clone fails here
  return JSON.parse(JSON.stringify(safeDonationData))
}

/**
 * Returns the description of the given food donation
 * @param donation the food donation
 * @param limitedLength the  maximum length of the donation description
 * @returns {string}
 */
export const getDonationDescription = (
  donation: Donation,
  limitedLength: boolean = false,
): string => {
  let itemDescription = donation.description
  if (!itemDescription) {
    itemDescription = donation.items.reduce((total, next) => {
      return total + next.productName
    }, '')
  }

  if (limitedLength && itemDescription.length >= 250) {
    return `${itemDescription.substr(0, 250)}…`
  }

  return donation.description
}

/**
 * Returns the name of the given food donation
 * @param donation the food donation
 * @returns string
 */
export const getDonationName = (donation: Donation): string => {
  return donation.name
}

/**
 * Returns whether the given food donation has an image
 * @param donation the food donation
 * @returns boolen
 */
export const hasDonationImage = (donation: Donation): boolean => {
  const donationImage = donation.image
  return !donationImage === false
}

/**
 * Returns the image url of the given food donation, fallbacks to the default image when not available
 * @param donation the food donation
 * @returns string
 */
export const getDonationImageUrl = (donation: Donation): string => {
  const donationImage = donation.image
  if (!hasDonationImage(donation)) {
    return `${IconDonationDefaultImage}`
  }

  return `${donationImage.url}`
}

/**
 * Returns the distance between the  donor and recipient participants of the given donation
 * @param donation the food donation
 * @param start the original position
 * @returns {number}
 */
export const getDonationDistance = (
  donation: Donation,
  start: Coordinate = {
    latitude: 51.5073509,
    longitude: -0.1277583,
  },
): number => {
  const donorRecipient = getDonationDonorProfile(donation)
  if (!donorRecipient) {
    return 0
  }

  const {latitude, longitude} = donorRecipient.primaryAddress
  const distance = geodist(
    [latitude, longitude],
    [start.latitude, start.longitude],
    {
      unit: 'mi',
    },
  )
  return distance
}

/**
 * Returns a filtered list of food donations based on filter type
 * @param donation the food donation
 * @param filter the filter
 * @returns DonationList
 */
export const getFilteredDonations = (
  items: DonationList,
  filter: string,
): DonationList => {
  const filterableItems = items.concat()

  const filterTokens = filter.split(':')
  const actualFilterType = firstItem(filterTokens)
  const selectedFilterValue = filterTokens.pop()

  switch (actualFilterType) {
    case 'distance':
      return filterableItems.sort((x, y) => {
        const leftDistance = getDonationDistance(x)
        const rightDistance = getDonationDistance(y)
        if (leftDistance === rightDistance) {
          return DateTime.fromISO(x.deliveryDate) <
            DateTime.fromISO(y.deliveryDate)
            ? -1
            : 1
        }

        return leftDistance - rightDistance
      })
    case 'delivery':
      return filterableItems.sort((x, y) => {
        return DateTime.fromISO(x.deliveryDate) <
          DateTime.fromISO(y.deliveryDate)
          ? -1
          : 1
      })
    case 'newest':
      return filterableItems.sort((x, y) => {
        return DateTime.fromISO(x.deliveryDate) >
          DateTime.fromISO(y.deliveryDate)
          ? -1
          : 1
      })
    case 'availability': {
      const currentDate = DateTime.local()
      const futureItems = filterableItems.filter(item => {
        return DateTime.fromISO(item.deliveryDate) > currentDate ? -1 : 1
      })

      return futureItems.sort((x, y) => {
        return DateTime.fromISO(x.deliveryDate) >
          DateTime.fromISO(y.deliveryDate)
          ? -1
          : 1
      })
    }

    case 'foodtype':
      return filterableItems.filter(item => {
        const availableFoodTypes =
          item.foodTypes.map(foodType => foodType.category) || []
        return availableFoodTypes.includes(selectedFilterValue)
      })
    default:
      return filterableItems.sort((x, y) => {
        return DateTime.fromISO(x.deliveryDate) <
          DateTime.fromISO(y.deliveryDate)
          ? -1
          : 1
      })
  }
}

/**
 * Returns the suggested weight of a given donation item
 * @param donation the food donation
 * @returns number
 */
export const getSuggestedWeightOfItem = (
  donationItem: DonationItem,
): number => {
  const unitDivision = donationItem.unitOfMeasure === 'G' ? 1000 : 1
  return (donationItem.suggestedWeight || 0) / unitDivision
}

/**
 * Returns the total weight of the given food donation
 * @param donation the food donation
 * @param inGrams whether to return the total weight in grams
 * @returns number
 */
export const getTotalWeightOfDonation = (
  donation: Donation,
  inGrams: boolean = false,
): number => {
  const donationItems = get(donation, 'items', [])

  const totalWeight = donationItems.reduce((current, item) => {
    const itemSuggestedWeight = getSuggestedWeightOfItem(item)
    current += itemSuggestedWeight
    return current
  }, 0)

  const result = inGrams ? totalWeight * 1000 : totalWeight
  const roundedResult = round(result, 2)
  return roundedResult
}

/**
 * Returns whether the delivery is completed of the given food donation
 * @param donation the food donation
 */
export const getDonationDeliveryCompleted = (donation: Donation): boolean => {
  return donation.deliveryStatus === 'delivered'
}

/**
 * Returns whether the delivery status is failed of the given food donation
 * @param donation the food donation
 */
export const getDonationDeliveryFailed = (donation: Donation): boolean => {
  return donation.deliveryStatus === 'failed'
}

/**
 * Returns whether the delivery status is created of the given food donation
 * @param donation the food donation
 */
export const getDonationDeliveryCreated = (donation: Donation): boolean => {
  return donation.deliveryStatus === 'created' // TODO: || donation.deliveryStatus === 'pending';
}

/**
 * Returns whether the delivery status is pending of the given food donation
 * @param donation the food donation
 */
export const getDonationDeliveryPending = (donation: Donation): boolean => {
  return donation.deliveryStatus === 'pending' // TODO: || donation.deliveryStatus === 'pending';
}

/**
 * Returns whether the delivery status is collected of the given food donation
 * @param donation the food donation
 */
export const getDonationDeliveryCollected = (donation: Donation): boolean => {
  return donation.deliveryStatus === 'collected'
}

/**
 * Returns the redirect url after creating a food donation
 * @param donation the food donation
 */
export const getSavedDonationRedirectUrl = (
  profileType: ProfileType,
): string => {
  // wdb: Based on email conversation on 25.06.2018 @ 15:46 -- PZ request to always redirect to the dashboard
  switch (profileType) {
    case 'business':
      return '/donations/created'
    case 'charity':
      return '/donations/available'
    case 'transporter':
      return '/donations/claimed'
    case 'volunteer':
      return '/donations/claimed'
    default:
      return '/donations'
  }
}

//// TEMPORARY ////

/**
 * @private
 * Returns the participant type of the profile in the given donation
 * @param donation the food donation
 * @param prifle the profile
 */
export const getProfileParticipantType = (
  donation: Donation,
  profile: Profile,
): ?ParticipantType => {
  const profileId = profile && profile.id
  const participants: ParticipantItemList = getDonationParticipants(donation)
  const participant: ?ParticipantItem = participants.find(
    item => item.profile.id === profileId,
  )
  return participant && participant.participantType
}

/**
 * @private
 * Returns the participant type of the profile in the given donation
 * @param donation the food donation
 * @param prifle the profile
 */
export const isParticipantRoleAccepted = (
  donation: Donation,
  profile: Profile,
): boolean => {
  const profileId = profile && profile.id
  const participants: ParticipantItemList = getDonationParticipants(donation)
  const participant: ?ParticipantItem = participants.find(
    item => item.profile.id === profileId,
  )
  if (!participant) {
    return false
  }

  return participant.isAccepted
}

/**
 * Returns the profile of the authenticated user session
 */
export const getSesssionProfile = (state: any): Profile => {
  const currentSessionData = getSessionData(state)
  return currentSessionData && currentSessionData.profile
}

export default {
  getDonationName,
  getDonationDescription,
  getDonationDistance,
  getDonationImageUrl,
  hasDonationImage,
  getDonationParticipants,
  getDonationRecipient,
  getDonationRecipientProfile,
  getDonationDonor,
  getDonationDonorProfile,
  getDonationVolunteer,
  getDonationVolunteerProfile,
  getDonationTransporter,
  getDonationTransporterProfile,
  getDonationCollectionTime,
  getDonationCreationTime,
  getDonationDeliveryDuration,
  getDonationDeliveryDate,
  getDonationCreationDate,
  getDonationLastModifiedDate,
  getEditableDonationData,
  isDonationExpired,
  isDonationClaimed,
  isDonationAvailable,
  getTotalWeightOfDonation,
  getSuggestedWeightOfItem,
  getProfileParticipantType,
  isParticipantRoleAccepted,
  getDonationDeliveryCreated,
  getDonationDeliveryCompleted,
  getDonationDeliveryFailed,
  getDonationDeliveryPending,
  getDonationDeliveryCollected,

  canProfileModifyDonation,

  // TODO: move this to seperate selectors
  getSesssionProfile,
}
