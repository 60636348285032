import {combineReducers} from 'redux'
import {reducer as session} from 'redux-simple-auth'
import * as actionTypes from './common/reducers/resources/actionTypes'
import donations from './modules/donations'
import profiles from './modules/profiles'
import statistics from './modules/overview'
import reports from './modules/reports'
import {resourceReducer} from 'redux-resource'

import * as reducers from './common/reducers'
import * as helpers from './common/reducers/helpers'
import {includedResources, httpStatusCodes} from 'redux-resource-plugins'
const {NAME: SESSION_NAME, reducer: sessionReducer} = reducers.session
const {NAME: RESOURCES_NAME} = reducers.resources
const {NAME: LOOKUPS_NAME, reducer: lookupReducer} = reducers.lookups

const initialState = {pagination: {}, requests: {}}
const resourceOptions = {allowedActionTypes: [actionTypes.REQUEST_SUCCEEDED, actionTypes.REQUEST_FAILED]}

export default combineReducers({
    session,
    ui: combineReducers({
        [donations.NAME]: donations.uiReducer,
        [profiles.NAME]: profiles.uiReducer,
        [statistics.NAME]: statistics.reducer,
        [reports.NAME]: reports.reducer,
    }),
    profiles: profiles.apiReducer,
    donations: donations.apiReducer,
    [LOOKUPS_NAME]: lookupReducer,
    [SESSION_NAME]: sessionReducer,
    [RESOURCES_NAME]: combineReducers({
        network: resourceReducer('network', {
            plugins: [
                includedResources,
                httpStatusCodes,
                helpers.updateMergeMetadataResourceReducer,
                helpers.paginationResourceReducer,
            ],
            initialState,
            ...resourceOptions,
        }),
        donations: resourceReducer('donations', {
            plugins: [
                includedResources,
                httpStatusCodes,
                helpers.updateMergeMetadataResourceReducer,
                helpers.paginationResourceReducer,
            ],
            initialState,
            ...resourceOptions,
        }),
        users: resourceReducer('users', {
            plugins: [
                includedResources,
                httpStatusCodes,
                helpers.updateMergeMetadataResourceReducer,
                helpers.paginationResourceReducer,
            ],
            initialState,
            ...resourceOptions,
        }),
    }),
})
