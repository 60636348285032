import React, {Component} from 'react'
import {DateTime} from 'luxon'
import {Content, Heading, Columns} from 'react-bulma-components'
import AddressBlock from '../AddressBlock'
import {
  getDonationDonorProfile,
  getDonationRecipientProfile,
  getDonationTransporterProfile,
  getSuggestedWeightOfItem,
  getDonationCollectionTime,
  getDonationCreationTime,
} from '../../selectors/donations'
// eslint-disable-next-line import/no-named-as-default
import ParticipantBlock from './ParticipantBlock'

export const DonationItemDetails = ({item}) => (
  <Columns>
    <Columns.Column>{item.productName}</Columns.Column>
    <Columns.Column>{item.quantity}</Columns.Column>
    <Columns.Column>
      {getSuggestedWeightOfItem(item)}
      <span>kg</span>
    </Columns.Column>
  </Columns>
)

export const DonationItems = ({items}) => (
  <React.Fragment>
    <Columns>
      <Columns.Column>
        <strong>Product Name</strong>
      </Columns.Column>
      <Columns.Column>
        <strong>Quantity</strong>
      </Columns.Column>
      <Columns.Column>
        <strong>Weight</strong>
      </Columns.Column>
    </Columns>
    {items.map((item, index) => {
      return <DonationItemDetails key={index} item={item} />
    })}
  </React.Fragment>
)

/**
 *
 */
export default class DonationDetails extends Component {
  static defaultProps = {
    simplified: false,
    isLoading: false,
  }

  render() {
    const {isLoading, itemData, roleAccepted = false} = this.props
    if (!itemData) {
      return null
    }

    if (isLoading) {
      return <p>Loading...</p>
    }

    const donorParticipant = getDonationDonorProfile(itemData)
    const recipientParticipant = getDonationRecipientProfile(itemData)
    const transporterParticipant = getDonationTransporterProfile(itemData)

    return (
      <React.Fragment>
        <Columns>
          <Columns.Column>
            <Heading renderAs="h6" size={6}>
              Food available
            </Heading>
            <Content>
              <DonationItems items={itemData.items} />
            </Content>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Heading renderAs="h6" size={6}>
              Description
            </Heading>
            <Content>{itemData.description}</Content>

            <Heading renderAs="h6" size={6}>
              Donor
            </Heading>
            <Content>
              {donorParticipant && <p>{donorParticipant.fullName}</p>}
            </Content>

            <Heading renderAs="h6" size={6}>
              Location
            </Heading>
            <Content>
              {donorParticipant && donorParticipant.primaryAddress && (
                <React.Fragment>
                  <AddressBlock
                    className="address_info"
                    type="address"
                    data={donorParticipant.primaryAddress}
                  />
                  {roleAccepted && (
                    <AddressBlock
                      className="address_contact"
                      type="contact"
                      contactFields={['emailAddress']}
                      data={donorParticipant.primaryAddress}
                    />
                  )}
                </React.Fragment>
              )}
            </Content>
          </Columns.Column>
          <Columns.Column>
            <Heading renderAs="h6" size={6}>
              Receiver
            </Heading>
            <Content>
              <ParticipantBlock
                data={recipientParticipant}
                onItemClicked={this.onParticipantClicked}
              />
              {recipientParticipant && recipientParticipant.primaryAddress && (
                <React.Fragment>
                  <AddressBlock
                    className="address_info"
                    type="address"
                    data={recipientParticipant.primaryAddress}
                  />
                  {roleAccepted && (
                    <AddressBlock
                      className="address_contact"
                      type="contact"
                      contactFields={['emailAddress']}
                      data={recipientParticipant.primaryAddress}
                    />
                  )}
                </React.Fragment>
              )}
            </Content>

            <Heading renderAs="h6" size={6}>
              Posted on
            </Heading>
            <Content>
              <p>
                {getDonationCreationTime(itemData, DateTime.DATETIME_MED) ||
                  'N/A'}
              </p>
            </Content>

            <Heading renderAs="h6" size={6}>
              Collection time
            </Heading>
            <Content>
              <p>{getDonationCollectionTime(itemData) || 'N/A'}</p>
            </Content>

            <Heading renderAs="h6" size={6}>
              Transportation
            </Heading>
            <Content>
              {itemData.collectionOnly && <p>The food needs to be collected</p>}
              {!itemData.collectionOnly && <p>The donor can deliver</p>}
            </Content>

            {itemData.collectionOnly && (
              <React.Fragment>
                <Heading renderAs="h6" size={6}>
                  Volunteers / Transporters
                </Heading>
                <Content>
                  <ParticipantBlock
                    data={transporterParticipant}
                    onItemClicked={this.onParticipantClicked}
                  />
                  {transporterParticipant &&
                    transporterParticipant.primaryAddress && (
                      <React.Fragment>
                        <AddressBlock
                          className="address_info"
                          type="address"
                          data={transporterParticipant.primaryAddress}
                        />
                        {roleAccepted && (
                          <AddressBlock
                            className="address_contact"
                            type="contact"
                            contactFields={['emailAddress']}
                            data={transporterParticipant.primaryAddress}
                          />
                        )}
                      </React.Fragment>
                    )}
                </Content>
              </React.Fragment>
            )}
          </Columns.Column>
        </Columns>
        {itemData.information && (
          <Columns>
            <Columns.Column>
              <Heading renderAs="h6" size={6}>
                Additional information
              </Heading>
              <Content>
                <p>{itemData.information}</p>
              </Content>
            </Columns.Column>
          </Columns>
        )}
        <Columns>
          <Columns.Column>
            <Heading renderAs="h6" size={6}>
              Donation audience
            </Heading>
            <Content>
              <ul>
                {itemData.participants.map((participant, index) => {
                  return (
                    <li key={index}>
                      <a href={`/profiles/${participant.profile.id}`}>
                        {participant.profile.fullName}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </Content>
          </Columns.Column>
        </Columns>
      </React.Fragment>
    )
  }
}
