import * as actionTypes from './actionTypes'
import {fetch} from 'redux-simple-auth'
import {REACT_APP_GATEWAY_SERVER_URL} from '../../../app-constants'
import {
  getPaginationDataFromHeaders,
  getSearchQuerystring,
} from '../../../common/reducers/getSearchString'
import {handleErrors} from '../../../common/utils/handleErrors'

//UI action creators
export const setUiState = (key: string, value: any) => ({
    type: actionTypes.SET_UI_STATE,
    payload: {
        key,
        value,
    },
})

export const resetUiState = (keys: Array<string>) => ({
    type: actionTypes.RESET_UI_STATE,
    payload: {
        keys,
    },
})

export const setModalState = (modalName: string, state: boolean) => ({
    type: actionTypes.SET_MODAL_STATE,
    payload: {
        modalName,
        state,
    },
})

//API synchronous action creators
const getDonationsStart = () => {
    return {
        type: actionTypes.GET_DONATIONS_START
    }
}

const getDonationsSuccess = (donations, pagination) => {
    return {
        type: actionTypes.GET_DONATIONS_SUCCESS,
        donations: donations,
        pagination: pagination
    }
}

const getDonationsFail = error => {
    return {
        type: actionTypes.GET_DONATIONS_FAIL,
        errors: error
    }
}

const deleteDonationStart = () => {
    return {
        type: actionTypes.DELETE_DONATIONS_START
    }
}

const deleteDonationSuccess = (response) => {
    return {
        type: actionTypes.DELETE_DONATIONS_SUCCESS,
        response: response
    }
}

const deleteDonationFail = (error) => {
    return {
        type: actionTypes.DELETE_DONATIONS_FAIL,
        errors: error
    }
}

const claimDonationStart = () => {
    return {
        type: actionTypes.CLAIM_DONATIONS_START
    }
}

const claimDonationSuccess = (response) => {
    return {
        type: actionTypes.CLAIM_DONATIONS_SUCCESS,
        response: response
    }
}

const claimDonationFail = (error) => {
    return {
        type: actionTypes.CLAIM_DONATIONS_FAIL,
        errors: error
    }
}

//API asynchronous action creators

/**
 * Generalised method to return the donations items and pagination
 * @param {*} search 
 * @param {*} cursor 
 * @param {*} endpoint 
 */
const getDonationsList = (search, cursor, endpoint) => {
    return dispatch => {
        dispatch(getDonationsStart());
        
        const queryString = getSearchQuerystring({search, cursor})
        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}${endpoint}${queryString}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }),
          )
            .then(handleErrors)
            .then(res => {
              return Promise.all([
                res.json(),
                getPaginationDataFromHeaders(res.headers),
              ])
            })
            .then(([json, paginationData = null]) => {
                dispatch(getDonationsSuccess(json, paginationData))
            })
            .catch(error => {
                dispatch(getDonationsFail(error));
            })
    }
}

export const getDonations = (search, cursor) => {
    return getDonationsList(search, cursor, '/v1/admin/donations');
}

export const getClaimedDonations = (search, cursor) => {
    return getDonationsList(search, cursor, '/v1/admin/donations/claimed');
}

export const getUnclaimedDonations = (search, cursor) => {
    return getDonationsList(search, cursor, '/v1/admin/donations/unclaimed');
}

//TODO deleting donations doesn't actually change any state, investigation req'd
/**
 * Action creator called when deleting a donation
 * @param {*} id 
 */
export const deleteDonationById = id => {
    return dispatch => {
        dispatch(deleteDonationStart())
    
        dispatch(
          fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/admin/donations/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        )
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            dispatch(deleteDonationSuccess(json))
            return json
          })
          .catch(error => {
            dispatch(deleteDonationFail(error))
          })
      }
}

//TODO claiming donations doesn't actually change any state, investigation req'd
/**
 * Action creator called when admin claims a donation on behalf of a profile
 * @param {*} donationId 
 * @param {*} recipientId 
 */
export const claimDonationById = (donationId, recipientId) => {
    return dispatch => {
        dispatch(claimDonationStart())
      
          dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/admin/donations/${donationId}/claim`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: recipientId ? JSON.stringify({claimeeProfileId: recipientId}) : null,
            }),
          )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
              dispatch(claimDonationSuccess(json))
              return json
            })
            .catch(error => {
              dispatch(claimDonationFail(error))
            })
    }
}