import React from 'react'
import {Link} from '@reach/router'

export const NavLink = props => (
    <Link
        getProps={({isPartiallyCurrent}) => ({
            className: isPartiallyCurrent ? 'navbar-item is-active' : 'navbar-item',
        })}
        {...props}
    />
)

export default NavLink
