// @flow
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Container, Section, Heading} from 'react-bulma-components'
import {AlertDialog, AlertDialogLabel, AlertDialogDescription} from '@reach/alert-dialog'
import '@reach/dialog/styles.css'

import DonationDetailsItem from './DonationDetailsItem'
import get from 'lodash/get'

import * as actions from './reducer/actions';
import {EntitiesDataTable} from '../../common/components/EntitiesDataTable'
import * as uiActions from './reducer/actions'
import * as uiSelectors from './reducer/selectors'
import ProfilePicker from '../../common/components/profiles/ProfilePicker'

import './DashboardDonationsPage.scss'

/**
 * DashboardProfilesPage
 */
export class DashboardProfilesPage extends Component {
    state = {searchQuery: ''}

    constructor(props) {
        super(props)
        this.cancelClaimDonationRef = React.createRef()
        this.cancelDeleteDonationRef = React.createRef()
    }

    componentDidMount() {
        this.refreshItems()
    }

    onSearchQueryChanged = (query: string) => {
        this.props.dispatch(uiActions.setUiState('donationSearchQuery', query))
    }

    onSearchCleared = () => {
        this.props.dispatch(uiActions.setUiState('donationSearchQuery', ''))
        setTimeout(() => {
            this.refreshItems()
        }, 500)
    }

    onSearchItems = event => {
        this.refreshItems()
    }

    onClaimDonationResponse = (response: boolean) => {
        const selectedDonationId = this.props.selectedDonationId
        const selectedRecipientId = this.props.selectedRecipientId

        // Dismiss the alert dialog
        this.props.dispatch(uiActions.setUiState('selectedDonationId', null))
        this.props.dispatch(uiActions.setUiState('selectedRecipientId', null))
        this.props.dispatch(uiActions.setModalState('confirmClaimDonation', false))
        if (!response) {
            return
        }

        // Claim the donation on behalf of the adminsitrative user
        this.props.dispatch(actions.claimDonationById(selectedDonationId, selectedRecipientId))
    }

    setProfileForClaimDonation = selectedProfile => {
        this.props.dispatch(uiActions.setUiState('selectedRecipientId', selectedProfile.id))
    }

    onDeleteDonationResponse = (response: boolean) => {
        const deleteDonationId = this.props.deleteDonationId

        // Dismiss the alert dialog
        this.props.dispatch(uiActions.setUiState('deleteDonationId', null))
        this.props.dispatch(uiActions.setModalState('confirmDeleteDonation', false))
        if (!response) {
            return
        }

        // Claim the donation on behalf of the adminsitrative user
        this.props.dispatch(actions.deleteDonationById(deleteDonationId))
    }

    handleItemAction = (action: string, item: any) => {
        switch (action) {
            case 'details':
                this.props.dispatch(uiActions.setUiState('selectedDonationId', item.id))
                this.props.navigate(`/dashboard/donations/${item.id}`)
                break
            case 'edit':
                this.props.dispatch(uiActions.setUiState('selectedDonationId', item.id))
                this.props.navigate(`/dashboard/donations/${item.id}/modify`)
                break
            case 'claim':
                this.props.dispatch(uiActions.setUiState('selectedDonationId', item.id))
                this.props.dispatch(uiActions.setModalState('confirmClaimDonation', true))
                break
            case 'delete':
                this.props.dispatch(uiActions.setUiState('deleteDonationId', item.id))
                this.props.dispatch(uiActions.setModalState('confirmDeleteDonation', true))
                break
            default:
                break
        }
    }

    refreshItems = (page: string) => {
        const {donationSearchQuery, selectedFilterMode} = this.props
        switch (selectedFilterMode) {
            case 'claimed':
                this.props.dispatch(actions.getClaimedDonations(donationSearchQuery, page))
                break
            case 'unclaimed':
                this.props.dispatch(actions.getUnclaimedDonations(donationSearchQuery, page))
                break
            default:
                this.props.dispatch(actions.getDonations(donationSearchQuery, page))
                break
        }
    }

    onPaginationChanged = (page: string) => {
        const {pagination} = this.props
        if (page === 'next') {
            const nextCursor = get(pagination, 'links.next.cursor')
            if (!nextCursor) {
                return
            }

            this.refreshItems(nextCursor)
        } else {
            const previousCursor = get(pagination, 'links.previous.cursor')
            if (!previousCursor) {
                return
            }

            this.refreshItems(previousCursor)
        }
    }

    onFilterChanged = (filter: string) => {
        this.props.dispatch(uiActions.setUiState('selectedFilterMode', filter))
    }

    getProfilesByQuery = (filter: string) => {
        console.log('getProfilesByQuery() filter: ', filter)
        return []
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedFilterMode !== this.props.selectedFilterMode) {
            this.refreshItems()
        }
    }

    render() {
        const {
            selectedFilterMode = 'all',
            confirmClaimDonation = false,
            confirmDeleteDonation = false,
            selectedDonationId = null,
            deleteDonationId = null,
            pagination = {},
            items = [],
        } = this.props

        return (
            <React.Fragment>
                <Section renderAs="header">
                    <Container>
                        <Heading>Donations</Heading>
                    </Container>
                </Section>
                <Section style={{paddingTop: 0}}>
                    <EntitiesDataTable
                        pagination={pagination}
                        selectedFilterMode={selectedFilterMode}
                        onPagination={this.onPaginationChanged}
                        onQueryChange={this.onSearchQueryChanged}
                        onSearchClick={this.onSearchItems}
                        onQueryCleared={this.onSearchCleared}
                        onFilterChange={this.onFilterChanged}
                        filterModes={[
                            {id: 'all', description: 'All'},
                            {id: 'unclaimed', description: 'Unclaimed'},
                            {id: 'claimed', description: 'Claimed'},
                        ]}
                        data={items}
                        render={props => {
                            return (
                                <DonationDetailsItem
                                    key={props.key}
                                    data={props.data}
                                    onAction={this.handleItemAction}
                                />
                            )
                        }}
                    />
                </Section>
                {confirmClaimDonation && (
                    <AlertDialog leastDestructiveRef={this.cancelClaimDonationRef}>
                        <AlertDialogLabel>
                            Are you sure you want to claim the donation with ID {selectedDonationId}
                        </AlertDialogLabel>
                        <AlertDialogDescription>
                            <div>
                                <ProfilePicker
                                    getProfiles={this.getProfilesByQuery}
                                    onChange={selection => this.setProfileForClaimDonation(selection)}
                                />
                                <p>
                                    If you aren't a recipient of the food donation yet, you will be added as one and be
                                    marked as the participant that has claimed the donation.
                                </p>
                            </div>
                        </AlertDialogDescription>

                        <div className="buttons">
                            <button
                                className="control button is-danger"
                                onClick={event => this.onClaimDonationResponse(true)}
                            >
                                Yes, claim this donation
                            </button>{' '}
                            <button
                                className="control button"
                                ref={this.cancelClaimDonationRef}
                                onClick={event => this.onClaimDonationResponse(false)}
                            >
                                Nevermind, don't claim
                            </button>
                        </div>
                    </AlertDialog>
                )}
                {confirmDeleteDonation && (
                    <AlertDialog leastDestructiveRef={this.cancelDeleteDonationRef}>
                        <AlertDialogLabel>
                            Are you sure you want to delete the donation with ID {deleteDonationId}
                        </AlertDialogLabel>
                        <AlertDialogDescription>
                            <p>
                                You are about to delete this donation which was created by [donorParticipantFullName].
                            </p>
                        </AlertDialogDescription>

                        <div className="buttons">
                            <button
                                className="control button is-danger"
                                onClick={event => this.onDeleteDonationResponse(true)}
                            >
                                Yes, delete this donation
                            </button>{' '}
                            <button
                                className="control button"
                                ref={this.cancelDeleteDonationRef}
                                onClick={event => this.onDeleteDonationResponse(false)}
                            >
                                Nevermind, don't delete
                            </button>
                        </div>
                    </AlertDialog>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    isLoading: state.donations.isLoading,
    isLoaded: state.donations.isLoaded,
    pagination: state.donations.pagination,
    items: state.donations.items,

    // UI STATE
    selectedFilterMode: uiSelectors.getUiState('selectedFilterMode')(state),
    selectedDonationId: uiSelectors.getUiState('selectedDonationId')(state),
    donationSearchQuery: uiSelectors.getUiState('donationSearchQuery')(state),

    selectedRecipientId: uiSelectors.getUiState('selectedRecipientId')(state),
    confirmClaimDonation: uiSelectors.getModalState('confirmClaimDonation')(state),

    deleteDonationId: uiSelectors.getUiState('deleteDonationId')(state),
    confirmDeleteDonation: uiSelectors.getModalState('confirmDeleteDonation')(state),
})

const mapDispatchToProps = dispatch => {
    return {
        ...uiActions,
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProfilesPage)
