import * as Yup from 'yup'

export const AddressSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  name: Yup.string().when(
    ['isPrimaryAddress', '$profileType'],
    (isPrimaryAddress, profileType, schema) => {
      // const isVolunteerProfile = (profileType === 'transporter' || profileType === 'volunteeer')
      const isVolunteerProfile = ['transporter', 'volunteer'].includes(
        profileType,
      )
      return isPrimaryAddress && !isVolunteerProfile
        ? schema.required(`The ${profileType} name is required`)
        : schema.notRequired()
    },
  ),
  address1: Yup.string().notRequired(),
  address2: Yup.string().notRequired(),
  address3: Yup.string().notRequired(),
  postcode: Yup.string().notRequired(),
  city: Yup.string().notRequired(),
  country: Yup.string().notRequired(),
  emailAddress: Yup.string().email('Invalid email address'),
  phoneNumber: Yup.string().when(
    ['isPrimaryAddress', '$currentStep'],
    (isPrimaryAddress, currentStep, schema) => {
      return isPrimaryAddress && currentStep === 0
        ? schema.required(
            'You need to give a phone number so we are able to contact you',
          )
        : schema.notRequired()
    },
  ),
  mobileNumber: Yup.string().when(
    ['isPrimaryAddress', '$currentStep'],
    (isPrimaryAddress, currentStep, schema) => {
      return schema
    },
  ),
  isPrimaryAddress: Yup.boolean().required('Required'),
})

export default AddressSchema
