/*eslint babel/no-invalid-this: "off" */
import * as Yup from 'yup'

import {DateTime} from 'luxon'
import {FoodDonationItemSchema} from './FoodDonationItemSchema'

const DonationAudienceSchema = {
  donationAudience: Yup.string()
    .oneOf(['all', 'radius', 'recipient', 'my_community'])
    .required('A donation audience needs to be selected'),
  donationRadius: Yup.number().when(['donationAudience'], {
    is: 'radius',
    then: Yup.number().required('A radius needs to be given'),
    otherwise: Yup.number().notRequired(),
  }),
  donationRecipient: Yup.number().when(['donationAudience'], {
    is: 'recipient',
    then: Yup.number().required(
      'A recipient for the donation needs to be selected',
    ),
    otherwise: Yup.number().notRequired(),
  }),
}

export const FoodDonationSchema = Yup.object().shape({
  name: Yup.string().required('You need to give the donation a title'),
  description: Yup.string(),
  deliveryDate: Yup.mixed()
    .required('You need to select a collection date')
    .typeError('Invalid date')
    .test(
      'is-delivery-date',
      "You can't select a collection date in the past",
      function validateDeliveryDate(value) {
        // was: function checkDeliveryDate(value) {
        // eslint-disable-line
        if (!value) {
          return false
        }

        // eslint-disable-next-line babel/no-invalid-this
        if (this.options.context.isAdministrative === true) {
          return true
        }

        let dateToVerify = null
        if (typeof value === 'string') {
          dateToVerify = DateTime.fromISO(value)
        } else {
          dateToVerify = DateTime.fromJSDate(value.toDate()).startOf('day')
        }

        if (!dateToVerify.isValid) {
          return false
        }

        return dateToVerify >= DateTime.local().startOf('day')
      },
    ),
  deliveryTimeBegin: Yup.mixed()
    .required('You need to select a collection time')
    .typeError('Invalid time')
    .test(
      'is-delivery-time',
      'You need to select a collection time in the future',
      function validateDeliveryDate(value) {
        if (!value) {
          return false
        }

        let dateToVerify = null
        if (typeof value === 'string') {
          dateToVerify = DateTime.fromISO(value)
        } else {
          dateToVerify = DateTime.fromJSDate(value.toDate())
        }

        // If a delivery date is known, we should apply it to the time to correctly check if the
        // full delivery date and time is in the future
        if (this.parent.deliveryDate) {
          //use epoch time because DateTime.set() not working as expected
          const epochDay = 86400000
          const epochHour = 3600000
          const timeISO = Date.parse(value)
          const dateISO = Date.parse(this.parent.deliveryDate) + epochHour  //date exactly 1 hour less than selected day
                                                                            //could be something to do with BST vs GMT?
          const verifiableISO = dateISO + (timeISO % epochDay)
          return verifiableISO >= new Date()
        }



        if (!dateToVerify.isValid) {
          return false
        }

        return dateToVerify >= DateTime.local()
      },
    ),
  deliveryTimeEnd: Yup.string().matches(
    /([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?/,
    {
      message: 'Missing the delivery end time',
    },
  ),
  collectionOnly: Yup.boolean().required(
    'You need to select a collection method',
  ),
  donationAgreementAccepted: Yup.boolean().test(
    'required',
    'The donation agreement needs to be accepted',
    field => {
      return /^(true|1)$/i.test(String(field))
    },
  ),
  donationFoodType: Yup.array().min(
    '1',
    'At least one food type needs to be selected',
  ),

  items: Yup.array()
    .min(1, 'At least one item needs to be part of the donation')
    .of(FoodDonationItemSchema),

  metadata: Yup.object(DonationAudienceSchema),
})

export default FoodDonationSchema
