import React, {Component} from 'react'
import {Router, Redirect} from '@reach/router'
import {getIsAuthenticated, getIsRestored} from 'redux-simple-auth'
import {connect} from 'react-redux'

import AuthenticatedRouteContainer from '../AuthenticatedRouteContainer'
import LoadingIndicator from '../common/components/LoadingIndicator'
import {getUserRole} from '../common/components/withRole'

// preloading
import lookupActions from '../common//reducers/lookups/actions'
import lookupSelectors from '../common//reducers/lookups/selectors'

import NotFoundPage from './pages/NotFoundPage'
import LoginPage from './pages/LoginPage'
import DashboardLayout from './DashboardLayout'

import DashboardDonationsPage from '../modules/donations/DashboardDonationsPage'
import DashboardDonationDetailsPage from '../modules/donations/DashboardDonationDetailsPage'
import DashboardModifyDonationPage from '../modules/donations/DashboardModifyDonationPage'
import DashboardOverviewPage from '../modules/overview/DashboardOverviewPage'
import DashboardProfileDetailsPage from '../modules/profiles/DashboardProfileDetailsPage'
import DashboardProfilesPage from '../modules/profiles/DashboardProfilesPage'
import DashboardReportsPage from '../modules/reports/DashboardReportsPage'
import DashboardProfileNetworkPage from '../modules/profiles/DashboardProfileNetworkPage'
import DashboardAdminPage from '../modules/admin/DashboardAdminPage'

import qs from 'query-string'

/**
 * Helper component to automatically login a user with the given token and redirect the appropriate url
 */
// redirect?uri=${routePath}&token=${authToken}
const RedirectFromToken = props => {
    const query = qs.parse(props.location.search)
    if (query.uri) {
        return <Redirect noThrow to={query.uri} />
    }

    return (
        <div>
            RedirectFromToken {JSON.stringify(query, null, 2)} uri=
            {query.uri} with token: {query.token}
        </div>
    )
}

export class AppContainer extends Component {
    componentDidMount() {
        this.props.getLookupData && this.props.getLookupData()
    }

    render() {
        const {isAppReady, userRole} = this.props
        if (!isAppReady) {
            return <LoadingIndicator size="large" />
        }

        const dashboardRoutes = userRole === 'admin'?
            (<React.Fragment>
                <DashboardOverviewPage path="/overview" />
                <DashboardProfilesPage path="/profiles" />
                <DashboardProfileDetailsPage path="/profiles/:id" />
                <DashboardProfileNetworkPage path="/profiles/:id/network" />
                <DashboardDonationsPage path="/donations" />
                <DashboardDonationDetailsPage path="/donations/:id" />
                <DashboardModifyDonationPage path="/donations/:id/modify" />
                <DashboardReportsPage path="/reports" />
                <DashboardReportsPage path="/reports/:selectedYear" />
                <DashboardAdminPage path="/admin" />
            </React.Fragment>) 
            : null

        return (
            <Router>
                <LoginPage path="/login"/>
                <Redirect noThrow from="/" to="/dashboard" />
                <AuthenticatedRouteContainer layout={DashboardLayout} path="/dashboard">
                    {dashboardRoutes}
                </AuthenticatedRouteContainer>
                <NotFoundPage default />
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSessionRestored: getIsRestored(state),
        isLookupsReady: lookupSelectors.isReady(state),
        isAppReady: lookupSelectors.isReady(state),
        isAuthenticated: getIsAuthenticated(state),
        userRole: getUserRole(state),
        user: state.user,
    }
}

export default connect(mapStateToProps, {...lookupActions})(AppContainer)
