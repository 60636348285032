import React from 'react'
import {Redirect} from '@reach/router'
import {getIsAuthenticated, getIsRestored} from 'redux-simple-auth'
import {connect} from 'react-redux'

// eslint-disable-next-line import/no-named-as-default
import ProfileSessionDataLoader from './ProfileSessionDataLoader'

type Props = {
    isAuthenticated: boolean,
    layout: any,
    children: any,
}

/**
 * AuthenticatedRouteContainer
 * A wrapper component that checks if the user is authenticated, if not it redirects to /login-route
 */
export class AuthenticatedRouteContainer extends React.Component<Props> {
    state = {}

    render() {
        const {children, isAuthenticated = false, layout: Component} = this.props
        if (!isAuthenticated) {
            return <Redirect noThrow to="/login" />
        }

        if (!Component) {
            return <React.Fragment>{children}</React.Fragment>
        }

        return (
            <React.Fragment>
                <ProfileSessionDataLoader />
                <Component>{children}</Component>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSessionRestored: getIsRestored(state),
        isAuthenticated: getIsAuthenticated(state),
        user: state.user,
    }
}

export default connect(mapStateToProps)(AuthenticatedRouteContainer)
