import * as Sentry from '@sentry/browser'
import {Component} from 'react'
import {connect} from 'react-redux'
import {
  fetch,
  invalidateSession,
  getIsAuthenticated,
  getIsRestored,
} from 'redux-simple-auth'
import * as actions from '../../reducers/session/actions'
import {API_SERVER_URL} from '../../app-constants'

/**
 * ProfileSessionDataLoader
 */
export class ProfileSessionDataLoader extends Component {
  componentDidMount() {
    if (this.props.isSessionRestored && this.props.isAuthenticated) {
      this.loadSessionData()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.loadSessionData()
    }
  }

  loadSessionData() {
    this.props
      .dispatch(
        fetch(`${API_SERVER_URL}/v1/profiles/current`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      )
      .then(response => response.json())
      .then(currentUser => {
        // Set the sentry user context
        Sentry.configureScope(scope => {
          scope.setUser({
            id: currentUser.id,
            profileType: currentUser.profileType,
          })
        })

        this.props.dispatch(actions.updateCurrentUser(currentUser))
      })
      .catch(error => {
        console.warn('Error occurred while loading profile data', error) // eslint-disable-line no-console
        Sentry.configureScope(scope => {
          scope.clear()
        })

        this.props.dispatch(invalidateSession())
      })
  }

  render() {
    return null
  }
}

const mapStateToProps = (state: State, _ownProps: OwnProps): StateProps => ({
  isAuthenticated: getIsAuthenticated(state),
  isSessionRestored: getIsRestored(state),
})

export default connect(mapStateToProps)(ProfileSessionDataLoader)
