// @flow
import * as actions from './reducer/actions'

import {Container, Heading, Section} from 'react-bulma-components'
import React, {Component} from 'react'

import ProfileDetails from '../../common/components/profiles/ProfileDetails'
import {connect} from 'react-redux'

/**
 * DashboardProfileDetailsPage
 */
export class DashboardProfileDetailsPage extends Component {
    componentDidMount() {
        this.props.onGetProfileById(this.props.id)
    }

    handleNameChange = (id, data) => {
        this.props.onNameChangeSubmit(id, data, () => this.refreshProfile(id));
    }

    refreshProfile = (id) => {        
        this.props.onGetProfileById(id)
    }

    render() {
        const {selectedItem, loading, loaded} = this.props

        return (
            <React.Fragment>
                <Section renderAs="header">
                    <Container>
                        <Heading>Profile ID: {this.props.id}</Heading>
                    </Container>
                </Section>

                {loaded ? (
                    <ProfileDetails simplified={false} isLoading={loading} itemData={selectedItem} handleNameChange={this.handleNameChange}/>
                ) : loading ? (
                    <p>Loading...</p>
                ) : (
                    <p>Something went wrong while loading the food donation.</p>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectedItem: state.profiles.profile,
        loading: state.profiles.isLoading,
        loaded: state.profiles.isLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetProfileById: id => dispatch(actions.getProfileById(id)),
        onNameChangeSubmit: (id, data, callback) => dispatch(actions.patchProfile(id, data, callback)),
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProfileDetailsPage)
