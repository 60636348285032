import * as actionTypes from './actionTypes'
import {fetch} from 'redux-simple-auth'
import {REACT_APP_GATEWAY_SERVER_URL} from '../../../app-constants'
import {
  getPaginationDataFromHeaders,
  getSearchQuerystring,
} from '../../../common/reducers/getSearchString'
import {handleErrors} from '../../../common/utils/handleErrors'

//UI action creators
export const setUiState = (key: string, value: any) => ({
    type: actionTypes.SET_UI_STATE,
    payload: {
        key,
        value,
    },
})

export const resetUiState = (keys: Array<string>) => ({
    type: actionTypes.RESET_UI_STATE,
    payload: {
        keys,
    },
})

export const setModalState = (modalName: string, state: boolean) => ({
    type: actionTypes.SET_MODAL_STATE,
    payload: {
        modalName,
        state,
    },
})


//API synchronous action creators
const getProfilesListSuccess = (data, pagination) => {
    return {
      type: actionTypes.GET_PROFILES_LIST_SUCCESS,
      items: data,
      pagination: pagination
    }
  }
  
const getProfilesListFail = error => {
    return {
        type: actionTypes.GET_PROFILES_LIST_FAIL,
        error: error
    }
}

const getProfilesListStart = () => {
    return {
        type: actionTypes.GET_PROFILES_LIST_START
    }
}

const profileActionStart = () => {
  return {
    type: actionTypes.PROFILE_ACTION_START,
  }
}

const profileActionFail = () => {
  return {
    type: actionTypes.PROFILE_ACTION_FAIL
  }
}

//API asynchrounous action creators
const profileActionSuccess = (callback) => {
  //TODO use of callback here is messy, could incorporate redux-saga when time
  if (!callback) {
    return {
      type: actionTypes.PROFILE_ACTION_SUCCESS
    }
  } else {
    return dispatch => {
      dispatch(callback)
    }
  }
}

/**
 * Generalised method to get a list of profiles with pagination from the backend
 * @param {*} search 
 * @param {*} cursor 
 * @param {*} options 
 * @param {*} endpoint 
 */
const getProfilesList = (search, cursor, options, endpoint) => {
    return dispatch => {
        const {list, requestKey, ...otherOptions} = options
        const queryString = getSearchQuerystring({search, cursor, ...otherOptions})

        dispatch(getProfilesListStart());

        dispatch(
          fetch(`${REACT_APP_GATEWAY_SERVER_URL}${endpoint}${queryString}`, {
              method: 'GET',
              headers: {
              'Content-Type': 'application/json',
              },
          }),
        )
      .then(handleErrors)
      .then(res => {
        return Promise.all([
          res.json(),
          getPaginationDataFromHeaders(res.headers),
        ])
      })
      .then(([json, paginationData = null]) => {
        dispatch(getProfilesListSuccess(json, paginationData));
      })
      .catch(error => {
        dispatch(getProfilesListFail(error));
      })
    }
}

export const getProfiles = (search, cursor, options) => {
  return getProfilesList(search, cursor, options, '/v1/admin/profiles');
}

export const getUnconfirmedProfiles = (search, cursor, options) => {
  return getProfilesList(search, cursor, options, '/v1/admin/profiles/unconfirmed');
}

export const getDeletedProfiles = (search, cursor, options) => {
  return getProfilesList(search, cursor, options, '/v1/admin/profiles/deleted');
}

export const getApprovedProfiles = (search, cursor, options) => {
  return getProfilesList(search, cursor, options, '/v1/admin/profiles/approved');
}

export const getUnapprovedProfiles = (search, cursor, options) => {
  return getProfilesList(search, cursor, options, '/v1/admin/profiles/unapproved');
}

//TODO profile actions don't seem to alter any state, investigation required, definitely
//potential to add new features using these actions
/**
 * Generalised method for dispatching actions on individual profiles
 * @param {*} endpointStart 
 * @param {*} endpointFinish 
 * @param {*} profileId 
 * @param {*} requestMethod 
 * @param {*} data 
 */
const profileAction = (endpointStart, endpointFinish, profileId, requestMethod, data = {}, callback) => {
  return dispatch => {
    dispatch(profileActionStart())

    dispatch(
      fetch(`${REACT_APP_GATEWAY_SERVER_URL}${endpointStart}${profileId}${endpointFinish}`, {
        method: requestMethod,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(profileActionSuccess(callback))
        return json
      })
      .catch(error => {
        dispatch(profileActionFail())
      })
  }
}

export const deleteProfile = profileId => {
  return profileAction('/v1/admin/profiles/', '/delete', profileId, 'DELETE')
}

export const unapproveProfile = profileId => {
  return profileAction('/v1/profiles/', '/unapprove', profileId, 'PUT')
}

export const approveProfile = profileId => {
  return profileAction('/v1/profiles/', '/approve', profileId, 'PUT')
}

export const patchProfile = (profileId, data, callback) => {
  return profileAction('/v1/profiles/', '', profileId, 'PATCH', data, callback)
}

const getProfileByIdStart = () => {
  return {
    type:actionTypes.GET_PROFILE_BY_ID_START
  }
}

const getProfileByIdSuccess = profile => {
  return {
    type: actionTypes.GET_PROFILE_BY_ID_SUCCESS,
    profile: profile
  }
}

const getProfileByIdFail = error => {
  return {
    type: actionTypes.GET_PROFILE_BY_ID_FAIL,
    error: error
  }
}

export const getProfileById = (id) => {
  return dispatch => {
    dispatch(getProfileByIdStart());
    dispatch(
      fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/admin/profiles/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }))
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(getProfileByIdSuccess(json));
        return json;
      })
      .catch(error => {
        dispatch(getProfileByIdFail(error))
      })
  }
}

