// @flow
import React, {PureComponent} from 'react'
import {Heading} from 'react-bulma-components'

type MetadataType = {|
  donationAudience: 'radius' | 'recipient',
  donationRadius?: number,
  donationRecipient?: number,
|}

type SuggestionItem = {
  id: number,
  label?: string,
  fullName: string,
}
type SuggestionItemList = Array<SuggestionItem>

type Props = {
  // name: string,
  metadata: MetadataType,
  currentAudience: SuggestionItemList,
  getSuggestions: (metadata: MetadataType) => Promise<SuggestionItemList>,
}

type State = {
  suggestions: SuggestionItemList,
  currentAudience: SuggestionItemList,
  isLoading: boolean,
}

/**
 * DonationAudienceSuggestions
 */
class DonationAudienceSuggestions extends PureComponent<Props, State> {
  _isMounted = false

  constructor(props: Props) {
    super(props)
    this.state = {
      suggestions: [],
      currentAudience: this.props.currentAudience,
      isLoading: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    if (this.props.metadata) {
      this.updateSuggestions()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.metadata !== prevProps.metadata) {
      this.updateSuggestions()
    } else if (this.props.currentAudience !== prevProps.currentAudience) {
      // TODO: fix the react/no-did-update-set-state issue
      this.setState({currentAudience: this.props.currentAudience}) // eslint-disable-line react/no-did-update-set-state
    }
  }

  updateSuggestions() {
    const {metadata = {}} = this.props
    if (!this.props.getSuggestions) {
      this.setState({isLoading: false})
      return
    }

    this.setState({isLoading: true})
    this.props
      .getSuggestions(metadata)
      .then(suggestions => {
        if (!this._isMounted) return
        this.setState({suggestions, isLoading: false})
      })
      .catch(_error => {
        if (!this._isMounted) return
        this.setState({suggestions: [], isLoading: false})
      })
  }

  render() {
    const {isLoading, currentAudience = [], suggestions = []} = this.state
    return (
      <div>
        {isLoading && <p className="is-primary is-loading">Loading...</p>}
        <div>
          <Heading size={6} marginless>
            Suggested Audience
          </Heading>
          {suggestions.length > 0 ? (
            suggestions.map((item, index) => <p key={index}>{item.label}</p>)
          ) : (
            <p>No suggestions available.</p>
          )}
        </div>
        {currentAudience.length > 0 && (
          <div style={{marginTop: '0.75rem'}}>
            <Heading size={6} marginless>
              Current Audience
            </Heading>
            {currentAudience.map((item, index) => (
              <p key={index}>{item.fullName}</p>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default DonationAudienceSuggestions
