// @flow
import React, {Component} from 'react'

import DatePicker from 'react-datepicker'
import {DateTime} from 'luxon'
import {connect} from 'formik'
import InputNoAutoComplete from './InputNoAutoComplete'

// import 'react-datepicker/src/datepicker.scss'

type DateOrString = string | Date

export class DateField extends Component {
  onBlur = (_event: KeyboardEvent) => {}

  onValueChange = (value: DateOrString) => {
    let formattedDate = value
    if (value instanceof Date) {
      // Clone the date, why?
      const realDate = new Date(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds(),
      )
      formattedDate = realDate.toISOString()
    }

    this.props.formik.setFieldValue(this.props.name, formattedDate)
    if (this.props.onChange) {
      this.props.onChange(formattedDate)
    }
  }

  getDateFromValue(value: DateOrString) {
    if (typeof value === 'undefined') {
      return value
    }

    let transformedValue = value
    if (typeof transformedValue === 'string') {
      // remove Z from the end of time string to avoid a timezone offset is being applied this
      // solves shifting of the time by one 1 hour
      // transformedValue = transformedValue.replace('Z', '')
      transformedValue = DateTime.fromISO(transformedValue).toJSDate()
    } else if (value instanceof Date) {
      transformedValue = value
    }

    return transformedValue
  }

  render() {
    const {
      onBlur,
      onChange,
      value,
      minDate,
      placeholder,
      ...pickerProps
    } = this.props
    let selectedValue = this.getDateFromValue(this.props.value)
    const minimumDate = this.getDateFromValue(minDate)

    // Convert plain Date object into moment object for the Datepicker component
    selectedValue = selectedValue ? selectedValue : null

    return (
      <DatePicker
        customInput={<InputNoAutoComplete />}
        onChange={this.onValueChange}
        selected={selectedValue}
        minDate={minimumDate}
        placeholderText={placeholder}
        {...pickerProps}
      />
    )
  }
}

export default connect(DateField)
