import React, {Component} from 'react'
import {Container, Columns, Navbar} from 'react-bulma-components'
import NavLink from './NavLink'
import Logo from '../common/assets/logo-white.svg'

/**
 * DashboardLayout
 */
export class DashboardLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuCollapsed: false,
        }
    }

    render() {
        const {children} = this.props
        const {isMenuCollapsed = false} = this.state
        return (
            <React.Fragment>
                <Navbar color="primary" active={isMenuCollapsed}>
                    <Container>
                        <Navbar.Brand>
                            <Navbar.Item renderAs="a" href="#">
                                <img src={Logo} alt="Plan Zheroes" width="180" height="90" />
                            </Navbar.Item>
                            <Navbar.Burger
                                onClick={() => {
                                    this.setState((prevState, props) => {
                                        return {isMenuCollapsed: !prevState.isMenuCollapsed}
                                    })
                                }}
                            />
                        </Navbar.Brand>
                        <Navbar.Menu>
                            <Navbar.Container>
                                <NavLink to="/dashboard/overview">Dashboard</NavLink>
                                <NavLink to="/dashboard/profiles">Profiles</NavLink>
                                <NavLink to="/dashboard/donations">Donations</NavLink>
                                <NavLink to="/dashboard/reports">Reporting</NavLink>
                                <NavLink to="/dashboard/admin">Admin</NavLink>
                            </Navbar.Container>
                        </Navbar.Menu>
                    </Container>
                </Navbar>
                <Container>
                    <Columns>
                        <Columns.Column>{children}</Columns.Column>
                    </Columns>
                </Container>
            </React.Fragment>
        )
    }
}

export default DashboardLayout
