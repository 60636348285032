// @flow
import React, {Component} from 'react'

/**
 * RadioboxField
 */
class RadioboxField extends Component {
  render() {
    const {name, value, checked, children, ...props} = this.props
    return (
      <div className="b-radio">
        <label id={`$option-${name}`} className="radio">
          <input
            id={`$option-${name}`}
            type="radio"
            name={name}
            value={value}
            checked={checked}
            {...props}
          />
          {children}
        </label>
      </div>
    )
  }
}

export default RadioboxField
