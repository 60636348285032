import React, { Component } from 'react';
import {Button} from 'react-bulma-components';
import './admin.css';

class AdminCard extends Component {
    state = {};
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div class="admin-card">
                <div class="admin-card-content">
                    <header>{this.props.title}</header>
                    <span>{this.props.info}</span>
                </div>
                <div className="buttons">
                    <Button color="primary" onClick={this.props.buttonAction}>{this.props.buttonText}</Button>
                </div>
            </div>
        )
    }
}

export default AdminCard