import React, {Component} from 'react'
import {Form} from 'react-bulma-components'
import {connect, getIn} from 'formik'

type OptionId = number | string
type OptionItem = {id: OptionId, description: string}

type RadioboxGroupFieldProps = {
  options?: Array<OptionItem>,
}

/**
 * RadioboxGroupField
 */
export const RadioboxGroupField = class RadioboxGroupField extends Component<RadioboxGroupFieldProps> {
  onChange = (optionId: any, _checked: boolean) => {
    const {
      name,
      formik: {values, setFieldValue},
    } = this.props

    const value = getIn(values, name, [])
    if (value === optionId) {
      return
    }

    setFieldValue(name, optionId)
    if (this.props.onChange) {
      this.props.onChange(optionId)
    }
  }

  render() {
    const {name, formik, options} = this.props

    const value = getIn(formik.values, name, [])

    return (
      <React.Fragment>
        {options &&
          options.map(option => {
            const checked = value === option.id
            const optionValue = String(option.id)
            return (
              <Form.Control key={option.id}>
                <Form.Checkbox
                  checked={checked}
                  value={optionValue}
                  onChange={() => this.onChange(option.id, checked)}
                >
                  {option.description}
                </Form.Checkbox>
              </Form.Control>
            )
          })}
      </React.Fragment>
    )
  }
}

export default connect(RadioboxGroupField)
