// import styled from 'styled-components';
import {
  Button,
  Columns,
  Content,
  Heading,
  Image,
  Section
} from 'react-bulma-components'
import React, {Component} from 'react'

import AddressBlock from '../AddressBlock'
import {WithProfileType} from '../withProfileType'
import ProfileTypeIndicator from './ProfileTypeIndicator'
import Edit from '../../assets/edit-blue.svg'
import Tick from '../../assets/double-tick-blue.svg'

import './ProfileDetails.css'

const startEditStyle = {
  padding: '0 0 14px 14px',
  cursor: 'pointer'
}

const completeEditStyle = {
  padding: '0 0 0 14px',
  cursor: 'pointer'
}

const editNameStyle = {
  fontSize: '2rem', 
  marginBottom:'1.5rem',
  fontWeight: '600'
}

export class ProfileDetailsProgress extends Component {
  static defaultProps = {
    progress: {},
  }

  render() {
    const {progress, total} = this.props.progress
    return (
      <div className="profile_progress__container">
        <div
          className="profile_progress__bar"
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax={total}
          style={{height: `${progress}%`}}
        >
          <span>{progress}%</span>
        </div>
      </div>
    )
  }
}

export class ProfileDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isNameEditable: false,
      editNameValue: ''
    }
  }
  static defaultProps = {
    canEditProfile: false,
    simplified: false,
    isLoading: false,
  }

  onEditProfile = event => {
    event.preventDefault()
    if (this.props.onEditProfile) {
      this.props.onEditProfile()
    }
  }

  editNameOn = (profileData) => {
    this.setState({isNameEditable:true, editNameValue: profileData.fullName})
  }

  editNameSubmit = id => {
    this.props.handleNameChange(id, {fullName: this.state.editNameValue});
    this.setState({isNameEditable:false})
  }

  handleNameChange = event => {
    this.setState({editNameValue: event.target.value})
  }

  render() {
    const {
      isLoading,
      itemData: profileData = {},
      simplified: isSimplified,
      canEditProfile = false,
      // children,
    } = this.props
    if (!profileData || isLoading) {
      return <p>Loading...</p>
    }

    const profileAddresses = (profileData && profileData.addresses) || []
    // eslint-disable-next-line
    const selectedFoodTypes = (profileData.foodTypes || []).map(item => {
      return item.id
    })
    // eslint-disable-next-line
    const selectedCollectionTimes = (profileData.collectionTimes || []).map(
      item => {
        return item.id
      },
    )
    // eslint-disable-next-line
    const selectedVolunteerRoles = (profileData.volunteerRoles || []).map(
      item => {
        return item.id
      },
    )
    // eslint-disable-next-line
    const selectedDonationSizes = (profileData.donationSizes || []).map(
      item => {
        return item.id
      },
    )

    const profileName = this.state.isNameEditable ? 
    (
      <div>
        <input style={editNameStyle} defaultValue={profileData.fullName} onChange={e => this.handleNameChange(e)}/>
        <img src={Tick} style={completeEditStyle} onClick={() => this.editNameSubmit(profileData.id)}/>
      </div>
    )
    : 
    (
      <div style={{display: 'flex', flexDirection:'row'}}>
        <h1 className="title">{profileData.fullName}</h1>
        <img src={Edit} style={startEditStyle} onClick={() => this.editNameOn(profileData)}/>
      </div>
    );

    let donorAgreementName = 'agreement'
    if (profileData && profileData.profileType === 'business') {
      donorAgreementName = 'Food donor agreement'
    } else if (profileData && profileData.profileType === 'charity') {
      donorAgreementName = 'Charity Agreement'
    } else if (profileData && profileData.profileType === 'transporter') {
      donorAgreementName = 'Transporter agreement'
    } else if (profileData && profileData.profileType === 'transporter') {
      donorAgreementName = 'Volunteer agreement'
    }

    return (
      <Section style={{paddingTop: '0'}}>
        <Columns>
          <Columns.Column>
            <Columns>
              <Columns.Column size="one-third">
                {profileData.image && profileData.image.url && (
                  <Image src={profileData.image.url} size={128} />
                )}
                {!profileData.image && (
                  <Image
                    src="https://vignette.wikia.nocookie.net/project-pokemon/images/4/47/Placeholder.png/revision/latest?cb=20170330235552&format=original"
                    size={128}
                  />
                )}
                <a
                  href={`/downloads/${profileData.profileType}/donor_agreement.pdf`}
                  download
                >
                  {donorAgreementName} (PDF)
                </a>
              </Columns.Column>
              <Columns.Column>
                {profileName}
                <ProfileTypeIndicator role={profileData.profileType} />
                <Content>
                  {profileData.charityNumber && (
                    <p className="has-text-grey">
                      Charity Number: {profileData.charityNumber}
                    </p>
                  )}
                  {profileData.foodRegistrationNumber && (
                    <p className="has-text-grey">
                      Food Registration Number:{' '}
                      {profileData.foodRegistrationNumber}
                    </p>
                  )}
                  <Content>
                    <p>{profileData.description}</p>
                  </Content>
                </Content>
                {canEditProfile && (
                  <Button
                    color="primary"
                    fullwidth={true}
                    onClick={this.onEditProfile}
                  >
                    Edit Profile
                  </Button>
                )}
              </Columns.Column>
              {canEditProfile && (
                <Columns.Column>
                  <div className="profile__progress--container">
                    <Heading renderAs="h5" size={4}>
                      Profile Status
                    </Heading>
                    <ProfileDetailsProgress progress={profileData.status} />
                    <p>
                      Fill in more details to improve your profile and know more
                      Zheroes!
                    </p>
                    <Button onClick={this.onEditProfile}>
                      Complete Profile
                    </Button>
                  </div>
                </Columns.Column>
              )}
            </Columns>
            <Columns>
              <Columns.Column>
                <Heading renderAs="h5" size={5}>
                  Location
                </Heading>
                {!isSimplified && (
                  <Heading
                    subtitle
                    renderAs="p"
                    size={6}
                    className="has-text-grey"
                  >
                    This information will help us to recommend you Zheroes near
                    to you.
                  </Heading>
                )}
                {profileAddresses.map(item => {
                  return (
                    <div className="profile__address-item" key={item.id}>
                      {item.isPrimaryAddress && (
                        <Heading renderAs="h6" size={6}>
                          Primary Location
                        </Heading>
                      )}
                      {!item.isPrimaryAddress && (
                        <Heading renderAs="h6" size={6}>
                          Alternative Location
                        </Heading>
                      )}

                      <AddressBlock data={item} type="address" />
                    </div>
                  )
                })}
              </Columns.Column>
              <Columns.Column>
                <Heading renderAs="h5" size={5}>
                  Contact Details
                </Heading>
                {!isSimplified && (
                  <Heading
                    subtitle
                    renderAs="p"
                    size={6}
                    className="has-text-grey"
                  >
                    Only available to those Zheroes who are connected to you.
                  </Heading>
                )}
                {profileAddresses.map(item => {
                  return (
                    <div className="profile__address-item" key={item.id}>
                      {item.isPrimaryAddress && (
                        <Heading renderAs="h6" size={6}>
                          Primary Contact
                        </Heading>
                      )}
                      {!item.isPrimaryAddress && (
                        <Heading renderAs="h6" size={6}>
                          Alternative Contact
                        </Heading>
                      )}

                      <AddressBlock
                        data={item}
                        contactFields={['emailAddress']}
                        type="contact"
                      />
                    </div>
                  )
                })}
              </Columns.Column>
              <Columns.Column>
                <Heading renderAs="h5" size={5}>
                  Food transportation
                </Heading>
                {!isSimplified && (
                  <Heading
                    subtitle
                    renderAs="p"
                    size={6}
                    className="has-text-grey"
                  >
                    Can provide food transportation?
                  </Heading>
                )}
                <Content>
                  <ul>
                    <li>{profileData.collectionCapacity ? 'Yes' : 'No'}</li>
                  </ul>
                </Content>

                {profileData.transportOptions &&
                  profileData.transportOptions.length > 0 && (
                    <React.Fragment>
                      <Heading renderAs="h5" size={5}>
                        Transportation options
                      </Heading>
                      {!isSimplified && (
                        <Heading
                          subtitle
                          renderAs="p"
                          size={6}
                          className="has-text-grey"
                        >
                          Can provide food transportation?
                        </Heading>
                      )}
                      <Content>
                        <ul>
                          {profileData.transportOptions &&
                            profileData.transportOptions.map(item => {
                              return <li key={item.id}>{item.title}</li>
                            })}
                        </ul>
                      </Content>
                    </React.Fragment>
                  )}

                <WithProfileType
                  $profileType={profileData.profileType}
                  type="business"
                >
                  {profileData.donationSizes &&
                    profileData.donationSizes.length > 0 && (
                      <React.Fragment>
                        <Heading renderAs="h5" size={5}>
                          Donation Size
                        </Heading>
                        {!isSimplified && (
                          <Heading
                            subtitle
                            renderAs="p"
                            size={6}
                            className="has-text-grey"
                          >
                            What quantities would you be able to manage?
                          </Heading>
                        )}
                        <Content>
                          <ul>
                            {profileData.donationSizes &&
                              profileData.donationSizes.map(item => {
                                return <li key={item.id}>{item.description}</li>
                              })}
                          </ul>
                        </Content>
                      </React.Fragment>
                    )}
                </WithProfileType>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                {profileData.foodTypes && profileData.foodTypes.length > 0 && (
                  <React.Fragment>
                    <Heading renderAs="h5" size={5}>
                      Food Type
                    </Heading>
                    {!isSimplified && (
                      <Heading
                        subtitle
                        renderAs="p"
                        size={6}
                        className="has-text-grey"
                      >
                        What type of food are you willing to accept or do you
                        specially need?
                      </Heading>
                    )}
                    <Content>
                      <ul>
                        {profileData.foodTypes &&
                          profileData.foodTypes.map(item => {
                            return <li key={item.id}>{item.description}</li>
                          })}
                      </ul>
                    </Content>
                  </React.Fragment>
                )}
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </Section>
    )
  }
}

export default ProfileDetails
