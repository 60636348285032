// @flow
/* eslint-disable react/no-unsafe,babel/camelcase */
import React, {Children, Component} from 'react'
import warning from 'warning'

const isEmptyChildren = children => Children.count(children) === 0

type RenderProps = {
  role: string,
}

type Props = {
  children?: React.Element,
  render?: (props: RenderProps) => void,
  roleType?: string,
  role?: string,
}

type State = {
  canShow: boolean,
}

export class WithParticipantRole extends Component<Props, State> {
  static defaultProps = {
    roleType: 'all',
    role: 'donor',
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      canShow: false,
    }
  }

  componentDidMount() {
    this.checkCondition(this.props)
  }

  componentDidUpdate(nextProps) {
    if (this.props.role !== nextProps.role) {
      this.checkCondition(this.props)
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    warning(
      !(
        this.props.render &&
        this.props.children &&
        !isEmptyChildren(this.props.children)
      ),
      'You should not use both <WithParticipantRole render> and <WithParticipantRole children>; <WithParticipantRole children> will be ignored',
    )
  }

  checkCondition(props: Props) {
    const {role, roleType} = props
    if (roleType === 'all') {
      this.setState({canShow: true})
      return
    }

    this.setState({canShow: role === roleType})
  }

  render() {
    const {render, children, role, ...props} = this.props

    const {canShow = false} = this.state
    if (!canShow) {
      return null
    }

    if (render) {
      return render({role, ...props})
    }

    if (children) {
      return children
    }

    return null
  }
}
WithParticipantRole.displayName = 'UncleScroogeIsAwesome'

export default WithParticipantRole
