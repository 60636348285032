import React, {Component} from 'react'
import {Button, Level} from 'react-bulma-components'
import has from 'lodash/has'

/**
 * Pagination
 */
export class Pagination extends Component {
  onClick = type => {
    if (this.props.onChange) {
      this.props.onChange(type)
    }
  }

  render() {
    const {pagination = {}} = this.props
    const hasPreviousCursor = has(pagination, 'links.previous')
    const hasNextCursor = has(pagination, 'links.next')

    return (
      <div>
        <Level renderAs="nav">
          <Level.Side align="left">
            <Button
              onClick={_event => this.onClick('previous')}
              disabled={!hasPreviousCursor}
            >
              Previous
            </Button>
          </Level.Side>
          <Level.Side align="right">
            <Button
              onClick={_event => this.onClick('next')}
              disabled={!hasNextCursor}
            >
              Next
            </Button>
          </Level.Side>
        </Level>
      </div>
    )
  }
}

export default Pagination
