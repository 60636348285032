import * as actionTypes from './actionTypes'

const initialState = {
  data: null,
  isReady: false,
}

export default function currentSessionReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_USER:
      return {
        data: action.payload,
        isReady: true,
      }
    default:
      return state
  }
}
