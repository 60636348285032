// @flow
//import '../../../BulmaTheme.scss'

import {Button, Columns, Form} from 'react-bulma-components'
import {Formik, yupToFormErrors} from 'formik'
import React, {Component} from 'react'
import {DateTime} from 'luxon'
import * as selectors from '../../selectors/donations'

import CheckboxField from '../form/CheckboxField'
import CheckboxGroupField from '../form/CheckboxGroupField'
import DateField from '../form/DateField' // eslint-disable-line import/no-named-as-default
import {FoodDonationSchema} from '../../schemas'
import ImageField from '../form/ImageField'
import {TextInput} from '../form'
import DonationAudienceField from './fields/DonationAudienceField' // eslint-disable-line import/no-named-as-default
import DonationAudienceSuggestions from './fields/DonationAudienceSuggestions'
import DonationItemsField from './fields/DonationItemsField'

export type MetadataType = {|
  donationAudience: 'radius' | 'recipient',
  donationRadius?: number,
  donationRecipient?: number,
|}
export type SuggestionItem = {
  id: number,
  fullName: string,
}
export type SuggestionItemList = Array<SuggestionItem>
export type ParticipantType =
  | 'business'
  | 'charity'
  | 'transporter'
  | 'volunteer'
export type ParticipantItem = {
  id?: number,
  participantType: ParticipantType,
  profile?: SuggestionItem,
}
export type DonationFormValues = {
  metadata: MetadataType,
  participants: Array<ParticipantItem>,
}
export type EditDonationFormMode = 'create' | 'edit'
export type ImageItemInfo = {
  id: number,
  name: string,
  url: string,
}

//
type EditDonationFormProps = {
  mode: EditDonationFormMode,
  allowAdmin?: boolean,
  initialValues?: DonationFormValues,
  foodTypeOptions?: any,
  validateOnBlur: boolean,
  getProfiles?: () => Promise<SuggestionItemList>,
  getSuggestions?: (metadata: MetadataType) => Promise<SuggestionItemList>,
  onFormCompleted?: (
    values: DonationFormValues,
    actions: FormikActions,
  ) => void,
  onFormCleared?: () => void,
  onDonationImageChanged?: (
    selectedFile: File,
    values: DonationFormValues,
  ) => Promise<ImageItemInfo>,
}

const getProfilesFromParticipants = (participants = []) => {
  return participants
    .filter(participant => participant.participantType === 'recipient')
    .map(participant => participant.profile)
}

/**
 * EditDonationForm
 */
export class EditDonationForm extends Component<EditDonationFormProps> {
  static defaultProps: EditDonationFormProps = {
    // mode: 'create',
    allowAdmin: false,
    foodTypeOptions: [],
    // validateOnBlur: false,
  }

  onFormSubmit = (values: DonationFormValues, formikActions: FormikActions) => {
    if (this.props.onFormCompleted) {
      const formValues = {
        ...values,
        deliveryDate: DateTime.fromISO(values.deliveryDate).toISODate(),
        deliveryTimeBegin: DateTime.fromISO(values.deliveryTimeBegin).toFormat(
          'HH:mm:ss',
        ),
        deliveryTimeEnd: DateTime.fromISO(values.deliveryDate)
          .endOf('day')
          .toFormat('HH:mm:ss'),
      }
      this.props.onFormCompleted(formValues, formikActions)
    }
  }

  onFormReset = () => {
    if (this.props.onFormCleared) {
      this.props.onFormCleared()
    }
  }

  onFormValidate = (values: DonationFormValues) => {
    try {
      FoodDonationSchema.validateSync(values, {
        abortEarly: false,
        context: {
          isAdministrative: this.props.allowAdmin,
          initialValues: this.props.initialValues,
        },
      })
      return {}
    } catch (error) {
      if (error.name === 'TypeError') {
        return {}
      }

      try {
        return yupToFormErrors(error)
      } catch (_formError) {
        return {}
      }
    }
  }

  onImageChanged = (
    selectedFile: File,
    values: DonationFormValues,
    formikActions: FormikActions,
  ) => {
    if (this.props.onDonationImageChanged) {
      this.props
        .onDonationImageChanged(selectedFile, values)
        .then(response => {
          formikActions.setFieldTouched('image', true)
          formikActions.setFieldValue('image', response)
        })
        .catch(error => {
          formikActions.setFieldTouched('image', true)
          formikActions.setFieldError('image', error.message)
        })
    }
  }

  render() {
    const {foodTypeOptions} = this.props
    const selectedItem: DonationFormValues = selectors.getEditableDonationData(
      this.props.initialValues,
    )

    return (
      <Formik
        initialValues={selectedItem}
        validateOnBlur={this.props.validateOnBlur}
        validateOnChange={false}
        enableReinitialize={true}
        validate={this.onFormValidate}
        onSubmit={this.onFormSubmit}
        onReset={this.onFormReset}
      >
        {props => (
          <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
            <TextInput
              horizontal={true}
              name="name"
              component={Form.Input}
              label="Title of donation"
              required
              placeholder="Enter title"
              help="i.e. Some sauces left over from an event"
            />
            <TextInput
              horizontal={true}
              name="foodTypes"
              required
              render={_inputProps => (
                <CheckboxGroupField
                  {..._inputProps}
                  otherFieldName="metadata.foodTypeOther"
                  options={foodTypeOptions}
                />
              )}
              label="Type of Food"
            />

            <TextInput
              horizontal={true}
              name="description"
              component={Form.Textarea}
              label="Additional information"
              placeholder="Enter information"
              help="Use this box to add any useful information about your donation. For example; ‘Best before’ or ‘Use by’ dates, the reason for the food being surplus or how it’s packaged e.g: In crates, loose or if charities need to bring their own bags/containers etc."
            />

            <TextInput
              horizontal={true}
              name="image"
              component={ImageField}
              label="Image"
              help="Maximum file size is 2MB"
              extensions={['jpg', 'gif', 'png']}
              maxFileSize={2097152}
              onImageChanged={selectedFile =>
                this.onImageChanged(
                  selectedFile.data && selectedFile.data.file,
                  props.values,
                  {
                    setFieldError: props.setFieldError,
                    setFieldTouched: props.setFieldTouched,
                    setFieldValue: props.setFieldValue,
                    validateForm: props.validateForm,
                    validateField: props.validateField,
                  },
                )
              }
            />

            <TextInput
              name="items"
              render={_inputProps => (
                <DonationItemsField
                  name="items"
                  {...props}
                  options={foodTypeOptions}
                />
              )}
              label=""
            />

            <Columns>
              <Columns.Column>
                <TextInput
                  component={Form.Select}
                  name="collectionOnly"
                  label="Collection / delivery?"
                  required
                >
                  <option value="">Please select one</option>
                  <option value={true}>Donation must be collected</option>
                  <option value={false}>
                    We may be able to help with delivery
                  </option>
                </TextInput>
              </Columns.Column>
              <Columns.Column>
                <TextInput
                  component={DateField}
                  required
                  name="deliveryDate"
                  label="Collect by (date)"
                  showTimeSelect={false}
                  minDate={DateTime.utc().toISO()}
                  dateFormat="dd-MM-yyyy"
                  caption="Date"
                />
              </Columns.Column>
              <Columns.Column>
                <TextInput
                  component={DateField}
                  required
                  name="deliveryTimeBegin"
                  label="Collect by (time)"
                  showTimeSelectOnly={true}
                  showTimeSelect={true}
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                />
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <DonationAudienceField getProfiles={this.props.getProfiles} />
                <DonationAudienceSuggestions
                  name="participants"
                  metadata={props.values.metadata}
                  currentAudience={getProfilesFromParticipants(
                    props.values.participants,
                  )}
                  getSuggestions={this.props.getSuggestions}
                />
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Label>Food donation agreement</Form.Label>
                <p>
                  Please read the{' '}
                  <a
                    href="/downloads/donation/donation_collection_agreement.pdf"
                    target="_blank"
                  >
                    Food Donation and Collection Agreement
                  </a>{' '}
                  and ensure you understand the terms of this agreement before
                  posting your donation. Upon acceptance of your donation, the
                  charity will be bound to the same agreement.
                </p>
                <TextInput
                  component={CheckboxField}
                  type="checkbox"
                  name="donationAgreementAccepted"
                >
                  I agree to the terms of the ‘{' '}
                  <a
                    href="/downloads/donation/donation_collection_agreement.pdf"
                    target="_blank"
                  >
                    Food Donation and Collection Agreement
                  </a>{' '}
                  ’ with the charity who accepts this donation.
                </TextInput>
              </Columns.Column>
            </Columns>
            <div className="buttons">
              <Button
                color="primary"
                disabled={props.isSubmitting}
                loading={props.isSubmitting}
                type="submit"
              >
                {this.props.mode === 'edit'
                  ? 'Update donation'
                  : 'Post donation'}
              </Button>
            </div>
            {/* {process.env.NODE_ENV !== 'production' && (
                            <div>
                                <pre>{JSON.stringify(props.values, null, 2)}</pre>
                                <pre>{JSON.stringify(props.errors, null, 2)}</pre>
                                <pre>{JSON.stringify(props.touched, null, 2)}</pre>
                            </div>
                        )} */}
          </form>
        )}
      </Formik>
    )
  }
}

export default EditDonationForm
