import React from 'react'
import {Button, Content, Image, Media, Box, Level} from 'react-bulma-components'
import {DateTime} from 'luxon'
import * as selectors from '../../common/selectors/donations'

/**
 * ProfileDetailsItem
 */
const ProfileDetailsItem = ({data, ...props}) => {
    const creationDate = DateTime.fromISO(data.created_at, {zone: 'utc'})
    const formattedCreationDate = creationDate ? creationDate.toLocaleString(DateTime.DATETIME_FULL) : 'N/A'

    const claimedDate = DateTime.fromISO(data.claimedAt, {zone: 'utc'})
    const formattedClaimedDate = claimedDate ? claimedDate.toLocaleString(DateTime.DATETIME_FULL) : 'N/A'

    const donorParticipant = selectors.getDonationDonor(data, true)
    const recipientParticipant = selectors.getDonationRecipient(data, true)
    const isDonationClaimed = selectors.isDonationClaimed(data)

    return (
        <Box className="is-marginless">
            <Media>
                <Media.Item renderAs="figure" position="left">
                    {data.image && <Image size={64} alt="64x64" src={data.image && data.image.url} />}
                    {!data.image && <Image size={64} alt="64x64" src="https://via.placeholder.com/64x64" />}
                </Media.Item>
                <Media.Item>
                    <Content style={{wordbreak: 'break-word'}}>
                        <p>
                            <strong>
                                Donor:
                                {donorParticipant && <span>{donorParticipant.profile.fullName}</span>}
                                {!donorParticipant && <span>N/A</span>}
                            </strong>
                            <br />
                            {data.description}
                        </p>
                    </Content>
                    <Level breakpoint="mobile">
                        <Level.Side align="left">
                            <Content>
                                <a href={`/dashboard/donations/${data.id}`} title={`View details of ${data.title}`}>
                                    View details
                                </a>
                            </Content>
                        </Level.Side>
                    </Level>
                </Media.Item>
                <Media.Item>
                    <Content>
                        <p>
                            Posted on: {formattedCreationDate}
                            {isDonationClaimed && (
                                <React.Fragment>
                                    <br />
                                    Claimed on: {formattedClaimedDate}
                                </React.Fragment>
                            )}
                        </p>
                    </Content>
                </Media.Item>
                <Media.Item>
                    <Level breakpoint="mobile" className="is-pulled-right">
                        <Level.Side align="left">
                            {isDonationClaimed && <p>Claimed by: {recipientParticipant.profile.fullName}</p>}
                            {!isDonationClaimed && (
                                <div className="buttons">
                                    <Button text={true} onClick={event => props.onAction('claim', data)}>
                                        Claim as Admin
                                    </Button>
                                    <Button color="info" text={true} onClick={event => props.onAction('edit', data)}>
                                        Edit
                                    </Button>
                                    <Button
                                        color="danger"
                                        text={true}
                                        onClick={event => props.onAction('delete', data)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            )}
                        </Level.Side>
                    </Level>
                </Media.Item>
            </Media>
        </Box>
    )
}

export default ProfileDetailsItem
