import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import AppContainer from './components/AppContainer'
import ErrorBoundary from './common/components/ErrorBoundary'
import store from './store'

import * as serviceWorker from './serviceWorker'

import './index.scss'
import './common/index.scss'

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <AppContainer />
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
