import * as Yup from 'yup'

export const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, {
      message:
        'Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number and least 8 characters',
      excludeEmptyString: true,
    })
    .required('Required'),
})

export default PasswordSchema
