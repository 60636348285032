// @flow
import React, {Component} from 'react'
import {getIn, isFunction, connect} from 'formik'
import warning from 'warning'

/**
 * FieldErrorMessage
 */
class FieldErrorMessage extends Component {
  shouldComponentUpdate(props) {
    if (
      getIn(this.props.formik.errors, this.props.name) !==
        getIn(props.formik.errors, this.props.name) ||
      getIn(this.props.formik.touched, this.props.name) !==
        getIn(props.formik.touched, this.props.name) ||
      Object.keys(this.props).length !== Object.keys(props).length
    ) {
      return true
    } else {
      return false
    }
  }

  render() {
    const {component, formik, render, children, name, ...rest} = this.props

    const touch = getIn(formik.touched, name)
    const error = getIn(formik.errors, name)
    let renderableErrorMessage = error

    if (!name) {
      warning(
        `The FieldErrorMessage received a non-string for the 'name'-property returning the following error object: ${error}`,
        'NON_STRING_FOR_NAME_PROPERTY',
      )
      return null
    }

    // check if the error is an object, if so we combine all errors
    if (error && typeof error === 'object') {
      renderableErrorMessage = Object.keys(error)
        .map(errorName => {
          const message = error[errorName]
          return typeof message === 'string' ? message : null
        })
        .join(', ')

      // Avoid rendering an empty error message
      if (renderableErrorMessage === '') {
        renderableErrorMessage = null
      }
    }

    if (typeof renderableErrorMessage !== 'string') {
      if (process.env.NODE_ENV === 'development') {
        warning(
          `The FieldErrorMessage for ${name} had a non-string error message and won't be rendered on the screen, the error was: ${error}`,
          'NON_STRING_ERROR_MESSAGE_GIVEN',
        )
      }
      renderableErrorMessage = null
    }

    const displayError = (!!touch && !!error) || formik.submitCount > 0
    if (displayError && typeof error === 'undefined') {
      return null
    }

    return displayError
      ? render
        ? isFunction(render)
          ? render(renderableErrorMessage)
          : null
        : children
        ? isFunction(children)
          ? children(renderableErrorMessage)
          : null
        : component
        ? React.createElement(component, rest, renderableErrorMessage)
        : renderableErrorMessage
      : null
  }
}

export default connect(FieldErrorMessage)
