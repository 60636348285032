import parseLinkHeader from 'parse-link-header'

/**
 * Parses and returns the search query based on the passed search options
 * @params {*} query the search query
 * @returns {*}
 */
export const getSearchQuerystring = function getSearchQuerystring(
  query: object,
) {
  const urlParameters = new URLSearchParams()
  if (query.search) {
    urlParameters.set('search', query.search)
  }
  if (query.cursor) {
    urlParameters.set('cursor', query.cursor)
  }
  if (query.limit) {
    urlParameters.set('per_page', query.limit)
  }

  const queryString = urlParameters.toString()
  return queryString == '' ? '' : `?${queryString}`
}

/**
 * Returns the pagination data based on the passed headers
 * @params {Headers} headers the headers of the response
 * @returns {*}
 */
export const getPaginationDataFromHeaders = function getPaginationDataFromHeaders(
  headers: Headers,
) {
  const linkHeader = headers.get('Link')
  const itemsCountHeader = headers.get('X-Items-Count')
  return {
    links: parseLinkHeader(linkHeader),
    totalItems: parseInt(itemsCountHeader, 10),
  }
}

export default {getSearchQuerystring, getPaginationDataFromHeaders}
