import React, {Component} from 'react'
import {Box, Columns, Tile, Heading, Section, Notification} from 'react-bulma-components'
import {connect} from 'react-redux'
import {AreaChart, Area, PieChart, Pie, Cell, Tooltip} from 'recharts/lib'
import Rect from '@reach/rect'
// import * as selectors from '../../common/selectors/donations'

import StatsElement from './StatsElement'
import {DateTime} from 'luxon'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.locale(en)

export class DashboardItemSizeWatcher extends Component {
    render() {
        const {render} = this.props
        return (
            <Rect>
                {({rect, ref}) => (
                    <div style={{width: '100%', height: '100%', backgroundColor: 'transparent'}} ref={ref}>
                        {rect && render({width: rect.width, height: rect.height})}
                    </div>
                )}
            </Rect>
        )
    }
}

export const StatisticalItem = ({
    label = 'default',
    link,
    value = '',
    postfix = '',
    valueStyling = ['is-size-4'],
    isUppercase = true,
}) => {
    const formatter = typeof value === 'number' ? new Intl.NumberFormat() : {format: value => value}
    const hasLink = typeof link === 'string'
    const linkProps = hasLink ? {href: link} : {}
    const valueClasses = [...valueStyling, 'is-pulled-right	 has-text-weight-semibold'].join(' ')
    return (
        <Columns>
            <Columns.Column style={{paddingBottom: '0.1rem', paddingTop: 0}}>
                {!hasLink && <span className={isUppercase ? `is-uppercase` : ''}>{label}</span>}
                {hasLink && (
                    <a {...linkProps} title={label}>
                        {label}
                    </a>
                )}
            </Columns.Column>
            <Columns.Column style={{paddingBottom: 0, paddingTop: 0}}>
                <span className={valueClasses}>
                    {formatter.format(value)}
                    {postfix !== '' ? ` ${postfix}` : ''}
                </span>
            </Columns.Column>
        </Columns>
    )
}

/**
 * DashboardOverviewPage
 */
export class DashboardOverviewPage extends Component {
    render() {
        return (
            <Section>
                <Box>
                    <Tile kind="ancestor">
                        <Tile size={8} vertical>
                            <Tile>
                                <Tile kind="parent" vertical>
                                    <Tile renderAs="article" kind="child" notification color="dark">
                                        <Heading>Profile Coverage</Heading>
                                        <Heading subtitle>Coverage of the different profiles types</Heading>
                                        <StatsElement
                                            group="/v1/admin/dashboard/profile_distribution"
                                            render={({data}) => {
                                                if (!data) return <p>Something went wrong</p>
                                                const COLORS = {
                                                    business: '#469944',
                                                    charity: '#FF6955',
                                                    transporter: '#E15AA4',
                                                    volunteer: '#9E5DAE',
                                                }

                                                const pieChartData = Object.keys(data).map(type => {
                                                    return {
                                                        name: type,
                                                        value: parseInt(data[type], 10),
                                                        color: COLORS[type],
                                                    }
                                                })

                                                return (
                                                    <DashboardItemSizeWatcher
                                                        width="100%"
                                                        render={props => {
                                                            return (
                                                                <React.Fragment>
                                                                    <PieChart
                                                                        width={props.width}
                                                                        height={props.width}
                                                                        margin={{top: 0, right: 0, left: 0, bottom: 0}}
                                                                    >
                                                                        <Pie
                                                                            startAngle={180}
                                                                            endAngle={0}
                                                                            paddingAngle={5}
                                                                            data={pieChartData}
                                                                            dataKey="value"
                                                                        >
                                                                            {pieChartData.map((entry, index) => (
                                                                                <Cell key={index} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip cursor={false} />
                                                                    </PieChart>
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </Tile>
                                    <Tile renderAs="article" kind="child" notification color="warning">
                                        <Heading>Latest donations</Heading>
                                        <Heading subtitle>Five latest donations created</Heading>
                                        <StatsElement
                                            group="/v1/admin/dashboard/recent_donations?limit=5"
                                            render={({data}) => {
                                                if (!data) return <p>Something went wrong</p>
                                                const timeAgo = new TimeAgo('en-GB')

                                                return (
                                                    <React.Fragment>
                                                        {Array.isArray(data) &&
                                                            data.map(item => {
                                                                // const totalWeight = selectors.getTotalWeightOfDonation(
                                                                //     item,
                                                                // )
                                                                const creationDate = DateTime.fromISO(item.created_at)
                                                                const formattedCreationDate = creationDate.isValid
                                                                    ? timeAgo.format(creationDate.toJSDate())
                                                                    : 'N/A'

                                                                return (
                                                                    <StatisticalItem
                                                                        key={item.id}
                                                                        link={`/dashboard/donations/${item.id}`}
                                                                        label={item.name}
                                                                        value={formattedCreationDate}
                                                                        valueStyling={['is-size-4']}
                                                                        isUppercase={false}
                                                                    />
                                                                )
                                                            })}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    </Tile>
                                </Tile>
                                <Tile kind="parent" vertical>
                                    <Tile renderAs="article" kind="child" notification color="warning">
                                        <Heading>New profiles</Heading>
                                        <Heading subtitle>Recently registered profiles</Heading>
                                        <StatsElement
                                            group="/v1/admin/dashboard/recent_profiles?limit=15"
                                            render={({data}) => {
                                                if (!data) return <p>Something went wrong</p>

                                                const timeAgo = new TimeAgo('en-GB')
                                                return (
                                                    <React.Fragment>
                                                        <ul>
                                                            {Array.isArray(data) &&
                                                                data.map(profile => {
                                                                    const creationDate = DateTime.fromISO(
                                                                        profile.created_at,
                                                                    ).toJSDate()
                                                                    const relativeCreationDate = timeAgo.format(
                                                                        creationDate,
                                                                    )
                                                                    return (
                                                                        <StatisticalItem
                                                                            key={profile.id}
                                                                            link={`/dashboard/profiles/${profile.id}`}
                                                                            valueStyling={['is-size-6']}
                                                                            label={profile.fullName}
                                                                            value={relativeCreationDate}
                                                                        />
                                                                    )
                                                                })}
                                                        </ul>
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    </Tile>
                                    <Tile
                                        className="is-paddingless"
                                        renderAs="article"
                                        kind="child"
                                        notification
                                        color="info"
                                    >
                                        <div className="dashboard_content">
                                            <div className="dashboard_header" style={{padding: '1.25rem'}}>
                                                <Heading renderAs="h2">This year</Heading>
                                                <Heading subtitle>Number of donations</Heading>
                                            </div>
                                            <StatsElement
                                                group="/v1/admin/dashboard/statistics?period=this_year"
                                                render={({data}) => {
                                                    if (!data || !data.kpi) return <p>Something went wrong</p>
                                                    const {statistics, kpi} = data
                                                    const {totalDonations, totalMeals, totalDonationsAccepted} = kpi
                                                    return (
                                                        <React.Fragment>
                                                            <div
                                                                className="content"
                                                                style={{padding: '1.25rem', paddingTop: 0}}
                                                            >
                                                                <StatisticalItem
                                                                    label="Total food saved"
                                                                    postfix="kg"
                                                                    value={totalDonationsAccepted.weight}
                                                                />
                                                                <StatisticalItem
                                                                    label="Total meals"
                                                                    value={totalMeals}
                                                                />
                                                                <StatisticalItem
                                                                    label="Total donations"
                                                                    value={totalDonations}
                                                                />
                                                            </div>
                                                            {statistics.length > 1 && (
                                                                <div>
                                                                    <DashboardItemSizeWatcher
                                                                        width="100%"
                                                                        render={props => {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <AreaChart
                                                                                        width={props.width}
                                                                                        height={100}
                                                                                        data={statistics}
                                                                                        margin={{
                                                                                            top: 10,
                                                                                            right: 0,
                                                                                            left: 0,
                                                                                            bottom: 10,
                                                                                        }}
                                                                                    >
                                                                                        <defs>
                                                                                            <linearGradient
                                                                                                id="colorPv"
                                                                                                x1="0"
                                                                                                y1="0"
                                                                                                x2="0"
                                                                                                y2="1"
                                                                                            >
                                                                                                <stop
                                                                                                    offset="5%"
                                                                                                    stopColor="#82ca9d"
                                                                                                    stopOpacity={0.8}
                                                                                                />
                                                                                                <stop
                                                                                                    offset="95%"
                                                                                                    stopColor="#82ca9d"
                                                                                                    stopOpacity={0}
                                                                                                />
                                                                                            </linearGradient>
                                                                                        </defs>
                                                                                        <Area
                                                                                            type="monotone"
                                                                                            dataKey="totalDonations"
                                                                                            stroke="white"
                                                                                            fill="url(#colorPv)"
                                                                                        />
                                                                                        <Tooltip
                                                                                            cursor={false}
                                                                                            itemStyle={{color: 'black'}}
                                                                                            labelStyle={{
                                                                                                color: 'black',
                                                                                            }}
                                                                                        />
                                                                                    </AreaChart>
                                                                                </React.Fragment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                }}
                                            />
                                        </div>
                                    </Tile>
                                </Tile>
                            </Tile>
                            <Tile kind="parent">
                                <Tile renderAs="article" kind="child" notification color="danger">
                                    <Heading>Most active profiles</Heading>
                                    <Heading subtitle>Profiles which logged most frequently</Heading>
                                    <StatsElement
                                        group="/v1/admin/dashboard/mostactive_users?limit=15"
                                        render={({data}) => {
                                            if (!data) return <p>Something went wrong</p>
                                            // const highestLogins = Object.values(data.metadata).reduce(
                                            //     (current, value) => current + value,
                                            // )
                                            const sortedItems = data.sorted
                                            return (
                                                <React.Fragment>
                                                    {Array.isArray(sortedItems) &&
                                                        sortedItems.map(itemId => {
                                                            const totalLogins = data.metadata[itemId] || 0
                                                            const profile = data.profiles[itemId] || {id: 0}
                                                            return (
                                                                <StatisticalItem
                                                                    key={profile.id}
                                                                    link={`/dashboard/profiles/${profile.id}`}
                                                                    label={profile.fullName}
                                                                    value={totalLogins}
                                                                    isUppercase={false}
                                                                />
                                                            )
                                                        })}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </Tile>
                            </Tile>
                        </Tile>
                        <Tile kind="parent" vertical>
                            <Tile className="is-paddingless" renderAs="article" kind="child" notification color="info">
                                <div className="dashboard_content">
                                    <div className="dashboard_header" style={{padding: '1.25rem'}}>
                                        <Heading renderAs="h2">Food donated</Heading>
                                        <Heading subtitle>The amount of food saved this month</Heading>
                                    </div>
                                    <StatsElement
                                        group="/v1/admin/dashboard/statistics?period=this_month"
                                        render={({data}) => {
                                            if (!data || !data.kpi) return <p>Something went wrong</p>
                                            const {/*statistics,*/ kpi} = data
                                            const {
                                                // totalDonations,
                                                // totalMeals,
                                                totalDonationsPosted,
                                                totalDonationsAccepted,
                                            } = kpi
                                            return (
                                                <React.Fragment>
                                                    <div className="content" style={{padding: '1.25rem'}}>
                                                        <StatisticalItem
                                                            label="Food saved"
                                                            postfix="kg"
                                                            valueStyling={['is-size-2']}
                                                            value={totalDonationsAccepted.weight}
                                                        />
                                                        <StatisticalItem
                                                            label="Food posted"
                                                            postfix="kg"
                                                            valueStyling={['is-size-2']}
                                                            value={totalDonationsPosted.weight}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </div>
                            </Tile>

                            <Tile
                                className="is-paddingless"
                                renderAs="article"
                                kind="child"
                                notification
                                color="danger"
                            >
                                <div className="dashboard_content">
                                    <div className="dashboard_header" style={{padding: '1.25rem'}}>
                                        <Heading renderAs="h2">Acceptance rate</Heading>
                                        <Heading subtitle>
                                            Compares the donations accepted with the number of donations
                                        </Heading>
                                    </div>
                                    <StatsElement
                                        group="/v1/admin/dashboard/statistics?period=this_month"
                                        render={({data}) => {
                                            if (!data || !data.kpi) return <p>Something went wrong</p>
                                            const {statistics /*, kpi*/} = data
                                            // const {
                                            //     totalDonations,
                                            //     totalMeals,
                                            //     totalDonationsPosted,
                                            //     totalDonationsAccepted,
                                            // } = kpi
                                            return (
                                                <React.Fragment>
                                                    {statistics.length > 0 && (
                                                        <DashboardItemSizeWatcher
                                                            width="100%"
                                                            render={props => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <AreaChart
                                                                            width={props.width}
                                                                            height={100}
                                                                            data={statistics}
                                                                            margin={{
                                                                                top: 10,
                                                                                right: 0,
                                                                                left: 0,
                                                                                bottom: 10,
                                                                            }}
                                                                        >
                                                                            <defs>
                                                                                <linearGradient
                                                                                    id="colorUv"
                                                                                    x1="0"
                                                                                    y1="0"
                                                                                    x2="0"
                                                                                    y2="1"
                                                                                >
                                                                                    <stop
                                                                                        offset="5%"
                                                                                        stopColor="#8884d8"
                                                                                        stopOpacity={0.8}
                                                                                    />
                                                                                    <stop
                                                                                        offset="95%"
                                                                                        stopColor="#8884d8"
                                                                                        stopOpacity={0}
                                                                                    />
                                                                                </linearGradient>
                                                                                <linearGradient
                                                                                    id="colorPv"
                                                                                    x1="0"
                                                                                    y1="0"
                                                                                    x2="0"
                                                                                    y2="1"
                                                                                >
                                                                                    <stop
                                                                                        offset="5%"
                                                                                        stopColor="#82ca9d"
                                                                                        stopOpacity={0.8}
                                                                                    />
                                                                                    <stop
                                                                                        offset="95%"
                                                                                        stopColor="#82ca9d"
                                                                                        stopOpacity={0}
                                                                                    />
                                                                                </linearGradient>
                                                                            </defs>
                                                                            <Area
                                                                                type="monotone"
                                                                                dataKey="numberOfFoodAccepted"
                                                                                stroke="white"
                                                                                fill="url(#colorUv)"
                                                                            />
                                                                            <Area
                                                                                type="monotone"
                                                                                dataKey="totalDonations"
                                                                                stroke="white"
                                                                                fill="url(#colorPv)"
                                                                            />
                                                                            <Tooltip
                                                                                cursor={false}
                                                                                itemStyle={{color: 'black'}}
                                                                                labelStyle={{color: 'black'}}
                                                                            />
                                                                        </AreaChart>
                                                                    </React.Fragment>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </div>
                            </Tile>

                            <Tile className="is-paddingless" renderAs="article" kind="child" notification color="info">
                                <div className="dashboard_content">
                                    <div className="dashboard_header" style={{padding: '1.25rem'}}>
                                        <Heading renderAs="h2">Last 24 hours</Heading>
                                    </div>
                                    <StatsElement
                                        group="/v1/admin/dashboard/statistics?period=last24hours"
                                        render={({data}) => {
                                            if (!data || !data.kpi) return <p>Something went wrong</p>
                                            const {statistics, kpi} = data
                                            const {totalDonations, totalMeals, totalDonationsAccepted} = kpi
                                            return (
                                                <React.Fragment>
                                                    <div
                                                        className="content"
                                                        style={{padding: '1.25rem', paddingTop: 0}}
                                                    >
                                                        <StatisticalItem
                                                            label="Total food saved"
                                                            postfix="kg"
                                                            value={totalDonationsAccepted.weight}
                                                        />
                                                        <StatisticalItem label="Total meals" value={totalMeals} />
                                                        <StatisticalItem
                                                            label="Total donations"
                                                            value={totalDonations}
                                                        />
                                                    </div>
                                                    {statistics.length > 1 && (
                                                        <div>
                                                            <DashboardItemSizeWatcher
                                                                width="100%"
                                                                render={props => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <AreaChart
                                                                                width={props.width}
                                                                                height={60}
                                                                                data={statistics}
                                                                                margin={{
                                                                                    top: 10,
                                                                                    right: 0,
                                                                                    left: 0,
                                                                                    bottom: 10,
                                                                                }}
                                                                            >
                                                                                <defs>
                                                                                    <linearGradient
                                                                                        id="colorUv"
                                                                                        x1="0"
                                                                                        y1="0"
                                                                                        x2="0"
                                                                                        y2="1"
                                                                                    >
                                                                                        <stop
                                                                                            offset="5%"
                                                                                            stopColor="#8884d8"
                                                                                            stopOpacity={0.8}
                                                                                        />
                                                                                        <stop
                                                                                            offset="95%"
                                                                                            stopColor="#8884d8"
                                                                                            stopOpacity={0}
                                                                                        />
                                                                                    </linearGradient>
                                                                                    <linearGradient
                                                                                        id="colorPv"
                                                                                        x1="0"
                                                                                        y1="0"
                                                                                        x2="0"
                                                                                        y2="1"
                                                                                    >
                                                                                        <stop
                                                                                            offset="5%"
                                                                                            stopColor="#82ca9d"
                                                                                            stopOpacity={0.8}
                                                                                        />
                                                                                        <stop
                                                                                            offset="95%"
                                                                                            stopColor="#82ca9d"
                                                                                            stopOpacity={0}
                                                                                        />
                                                                                    </linearGradient>
                                                                                </defs>
                                                                                <Area
                                                                                    type="monotone"
                                                                                    dataKey="foodAccepted"
                                                                                    stroke="white"
                                                                                    fill="url(#colorUv)"
                                                                                />
                                                                                <Area
                                                                                    type="monotone"
                                                                                    dataKey="foodPosted"
                                                                                    stroke="white"
                                                                                    fill="url(#colorPv)"
                                                                                />
                                                                                <Tooltip
                                                                                    cursor={false}
                                                                                    itemStyle={{color: 'black'}}
                                                                                    labelStyle={{color: 'black'}}
                                                                                />
                                                                            </AreaChart>
                                                                        </React.Fragment>
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </div>
                            </Tile>

                            <Tile
                                className="is-paddingless"
                                renderAs="article"
                                kind="child"
                                notification
                                color="success"
                            >
                                <div className="dashboard_content">
                                    <div className="dashboard_header" style={{padding: '1.25rem'}}>
                                        <Heading renderAs="h2">Acceptance rate (weight)</Heading>
                                        <Heading subtitle>
                                            Compares the donations accepted with the number of donations
                                        </Heading>
                                    </div>
                                    <StatsElement
                                        group="/v1/admin/dashboard/statistics?period=this_month"
                                        render={({data}) => {
                                            if (!data || !data.kpi) return <p>Something went wrong</p>
                                            const {statistics /*, kpi*/} = data
                                            // const {
                                            //     totalDonations,
                                            //     totalMeals,
                                            //     totalDonationsPosted,
                                            //     totalDonationsAccepted,
                                            // } = kpi
                                            return (
                                                <React.Fragment>
                                                    {statistics.length > 0 && (
                                                        <DashboardItemSizeWatcher
                                                            width="100%"
                                                            render={props => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <AreaChart
                                                                            width={props.width}
                                                                            height={100}
                                                                            data={statistics}
                                                                            margin={{
                                                                                top: 10,
                                                                                right: 0,
                                                                                left: 0,
                                                                                bottom: 10,
                                                                            }}
                                                                        >
                                                                            <defs>
                                                                                <linearGradient
                                                                                    id="colorUv"
                                                                                    x1="0"
                                                                                    y1="0"
                                                                                    x2="0"
                                                                                    y2="1"
                                                                                >
                                                                                    <stop
                                                                                        offset="5%"
                                                                                        stopColor="#8884d8"
                                                                                        stopOpacity={0.8}
                                                                                    />
                                                                                    <stop
                                                                                        offset="95%"
                                                                                        stopColor="#8884d8"
                                                                                        stopOpacity={0}
                                                                                    />
                                                                                </linearGradient>
                                                                                <linearGradient
                                                                                    id="colorPv"
                                                                                    x1="0"
                                                                                    y1="0"
                                                                                    x2="0"
                                                                                    y2="1"
                                                                                >
                                                                                    <stop
                                                                                        offset="5%"
                                                                                        stopColor="#82ca9d"
                                                                                        stopOpacity={0.8}
                                                                                    />
                                                                                    <stop
                                                                                        offset="95%"
                                                                                        stopColor="#82ca9d"
                                                                                        stopOpacity={0}
                                                                                    />
                                                                                </linearGradient>
                                                                            </defs>
                                                                            <Area
                                                                                type="monotone"
                                                                                dataKey="foodAccepted"
                                                                                stroke="white"
                                                                                fill="url(#colorUv)"
                                                                            />
                                                                            <Area
                                                                                type="monotone"
                                                                                dataKey="totalDonations"
                                                                                stroke="white"
                                                                                fill="url(#colorPv)"
                                                                            />
                                                                            <Tooltip
                                                                                cursor={false}
                                                                                itemStyle={{color: 'black'}}
                                                                                labelStyle={{color: 'black'}}
                                                                            />
                                                                        </AreaChart>
                                                                    </React.Fragment>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </div>
                            </Tile>

                            <Tile className="is-paddingless" renderAs="article" kind="child" notification color="info">
                                <div className="dashboard_content">
                                    <div className="dashboard_header" style={{padding: '1.25rem'}}>
                                        <Heading renderAs="h2">This week</Heading>
                                    </div>
                                    <StatsElement
                                        group="/v1/admin/dashboard/statistics?period=this_week"
                                        render={({data}) => {
                                            if (!data || !data.kpi) return <p>Something went wrong</p>
                                            const {statistics, kpi} = data
                                            const {totalDonations, totalMeals, totalDonationsAccepted} = kpi
                                            return (
                                                <React.Fragment>
                                                    <div
                                                        className="content"
                                                        style={{padding: '1.25rem', paddingTop: 0}}
                                                    >
                                                        <StatisticalItem
                                                            label="Total food saved"
                                                            postfix="kg"
                                                            value={totalDonationsAccepted.weight}
                                                        />
                                                        <StatisticalItem label="Total meals" value={totalMeals} />
                                                        <StatisticalItem
                                                            label="Total donations"
                                                            value={totalDonations}
                                                        />
                                                    </div>
                                                    {statistics.length > 1 && (
                                                        <div>
                                                            <DashboardItemSizeWatcher
                                                                width="100%"
                                                                render={props => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <AreaChart
                                                                                width={props.width}
                                                                                height={60}
                                                                                data={statistics}
                                                                                margin={{
                                                                                    top: 10,
                                                                                    right: 0,
                                                                                    left: 0,
                                                                                    bottom: 10,
                                                                                }}
                                                                            >
                                                                                <defs>
                                                                                    <linearGradient
                                                                                        id="colorUv"
                                                                                        x1="0"
                                                                                        y1="0"
                                                                                        x2="0"
                                                                                        y2="1"
                                                                                    >
                                                                                        <stop
                                                                                            offset="5%"
                                                                                            stopColor="#8884d8"
                                                                                            stopOpacity={0.8}
                                                                                        />
                                                                                        <stop
                                                                                            offset="95%"
                                                                                            stopColor="#8884d8"
                                                                                            stopOpacity={0}
                                                                                        />
                                                                                    </linearGradient>
                                                                                    <linearGradient
                                                                                        id="colorPv"
                                                                                        x1="0"
                                                                                        y1="0"
                                                                                        x2="0"
                                                                                        y2="1"
                                                                                    >
                                                                                        <stop
                                                                                            offset="5%"
                                                                                            stopColor="#82ca9d"
                                                                                            stopOpacity={0.8}
                                                                                        />
                                                                                        <stop
                                                                                            offset="95%"
                                                                                            stopColor="#82ca9d"
                                                                                            stopOpacity={0}
                                                                                        />
                                                                                    </linearGradient>
                                                                                </defs>
                                                                                <Area
                                                                                    type="monotone"
                                                                                    dataKey="foodAccepted"
                                                                                    stroke="white"
                                                                                    fill="url(#colorUv)"
                                                                                />
                                                                                <Area
                                                                                    type="monotone"
                                                                                    dataKey="foodPosted"
                                                                                    stroke="white"
                                                                                    fill="url(#colorPv)"
                                                                                />
                                                                                <Tooltip
                                                                                    cursor={false}
                                                                                    itemStyle={{color: 'black'}}
                                                                                    labelStyle={{color: 'black'}}
                                                                                />
                                                                            </AreaChart>
                                                                        </React.Fragment>
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </div>
                            </Tile>

                            <Tile className="is-paddingless" renderAs="article" kind="child" notification color="info">
                                <div className="dashboard_content">
                                    <div className="dashboard_header" style={{padding: '1.25rem'}}>
                                        <Heading renderAs="h2">This month</Heading>
                                    </div>
                                    <StatsElement
                                        group="/v1/admin/dashboard/statistics?period=this_month"
                                        render={({data}) => {
                                            if (!data || !data.kpi) return <p>Something went wrong</p>
                                            const {statistics, kpi} = data
                                            const {totalDonations, totalMeals, totalDonationsAccepted} = kpi
                                            return (
                                                <React.Fragment>
                                                    <div
                                                        className="content"
                                                        style={{padding: '1.25rem', paddingTop: 0}}
                                                    >
                                                        <StatisticalItem
                                                            label="Total food saved"
                                                            postfix="kg"
                                                            value={totalDonationsAccepted.weight}
                                                        />
                                                        <StatisticalItem label="Total meals" value={totalMeals} />
                                                        <StatisticalItem
                                                            label="Total donations"
                                                            value={totalDonations}
                                                        />
                                                    </div>
                                                    {statistics.length > 1 && (
                                                        <div>
                                                            <DashboardItemSizeWatcher
                                                                width="100%"
                                                                render={props => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <AreaChart
                                                                                width={props.width}
                                                                                height={100}
                                                                                data={statistics}
                                                                                margin={{
                                                                                    top: 10,
                                                                                    right: 0,
                                                                                    left: 0,
                                                                                    bottom: 10,
                                                                                }}
                                                                            >
                                                                                <defs>
                                                                                    <linearGradient
                                                                                        id="colorUv"
                                                                                        x1="0"
                                                                                        y1="0"
                                                                                        x2="0"
                                                                                        y2="1"
                                                                                    >
                                                                                        <stop
                                                                                            offset="5%"
                                                                                            stopColor="#8884d8"
                                                                                            stopOpacity={0.8}
                                                                                        />
                                                                                        <stop
                                                                                            offset="95%"
                                                                                            stopColor="#8884d8"
                                                                                            stopOpacity={0}
                                                                                        />
                                                                                    </linearGradient>
                                                                                    <linearGradient
                                                                                        id="colorPv"
                                                                                        x1="0"
                                                                                        y1="0"
                                                                                        x2="0"
                                                                                        y2="1"
                                                                                    >
                                                                                        <stop
                                                                                            offset="5%"
                                                                                            stopColor="#82ca9d"
                                                                                            stopOpacity={0.8}
                                                                                        />
                                                                                        <stop
                                                                                            offset="95%"
                                                                                            stopColor="#82ca9d"
                                                                                            stopOpacity={0}
                                                                                        />
                                                                                    </linearGradient>
                                                                                </defs>
                                                                                <Area
                                                                                    type="monotone"
                                                                                    dataKey="foodAccepted"
                                                                                    stroke="white"
                                                                                    fill="url(#colorUv)"
                                                                                />
                                                                                <Area
                                                                                    type="monotone"
                                                                                    dataKey="foodPosted"
                                                                                    stroke="white"
                                                                                    fill="url(#colorPv)"
                                                                                />
                                                                                <Tooltip
                                                                                    cursor={false}
                                                                                    itemStyle={{color: 'black'}}
                                                                                    labelStyle={{color: 'black'}}
                                                                                />
                                                                            </AreaChart>
                                                                        </React.Fragment>
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </div>
                            </Tile>
                        </Tile>
                    </Tile>
                    <Notification>
                        The data visualised on this dashboard is not realtime and can be upto five minutes out of date
                    </Notification>
                </Box>
            </Section>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = dispatch => {
    return {
        // ...uiActions,
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOverviewPage)
