import * as actionTypes from './actionTypes'

export const setUiState = (key: string, value: any) => ({
    type: actionTypes.SET_UI_STATE,
    payload: {
        key,
        value,
    },
})

export const resetUiState = (keys: Array<string>) => ({
    type: actionTypes.RESET_UI_STATE,
    payload: {
        keys,
    },
})

export const setModalState = (modalName: string, state: boolean) => ({
    type: actionTypes.SET_MODAL_STATE,
    payload: {
        modalName,
        state,
    },
})
