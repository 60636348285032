import * as actionTypes from './actionTypes'

const initialState = {
    isLoaded: false,
    isLoading: false,
    items: [],
    pagination: {},
    errors: {},
    isError: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DONATIONS_START: 
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            }
        case actionTypes.GET_DONATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                items: action.donations,
                pagination: action.pagination,
            }
        case actionTypes.GET_DONATIONS_FAIL:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                errors: action.errors,
                isError: true
            }
        default:
            return state
    }
}

export default reducer