// @flow
// import * as selectors from '../../common/reducers/profiles/selectors'
import * as actions from '../../common/reducers/profiles/adminActions'
import * as uiActions from './reducer/actions'
import * as uiSelectors from './reducer/selectors'
import {Container, Button, Heading, Columns, Content, Image, Media, Section} from 'react-bulma-components'
import React, {Component} from 'react'

import {connect} from 'react-redux'
import {getStatus} from 'redux-resource'
import getResources from 'redux-resource/lib/utils/get-resources'
import GenericConfirmDialog from './modals/GenericConfirmDialog'
import ProfilePicker from '../../common/components/profiles/ProfilePicker'

export const NetworkItem = ({profile, removeProfile}) => {
    return (
        <Media>
            <Media.Item renderAs="figure" position="left">
                <Image
                    size={64}
                    alt="64x64"
                    src={profile.image ? profile.image.url : 'http://bulma.io/images/placeholders/128x128.png'}
                />
            </Media.Item>
            <Media.Item>
                <Content>{profile.fullName}</Content>
            </Media.Item>
            <Media.Item renderAs="figure" position="right">
                <Button remove onClick={e => removeProfile(profile)} />
            </Media.Item>
        </Media>
    )
}

/**
 * DashboardProfileNetworkPage
 */
export class DashboardProfileNetworkPage extends Component {
    componentDidMount() {
        this.refreshItems()
    }

    componentWillReceiveProps(nextProps, prevProps) {
        if (this.props.addProfileRequestStatus.pending && nextProps.addProfileRequestStatus.succeeded) {
            this.refreshItems()
        } else if (this.props.removeProfileRequestStatus.pending && nextProps.removeProfileRequestStatus.succeeded) {
            this.refreshItems()
        }
    }

    refreshItems() {
        this.props.dispatch(this.props.getProfileBranches(this.props.id))
    }

    addProfile = profile => {
        this.props.dispatch(uiActions.setUiState('addBranchProfile', profile))
        this.props.dispatch(uiActions.setModalState('confirmAddBranchProfile', true))
    }
    removeProfile = profile => {
        this.props.dispatch(uiActions.setUiState('deleteBranchProfileId', profile))
        this.props.dispatch(uiActions.setModalState('confirmDeleteBranchProfile', true))
    }

    onAddProfileDialogResponse = response => {
        if (response) {
            const addBranchProfileId = this.props.addBranchProfile.id
            this.props.dispatch(this.props.addProfileAsBranch(addBranchProfileId, this.props.id))
        }

        this.props.dispatch(uiActions.setUiState('addBranchProfile', null))
        this.props.dispatch(uiActions.setModalState('confirmAddBranchProfile', false))
    }
    onDeleteProfileDialogResponse = response => {
        if (response) {
            const deleteBranchProfileId = this.props.deleteBranchProfileId.id
            this.props.dispatch(this.props.removeProfileAsBranch(deleteBranchProfileId, this.props.id))
        }

        this.props.dispatch(uiActions.setUiState('deleteBranchProfileId', null))
        this.props.dispatch(uiActions.setModalState('confirmDeleteBranchProfile', false))
    }

    render() {
        const {
            requestStatus,
            branchProfiles = [],
            confirmAddBranchProfile = false,
            confirmDeleteBranchProfile = false,
        } = this.props
        if (requestStatus.pending) {
            return <p>Loading...</p>
        }

        return (
            <React.Fragment>
                <Section renderAs="header">
                    <Container>
                        <Heading>Manage branch profiles for profile {this.props.id}</Heading>
                    </Container>
                </Section>

                <Columns>
                    <Columns.Column>
                        <Heading size={6}>Branch profiles</Heading>
                        {branchProfiles.length === 0 ? <p>No branch profiles associated with this profile</p> : null}
                        {branchProfiles.map(profile => (
                            <NetworkItem
                                key={profile.id}
                                profile={profile}
                                addProfile={this.addProfile}
                                removeProfile={this.removeProfile}
                            />
                        ))}
                    </Columns.Column>
                    <Columns.Column>
                        <Heading size={6}>Available profiles</Heading>
                        <div className="">
                            <ProfilePicker
                                allowProfileTypes="business,charity"
                                onChange={profile => {
                                    this.props.dispatch(uiActions.setUiState('addBranchProfile', profile))
                                }}
                            />
                            <Button color="primary" onClick={e => this.addProfile(this.props.addBranchProfile)}>
                                Add as branch
                            </Button>
                            {this.props.addBranchProfile ? (
                                <NetworkItem
                                    profile={this.props.addBranchProfile}
                                    removeProfile={e =>
                                        this.props.dispatch(uiActions.setUiState('addBranchProfile', null))
                                    }
                                />
                            ) : null}
                        </div>
                    </Columns.Column>
                </Columns>
                {confirmAddBranchProfile && (
                    <GenericConfirmDialog
                        title={`Are you sure you want to add this branch ${JSON.stringify(
                            this.props.addBranchProfile && this.props.addBranchProfile.fullName,
                        )}?`}
                        confirmButtonLabel="Yes"
                        cancelButtonLabel="No"
                        onResponse={this.onAddProfileDialogResponse}
                    />
                )}
                {confirmDeleteBranchProfile && (
                    <GenericConfirmDialog
                        title={`Are you sure want to delete this branch?`}
                        confirmButtonLabel="Yes"
                        cancelButtonLabel="No"
                        onResponse={this.onDeleteProfileDialogResponse}
                    />
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        requestStatus: getStatus(state.resources.network, `requests[getProfileBranches-${ownProps.id}].status`),

        addProfileRequestStatus: getStatus(
            state.resources.network,
            `requests[addProfileAsBranch-${ownProps.id}].status`,
        ),
        removeProfileRequestStatus: getStatus(
            state.resources.network,
            `requests[removeProfileAsBranch-${ownProps.id}].status`,
        ),
        branchProfiles: getResources(state.resources.network, `networkOf${ownProps.id}`),

        deleteBranchProfileId: uiSelectors.getUiState('deleteBranchProfileId')(state),
        confirmDeleteBranchProfile: uiSelectors.getModalState('confirmDeleteBranchProfile')(state),

        addBranchProfile: uiSelectors.getUiState('addBranchProfile')(state),
        confirmAddBranchProfile: uiSelectors.getModalState('confirmAddBranchProfile')(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...actions,
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProfileNetworkPage)
