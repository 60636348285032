import React, {Component} from 'react'
import {Form} from 'react-bulma-components'

class InputNoAutoComplete extends Component {
  render() {
    const isChrome: boolean =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    const autoComplete: string = isChrome ? 'off' : 'off'
    return <Form.Input {...this.props} autoComplete={autoComplete} />
  }
}

export default InputNoAutoComplete
