// @flow
import React, {Component} from 'react'
import {Container, Content, Heading} from 'react-bulma-components'
import {reportError} from '../utils/reportError'

/**
 * ErrorBoundary
 */
export class ErrorBoundary extends Component {
  state = {hasError: false}

  componentDidCatch(error, info) {
    this.setState({hasError: true})
    reportError(error, {extra: info})
  }

  render() {
    return this.state.hasError ? (
      <Container>
        <Heading>Hmmm... something broke.</Heading>
        <Content>
          <p>
            Please{' '}
            <a
              href="mailto:info@planzheroes.org"
              className="has-text-color"
            >
              {' '}
              let us know
            </a>{' '}
            what happened.
          </p>
        </Content>
      </Container>
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
