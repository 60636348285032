//UI action types
export const SET_UI_STATE = '@donations/SET_UI_STATE'
export const RESET_UI_STATE = '@donations/RESET_UI_STATE'
export const SET_MODAL_STATE = '@donations/SET_MODAL_STATE'

//API call action types
export const GET_DONATIONS_START = 'GET_DONATIONS_START'
export const GET_DONATIONS_SUCCESS = 'GET_DONATIONS_SUCCESS'
export const GET_DONATIONS_FAIL = 'GET_DONATIONS_FAIL'

export const DELETE_DONATIONS_START = 'DELETE_DONATIONS_START'
export const DELETE_DONATIONS_SUCCESS = 'DELETE_DONATIONS_SUCCESS'
export const DELETE_DONATIONS_FAIL = 'DELETE_DONATIONS_FAIL'

export const CLAIM_DONATIONS_START = 'CLAIM_DONATIONS_START'
export const CLAIM_DONATIONS_SUCCESS = 'CLAIM_DONATIONS_SUCCESS'
export const CLAIM_DONATIONS_FAIL = 'CLAIM_DONATIONS_FAIL'

