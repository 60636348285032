// @flow
import React, {Component} from 'react'
import {connect, getIn} from 'formik'
import {Columns, Form} from 'react-bulma-components'
import debounce from 'lodash/debounce'

import TextInput from '../form/TextInput'
import WeightTextField from './WeightTextField'

type DonationItemWeightCalculatorProps = {
  name: string,
  itemIndex: number,
}

/**
 * DonationWeightCalculator
 */
class DonationItemWeightCalculator extends Component<DonationItemWeightCalculatorProps> {
  _isMounted: boolean = false

  constructor(props: DonationItemWeightCalculatorProps) {
    super(props)
    this.quantityRef = React.createRef()
    this.weightRef = React.createRef()
    this.suggestedWeightRef = React.createRef()
    this.debouncedUpdateSuggestedWeight = debounce(
      this.updateSuggestedWeight,
      100,
    )
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getFieldName() {
    return this.props.name && this.props.name.length > 0
      ? `${this.props.name}.`
      : ''
  }

  getFieldValue(value) {
    const lastCharacterOfValue = value.substr(-1)
    if (lastCharacterOfValue === '.') {
      return value
    }

    return value
  }

  onQuantityChanged = event => {
    const fieldName = this.getFieldName()
    const fieldValue = this.getFieldValue(event.target.value)
    this.props.formik.setFieldValue(`${fieldName}quantity`, fieldValue)
    this.debouncedUpdateSuggestedWeight()
  }

  onQuantityBlur = event => {
    const fieldName = this.getFieldName()
    this.props.formik.setFieldValue(
      `${fieldName}quantity`,
      parseFloat(event.target.value),
    )
  }

  onItemWeightChanged = event => {
    const fieldName = this.getFieldName()
    this.props.formik.setFieldValue(`${fieldName}itemWeight`, event)
    this.debouncedUpdateSuggestedWeight()
  }

  onItemWeightBlur = event => {
    const fieldName = this.getFieldName()
    let fieldValue = parseFloat(event.value)
    if (isNaN(fieldValue)) fieldValue = 0
    this.props.formik.setFieldValue(`${fieldName}itemWeight`, fieldValue)
  }

  updateSuggestedWeight() {
    if (!this._isMounted) {
      return
    }

    const fieldName = this.getFieldName()
    const quantity = getIn(
      this.props.formik.values,
      `${fieldName}quantity`,
      null,
    )

    //
    const itemWeight = this.weightRef.current.value
      ? parseFloat(this.weightRef.current.value)
      : 0
    let suggestedWeight = quantity * (itemWeight * 1000)
    if (isNaN(itemWeight)) {
      suggestedWeight = 0
    }

    // Update the suggested weight
    this.props.formik.setFieldValue(
      `${fieldName}suggestedWeight`,
      suggestedWeight,
    )
  }

  render() {
    const {itemIndex = 0} = this.props
    const isFirstItem = itemIndex === 0

    const fieldName = this.getFieldName()
    return (
      <div>
        <Columns>
          <Columns.Column size={3}>
            <TextInput
              render={({field, form, unitOfMeasure, ...otherProps}) => {
                const fieldValue = field.value ? String(field.value) : ''
                return <Form.Input value={fieldValue} {...otherProps} />
              }}
              data-testid="quantity"
              name={`${fieldName}quantity`}
              label={isFirstItem ? 'Quantity' : null}
              placeholder="Enter quantity"
              onChange={this.onQuantityChanged}
              onBlur={this.onQuantityBlur}
            />
          </Columns.Column>
          <Columns.Column size={3}>
            <TextInput
              render={({field, form, unitOfMeasure, ...otherProps}) => {
                const fieldValue = field.value ? String(field.value) : ''
                return (
                  <WeightTextField
                    inputRef={this.weightRef}
                    unitOfMeasure="kg"
                    value={fieldValue}
                    onFieldChange={this.onItemWeightChanged}
                    {...otherProps}
                  />
                )
              }}
              data-testid="weight"
              name={`${fieldName}itemWeight`}
              label={isFirstItem ? 'Item weight' : null}
              placeholder="Enter weight"
            />
          </Columns.Column>
          <Columns.Column size={3}>
            <TextInput
              data-testid="totalWeight"
              component={WeightTextField}
              name={`${fieldName}suggestedWeight`}
              label={isFirstItem ? 'Total weight' : null}
              unitOfMeasure="kg"
              disabled
              readOnly
            />
          </Columns.Column>
        </Columns>
      </div>
    )
  }
}
export default connect(DonationItemWeightCalculator)
