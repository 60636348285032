import * as Yup from 'yup'

export const FoodDonationItemSchema = Yup.object().shape({
  productName: Yup.string()
    .min(2, 'You need to give a meaningful description')
    .required('You need to describe the food item'),
  quantity: Yup.number()
    .min(1)
    .required('You need to specify a quantity')
    .label('Quantity')
    .typeError('Invalid number'),
  itemWeight: Yup.number()
    .min(0)
    .required('You need to specify the weight of the food item')
    .label('Item weight')
    .typeError('Invalid number'),
  suggestedWeight: Yup.number()
    .min(0)
    .required('Required')
    .label('Total weight')
    .typeError('Invalid number'),
  description: Yup.string().nullable(),
  notes: Yup.string().nullable(),
  feedback: Yup.string().nullable(),
  unitOfMeasure: Yup.string().default('G'),
})

export default FoodDonationItemSchema
