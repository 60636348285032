// @flow
import {fetch} from 'redux-simple-auth'
import {actionTypes} from 'redux-resource'
import {API_SERVER_URL} from '../../app-constants'
import {handleErrors} from '../../utils/handleErrors'

/**
 * Send a reminder email to the user to request the confirmation of their account
 * @param {*} userId the identifier of the user
 */
export const resendActivationMailForUserId = (userId: number) => {
  return (dispatch: Dispatch) => {
    const generatedRequestKey = `reminderProfile-${userId}`
    dispatch({
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      requestKey: generatedRequestKey,
      resourceType: 'users',
      resources: [userId],
    })

    dispatch(
      fetch(`${API_SERVER_URL}/v1/admin/users/${userId}/resend-activation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
          requestKey: generatedRequestKey,
          resourceType: 'users',
          resources: [json],
        })
        return json
      })
      .catch(error => {
        console.warn('resendActivationMailForUserId() error: ', error) // eslint-disable-line no-console
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_FAILED,
          requestKey: generatedRequestKey,
          resourceType: 'users',
          resources: [userId],
          meta: {error: error.message},
        })
      })
  }
}

export const correctCorruptAddresses = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      fetch(`${window.env.REACT_APP_GATEWAY_SERVER_URL}/v1/admin/corrupt_addresses`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      }),
    )
  }
}

export const hardDeleteCleanup = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      fetch(`${window.env.REACT_APP_GATEWAY_SERVER_URL}/v1/admin/users_hard_delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
      }),
    )
  }
}

export default {
  resendActivationMailForUserId,
}
