// @flow
import React, {Component} from 'react'
import {connect, getIn} from 'formik'

import {Button} from 'react-bulma-components'

type ImageFieldProps = {
  name?: string,
  showFilename?: boolean,
  multiple?: boolean,
  maxFileSize?: number,
  extensions?: Array<string>,
  value?: ImageInfoObject,
  onImageChanged?: (selectedFile: ImageInfoObject) => void,

  formik: FormikActions<any>,
  field?: {
    name: string,
  },
}

/**
 * ImageField
 */
class ImageField extends Component<ImageFieldProps> {
  static defaultProps = {
    showFilename: false,
    multiple: false,
    extensions: ['jpg', 'gif', 'png'],
  }

  inputRef: React.RefObject<HTMLInputElement>

  constructor(props: ImageFieldProps) {
    super(props)
    this.inputRef = React.createRef()
  }

  onFilesSelected = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {formik, name} = this.props

    const fieldName = name ? name : this.props.field && this.props.field.name
    const files = Array.from(event.target.files)
    const allFilePromises = files.map(file => this.readFile(file))

    Promise.all(allFilePromises)
      .then(processedFiles => {
        const errorMessages = {}

        // All files have been retrieved, now iterate through them and generate the new file data
        const fieldValue: Array<?ImageInfoObject> = processedFiles.map(
          (item: any) => {
            if (!item) {
              errorMessages.wrongFileType = 'Unsupported file'
              return null
            }

            if (!this.hasExtension(item.file.name)) {
              errorMessages.wrongFileType = 'Not allowed file type selected'
              return null
            }

            if (item.file.size > this.props.maxFileSize) {
              errorMessages.fileSize = 'File is too big'
              return null
            }

            return {
              url: item.dataURL,
              filename: item.file.name,
              contentType: item.file.type,
              data: item,
            }
          },
        )

        let firstFieldValue: ?ImageInfoObject = null
        if (Array.isArray(fieldValue) && !this.props.multiple) {
          firstFieldValue = fieldValue[0]
        }

        // Update the state of the field within the form
        formik.setFieldTouched(fieldName, true, false)
        formik.setFieldValue(fieldName, firstFieldValue, false)
        if (Object.keys(errorMessages).length > 0) {
          formik.setFieldError(fieldName, errorMessages)
        }

        if (firstFieldValue) {
          if (this.props.onImageChanged) {
            this.props.onImageChanged(firstFieldValue)
          }
        }
      })
      .catch(_error => {
        formik.setFieldTouched(fieldName, true, false)
        formik.setFieldValue(fieldName, undefined, false)
        formik.setFieldError(fieldName, 'Something went wrong')
      })
  }

  // If we not reset the value of the file input we can't reselect the same file selected earlier
  resetFileInputState() {
    this.inputRef.current.value = null
  }

  onFileInputClicked = (_event: MouseEvent) => {
    this.resetFileInputState()
  }

  onDeleteClick = (event: MouseEvent) => {
    event.preventDefault()

    // Reset the value of the file input element to ensure we can select any file again
    this.resetFileInputState()

    const {formik, name} = this.props
    const fieldName = name ? name : this.props.field.name
    formik.setFieldValue(fieldName, undefined)
  }

  hasExtension(fileName: string) {
    const extensions: Array<string> = this.props.extensions
      ? this.props.extensions
      : []
    const pattern = `(${extensions.join('|').replace(/\./g, '\\.')})$`
    return new RegExp(pattern, 'i').test(fileName)
  }

  /*
   * Read a file and return a promise that when resolved gives the file itself and the data URL
   */
  readFile(file: File): Promise<{file: File, dataURL: string}> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      // Read the image via FileReader API and save image result in state.
      reader.addEventListener(
        'load',
        (event: Event) => {
          let dataURL = event.target.result
          dataURL = dataURL.replace(';base64', `;name=${file.name};base64`)
          return resolve({file, dataURL})
        },
        false,
      )

      reader.addEventListener(
        'error',
        (error: ErrorEvent) => {
          return reject(error.error)
        },
        false,
      )

      reader.readAsDataURL(file)
    })
  }

  render() {
    const {showFilename = true, name} = this.props

    const fieldName = name ? name : this.props.field.name
    const fieldValue = this.props.value
      ? this.props.value
      : getIn(this.props.formik.values, fieldName, undefined)
    const isImageSelected = fieldValue && typeof fieldValue === 'object'
    const selectedFileName = isImageSelected
      ? fieldValue.filename
      : 'No file selected'

    const extensions: Array<string> = this.props.extensions
      ? this.props.extensions
      : []
    const acceptString = extensions.map(extension => `.${extension}`).join(', ')

    return (
      <div
        data-testid="image-field"
        className="file has-name is-boxed"
        style={{maxWidth: '15rem'}}
      >
        <label className="file-label">
          <input
            id="image-field_input"
            data-testid="image-field_input"
            className="file-input"
            type="file"
            accept={acceptString}
            ref={this.inputRef}
            onClick={this.onFileInputClicked}
            onChange={this.onFilesSelected}
          />
          {isImageSelected ? (
            <figure className="image" style={{borderRadius: '4px 4px 0 0'}}>
              <img src={fieldValue.url} alt="Preview" />
            </figure>
          ) : (
            <span
              className="file-cta"
              style={isImageSelected ? {padding: 0} : null}
            >
              <span className="file-icon">
                <i className="fas fa-upload" />
              </span>
              <span className="file-label">Choose a file…</span>
            </span>
          )}
          {showFilename && isImageSelected && (
            <span className="file-name">{selectedFileName}</span>
          )}

          {isImageSelected ? (
            <Button
              type="button"
              remove
              onClick={this.onDeleteClick}
              style={{
                position: 'absolute',
                right: '0.5rem',
                top: '0.5rem',
                zIndex: 10,
              }}
            >
              Delete
            </Button>
          ) : null}
        </label>
      </div>
    )
  }
}

export default connect(ImageField)
