import {createSelector} from 'reselect'

import * as selectors from '../../selectors/profiles'
import {NAME} from './constants'

export const getState = state => {
  return state[NAME] || {}
}

export const getAll = state => {
  return getState(state)
}

export const getAllEntities = state => {
  return getAll(state).entities
}

export const getUnapprovedProfileIds = state => {
  return state[NAME].unapproved || []
}

export const getApprovedProfileIds = state => {
  return state[NAME].approved || []
}

export const getProfileFetchStatus = (id, state) => {
  return getAll(state).fetchStatus[id] || 'idle'
}

export const getProfileActionStatus = (id, state) => {
  return getAll(state).actionStatus[id] || 'idle'
}

export const getProfile = (id, state) => {
  return getAll(state).entities[id] || {}
}

export const isProfileLoading = (id, state) => {
  return getProfileFetchStatus(id, state) === 'loading'
}
export const isProfileLoaded = (id, state) => {
  return getProfileFetchStatus(id, state) === 'loaded'
}

/**
 * Returns all the available pagination metadata
 */
export const getPaginationMetadata = state => {
  return getAll(state).pagination
}

/**
 * Returns the total number of items available
 */
export const getTotalItems = state => {
  const allState = getAll(state)
  return allState.pagination && allState.pagination.total
}

/**
 * Returns the previous pagination token
 */
export const getPreviousPaginationToken = state => {
  const allState = getAll(state)
  return allState.pagination && allState.pagination.previous_page_token
}

/**
 * Returns the next pagination token
 */
export const getNextPaginationToken = state => {
  const allState = getAll(state)
  return allState.pagination && allState.pagination.next_page_token
}

/**
 * Returns whether any profile related action is being loaded
 */
export const isLoading = createSelector(
  [getState],
  state => {
    return state.isLoading
  },
)

/**
 * Returns whether any profile related action is being loaded
 */
export const isLoaded = createSelector(
  [getState],
  state => {
    return state.isLoaded
  },
)

/**
 * @private
 * Returns the items of the given ids in the order they were given
 * @param items the list of items to pick from
 * @param ids   the list of identifiers
 * @returns {*}
 */
const getItemsBy = (items = [], ids = []) => {
  return ids.map(id => items[id])
}

/**
 * Returns wheter the given profile is friends with the authenticated profile
 */
export const isProfileFriendship = (id, state) => {
  const authenticatedProfile = selectors.getSesssionProfile(state)
  const profileFriends =
    (authenticatedProfile && authenticatedProfile.friends) || []
  if (profileFriends.length === 0) {
    return false
  }

  const friend = profileFriends.find(item => item.id === id)
  return friend !== null
}

/**
 * Returns wheter the given profile is friends with the authenticated profile
 */
export const isProfileFriendshipFromSession = (id, session) => {
  const authenticatedProfile = session && session.profile
  const profileFriends =
    (authenticatedProfile && authenticatedProfile.friends) || []
  if (profileFriends.length === 0) {
    return false
  }

  const friend = profileFriends.find(item => item.id === id)
  return friend !== null
}

/**
 * Returns the primary address of the given profile
 */
export const getPrimaryAddress = profile => {
  return profile.addresses.find(item => item.isPrimaryAddress)
}

/**
 * Returns the primary geometry coordinates of the address
 */
export const getAddressCoordinates = address => {
  return [address.latitude, address.longitude]
}

/**
 * Returns the primary geometry coordinates of the profile
 */
export const getAddressCoordinatesFromProfile = profile => {
  const primaryAddress = getPrimaryAddress(profile)
  return [primaryAddress.latitude, primaryAddress.longitude]
}

/**
 * Get list of approved profiles
 */
export const getApprovedProfiles = createSelector(
  [getApprovedProfileIds, getAllEntities],
  (ids, items) => {
    return getItemsBy(items, ids)
  },
)

/**
 * Get list of unapproved profiles
 */
export const getUnapprovedProfiles = createSelector(
  [getUnapprovedProfileIds, getAllEntities],
  (ids, items) => {
    return getItemsBy(items, ids)
  },
)
