export const MERGE_ENTITIES = '@resources/MERGE_ENTITIES'
export const REMOVE_ENTITIES = '@resources/REMOVE_ENTITIES'
export const REPLACE_ENTITIES = '@resources/REPLACE_ENTITIES'
export const RESET_ENTITIES = '@resources/RESET_ENTITIES'
export const UPDATE_ENTITIES = '@resources/UPDATE_ENTITIES'

export const REQUEST_IDLE = '@resources/REQUEST_IDLE'
export const REQUEST_PENDING = '@resources/REQUEST_PENDING'
export const REQUEST_FAILED = '@resources/REQUEST_FAILED'
export const REQUEST_SUCCEEDED = '@resources/REQUEST_SUCCEEDED'
