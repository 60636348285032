import {NAME} from './constants'

const getState = state => {
    return state.ui[NAME]
}

/**
 *
 */
export const getUiState = (key: string) => state => getState(state)[key]

/**
 *
 */
export const getModalState = (key: string) => state => getState(state).modalState[key]

export default {getUiState, getModalState}
