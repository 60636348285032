// @flow
import {Button, Form} from 'react-bulma-components'
import React, {Component} from 'react'
import debounce from 'lodash/debounce'

type WeightTextFieldProps = {
  unitOfMeasure?: UnitOfMeasureType,
  value?: string,
  onFieldChange?: () => void,
} // unitOfMeasure = 'g', id, type,  onKeyDown, onBlur, onChange, onFieldChange, ...otherProps

/**
 * WeightTextField
 * An input field which eases the entry of weights by kilograms or grams by
 * correctly converting into the grams
 */
const WeightTextField = class WeightTextField extends Component<WeightTextFieldProps> {
  static defaultProps = {
    unitOfMeasure: 'g',
  }

  _isMounted: ?boolean
  numberFormatter: ?Intl.NumberFormat
  inputRef: ?RefObject
  triggerFieldChangeEvent: () => void

  constructor(props: WeightTextFieldProps) {
    super(props)
    this._isMounted = false
    this.numberFormatter = new Intl.NumberFormat('en-GB')
    this.inputRef = React.createRef()
    this.triggerFieldChangeEvent = debounce(this.dispatchFieldChange, 100)
    this.state = {
      inputValue:
        this.props.unitOfMeasure === 'kg'
          ? this.props.value / 1000
          : this.props.value,
    }
  }

  componentDidMount() {
    this._isMounted = true
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  onFieldKeyDown = (event: KeyboardEvent) => {
    if (event.key === '-') {
      event.preventDefault()
    }
  }

  onFieldBlur = (event: FocusEvent) => {
    this.dispatchFieldChange()
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  dispatchFieldChange() {
    if (!this._isMounted) {
      return
    }
    const inputRef = this.props.inputRef ? this.props.inputRef : this.inputRef
    if (!inputRef.current) {
      return
    }
    const fieldValue: string = inputRef.current.value
    const transformedValue: number = parseFloat(fieldValue)
    const convertedValue: number =
      this.props.unitOfMeasure === 'kg'
        ? transformedValue * 1000
        : transformedValue

    if (this.props.onFieldChange) {
      this.props.onFieldChange(convertedValue)
    }
  }

  onChange = (event: Event) => {
    this.setState({inputValue: event.target.value})
    this.triggerFieldChangeEvent()

    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }

  render() {
    const {
      unitOfMeasure = 'g',
      id,
      type,
      value,
      onKeyDown,
      onBlur,
      onChange,
      onFieldChange,
      inputRef,
      ...otherProps
    } = this.props

    // Recalculate the value when the component is being rendered in read only
    let inputFieldValue: ?string = isNaN(value) ? '' : value
    if (inputFieldValue !== '' && this.props.readOnly) {
      inputFieldValue =
        this.props.unitOfMeasure === 'kg'
          ? String(inputFieldValue / 1000)
          : String(inputFieldValue)
      inputFieldValue = this.numberFormatter.format(inputFieldValue)
    } else {
      inputFieldValue = isNaN(this.state.inputValue)
        ? ''
        : this.state.inputValue
    }

    return (
      <Form.Field className="has-addons" {...otherProps}>
        <Form.Control>
          <input
            ref={this.props.inputRef ? this.props.inputRef : this.inputRef}
            data-testid="field"
            type="text"
            className="input"
            id={id}
            name={this.props.name}
            value={inputFieldValue}
            onKeyDown={this.onFieldKeyDown}
            onBlur={this.onFieldBlur}
            onChange={this.onChange}
            {...otherProps}
          />
        </Form.Control>
        <Form.Control>
          <Button type="button" className="is-static">
            {unitOfMeasure}
          </Button>
        </Form.Control>
      </Form.Field>
    )
  }
}

export default WeightTextField
