import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetch} from 'redux-simple-auth'

const CACHE = {}
const MAX_AGE = 60000 // 1m

/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * @param {string} str the input value
 * @param {boolean} [asString=false] set to true to return the hash value as
 *     8-digit hex string instead of an integer
 * @param {integer} [seed] optionally pass the hash of the previous chunk
 * @returns {integer | string}
 */
function hashFnv32a(str, asString, seed) {
    /*jshint bitwise:false */
    var i,
        l,
        hval = seed === undefined ? 0x811c9dc5 : seed

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i)
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24)
    }
    if (asString) {
        // Convert to 8 digit hex string
        return ('0000000' + (hval >>> 0).toString(16)).substr(-8)
    }
    return hval >>> 0
}

/**
 * StatsElement
 */
export class StatsElement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            hasError: false,
            data: null,
        }
    }

    componentDidMount() {
        this.getStatisticalData()
    }

    componentDidCatch() {
        console.log('Error occurred: ')
        this.setState({hasError: true, isLoading: false})
    }

    /**
     * Helper method to allow cache of the statistical data to avoid hitting the database
     */
    getCachedFetch(url, options) {
        const requestKey = hashFnv32a(url, true)
        let cached = CACHE[requestKey]

        const timePassedSinceItem = (cached && Date.now() - cached.time) || 0
        if (cached && timePassedSinceItem < MAX_AGE) {
            if (process.env.NODE_ENV !== 'production') {
                console.warn(
                    `The data url ${url} or requested key ${
                        requestKey
                    } has been cached and is within maximum cache age of ${MAX_AGE} ms`,
                )
            }
            return cached
        }

        let req = this.props
            .dispatch(
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
            )
            .then(r => r.json())
        req.time = Date.now()
        return (CACHE[requestKey] = req)
    }

    getStatisticalData() {
        if (!this.props.group) {
            return
        }

        // generated a unique request key to keep track of everything
        const dataUrl = `${window.env.REACT_APP_GATEWAY_SERVER_URL}${this.props.group}`
        const requestKey = dataUrl

        this.setState({isLoading: true, hasError: false})
        const fetchRequest = this.getCachedFetch(dataUrl, {})
        fetchRequest
            .then(data => {
                this.setState({data, isLoading: false, hasError: false})
            })
            .catch(error => {
                delete CACHE[requestKey]
                this.setState({isLoading: false, hasError: true, error: error.message})
            })
    }

    render() {
        const {isLoading = false, hasError = false, error = '', data} = this.state
        const {render} = this.props
        if (isLoading) {
            return (
                <p className="control is-large is-loading" style={{padding: '1.25rem'}}>
                    Loading...
                </p>
            )
        }

        if (hasError) {
            return <div style={{padding: '1.25rem'}}>Something went wrong: {error}</div>
        }

        if (render) {
            return <div>{render({data})}</div>
        }

        return null
    }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsElement)
