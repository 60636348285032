import React from 'react'
import {FastField, getIn} from 'formik'
import {Form} from 'react-bulma-components'
import warning from 'warning'
import FieldErrorMessage from './FieldErrorMessage'

/**
 * TextInput
 */
const TextInput = ({name, ...props}) => (
  <FastField
    name={name}
    render={otherFieldProps => {
      const {
        children: availableChildren,
        component: renderComponent,
        render,
        horizontal = false,
        required = false,
        label,
        help,
        ignoreError = false,
        ...otherProps
      } = props
      const {form, field} = otherFieldProps

      let children = availableChildren
      let component = renderComponent

      // Render
      if (Array.isArray(children) && children.length === 0) {
        children = null
      }

      if (typeof children === 'function') {
        children = children(otherProps)
        if (children === undefined) {
          warning('You returned `undefined` from the `children`')
          children = null
        }
      }

      if (!children && !component && !render) {
        component = Form.Input
      }

      //
      const formFieldId = getIn(otherProps, 'id', name)
      const touch = getIn(form.touched, name)
      const error = getIn(form.errors, name, undefined)
      const status = getIn(form.status, name, undefined)

      const fieldProps = {
        ...otherProps,
        name,
        id: formFieldId,
        //value: typeof field.value === 'undefined' ? '' : field.value,
      }
      if (fieldProps.type === 'checkbox') {
        fieldProps.checked =
          typeof field.value === 'boolean' ? field.value : false
      }

      // If the error message is showing we should ensure the styling of the component is updated too
      const displayError =
        (!!touch && !!error) || (error && form.submitCount > 0)
      if (displayError) {
        fieldProps.color = 'danger'
        fieldProps['aria-invalid'] = true
      }

      // Add the aria-required attribute to required fields
      if (required) {
        fieldProps['aria-required'] = true
      }

      const LabelContainer = horizontal ? Form.Field.Label : React.Fragment
      const labelProps = horizontal ? {className: 'has-text-left'} : null
      //console.log({...field, ...fieldProps}) //, props)
      return (
        <Form.Field horizontal={horizontal}>
          {label && (
            <LabelContainer {...labelProps}>
              <Form.Label htmlFor={formFieldId}>
                {label}
                {required && (
                  <span aria-label="Required" className="has-text-danger">
                    *
                  </span>
                )}
              </Form.Label>
            </LabelContainer>
          )}
          <Form.Field.Body>
            <Form.Field>
              <Form.Control>
                {render
                  ? render({field, form, ...fieldProps})
                  : component
                  ? React.createElement(
                      component,
                      {...field, ...fieldProps},
                      children,
                    )
                  : null}
              </Form.Control>
              {!ignoreError && (
                <React.Fragment>
                  <FieldErrorMessage
                    name={name}
                    className="validation--error"
                    component={Form.Help}
                    color="danger"
                  />
                </React.Fragment>
              )}
              {status && status.length > 0 && (
                <Form.Help className="status-error" color="danger">
                  {status}
                </Form.Help>
              )}
              {help && help.length > 0 && (
                <Form.Help color="dark">{help}</Form.Help>
              )}
            </Form.Field>
          </Form.Field.Body>
        </Form.Field>
      )
    }}
  />
)

export default TextInput
