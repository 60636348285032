import React from 'react'
import classNames from 'classnames'
//import './Icon.scss'

type IconProps = {
  type: string,
  className?: string,
  title?: string,
  onClick?: any,
  spin?: boolean,
  style?: any,
}

export const Icon = (props: IconProps) => {
  const {icon: IconComponent, spin, type, style, className, ...rest} = props
  const classString = classNames(
    {
      icon: true,
      'icon-spin': !!spin || type === 'loading',
      [`icon-${type}`]: true,
    },
    className,
  )
  return (
    <i style={style} className={classString} {...rest}>
      <IconComponent />
    </i>
  )
}

export const CircularIcon = (props: IconProps) => {
  const {icon: IconComponent, spin, type, style, className, ...rest} = props
  const classString = classNames(
    {
      icon: true,
      'icon-spin': !!spin || type === 'loading',
      [`icon-${type}`]: true,
    },
    className,
  )
  return (
    <i style={style} className={classString} {...rest}>
      <IconComponent />
    </i>
  )
}

export default {
  Icon,
  CircularIcon,
}
