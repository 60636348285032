// @flow
import React, {Component} from 'react'

/**
 * CheckboxField
 */
class CheckboxField extends Component {
  render() {
    const {name, value, checked, children, ...props} = this.props
    return (
      <div className="b-checkbox">
        <label id={`option=${name}`} className="checkbox">
          <input
            type="checkbox"
            className="styled"
            name={name}
            checked={checked}
            {...props}
          />
          {children}
        </label>
      </div>
    )
  }
}

export default CheckboxField
