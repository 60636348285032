import React, {useState} from 'react'
import {FastField, getIn} from 'formik'
import {Form} from 'react-bulma-components'
import warning from 'warning'
import FieldErrorMessage from './FieldErrorMessage'
import Recaptcha from 'react-recaptcha'
var callback = function () {
    console.log('Done!!!!')
}

const RecaptchaInput = ({onVerify}) => {
    return (
        <div>
            <Recaptcha sitekey="6LcmEbYaAAAAABHs0K9kbkRmrJ95tT_v9arFLbMo" render="explicit" verifyCallback={onVerify} />
        </div>
    )
    return
}

export default RecaptchaInput
